


























































































































































































































































































































































































































































































































































































































































































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import { Session, StringOrNull, DateOrNull, GetSingleProjectResponse, ProjectAllData, SubmitProjectResponse, Comment, UserCardInput, Permission, LabeledPermissions, SessionOrNull, NumberOrNull, PostUploadsToProjectResponse, ContractorAllData, GetContractorsResponse, InvitationsOrNull, InvitationOrNull, Invitation, Contractor, PostContractorInvitationResponse, ProjectInvitation, ProjectBidForm, AttachCustomBidFormOrNull, GetAddendumTimelineResponse, Addendum, AddendumOrNull, AddendumComment, Bid, generateEmptyBidForm, CustomerContactOrNull, generateEmptyProjectContact, ProjectContactOrNull } from '../../types'
import { requestErrorMessage } from '../../utils/constants/errors'
import { DRAFT } from '../../utils/constants/statuses'
import SnackBus from '../../utils/buses/SnackBus'
import UserBus from '../../utils/buses/UserBus'
import SocketBus, { JsonClient } from '../../utils/buses/SocketBus'
import ConfirmationDialog from '../../components/ConfirmationDialog.vue'
// Contact Imports
import SharedLinksCard from '../../components/Contacts/SharedLinksCard.vue'
// Project Team Imports
import UserCard from './UserCard.vue'
import UserCardAssign from './UserCardAssign.vue'
import UserCardUnassign from './UserCardUnassign.vue'
import ProjectTeam from '../../components/ProjectTeam.vue'
// Timeline Imports
import ProjectTimeline from '../../components/ProjectTimeline.vue'
// Addendum Imports
import AddendumProjectTimeline from '../../components/Addenda/AddendumProjectTimeline.vue'
import AddendaCard from '../../components/Addenda/AddendaCard.vue'
// Contractor Invite Imports
import ContractorInvites from '../../components/Invites/ContractorInvitesCard.vue'
import InviteContractor from '../../components/Invites/InviteContractor.vue'
// File Imports
import SupportingFilesCard from '../../components/Files/SupportingFilesCard.vue'
import FileUpload from '../../components/Files/FileUpload.vue'
import * as filestack from 'filestack-js'
import ReloadSnackMessage from '../../components/ReloadSnackMessage.vue'
import { mapProjectContactOrNullArrayToContactArray } from '@/utils/contactConversions'
import { Contact } from '@/graphql/graphql'

const canAssignProposalTeam = {
    icon: 'fas fa-file-invoice-dollar',
    title: 'Proposal Team',
    name: 'Assign to Me'
}
const unassignedSalesRep = {
    icon: 'fas fa-hard-hat',
    title: 'Sales Rep',
    name: 'Unassigned'
}
const unassignedProposalTeam = {
    icon: 'fas fa-file-invoice-dollar',
    title: 'Proposal Team',
    name: 'Unassigned'
}
// TODO - move these to different file?
const generateProjectManagerCard = (name: string) => {
    return {
        icon: 'fas fa-hard-hat',
        title: 'Project Manager',
        name
    }
}
const generateSalesRepCard = (name: string) => {
    return {
        icon: 'fas fa-chart-line',
        title: 'Sales Rep',
        name
    }
}
const generateSupportingSalesRepCards = (reps: Session[]) => {
    let userCards: UserCardInput[] = reps.map(rep => {
        let userCard = {
            icon: 'fas fa-chart-line',
            title: 'Supporting Sales Rep',
            name: rep.userName
        }
        return userCard
    })
    return userCards
}
const generateProposalTeamCard = (name: string) => {
    return {
        icon: 'fas fa-file-invoice-dollar',
        title: 'Proposal Team',
        name
    }
}

interface ProjectFile {
    createdAt: Date
    filename: string
    id: string
    path: string
    isDeleting: boolean
}

interface DeletableSession {
    id?: number
    userName: string
    role: string
    email: string
    isDeleting: boolean
}

interface ValidationError {
    id?: number
    error: string
}

@Component({
    components: {
        ProjectTeam,
        ProjectTimeline,
        AddendaCard,
        SharedLinksCard,
        AddendumProjectTimeline,
        FileUpload,
        ContractorInvites,
        InviteContractor,
        SupportingFilesCard,
        UserCard,
        UserCardAssign,
        UserCardUnassign,
        ReloadSnackMessage,
        ConfirmationDialog
    }
})
export default class ReadProject extends Vue {
    private project: ProjectAllData | null = null
    private isLoading: boolean = false
    private isSubmitting: boolean = false
    private error: StringOrNull = null
    private submitConfirmDialog: boolean = false
    private shouldSendCalendarInvites: boolean = false
    private validationErrors: ValidationError[] = []
    private timelineItems: Comment[] = []
    private isLoadingPermissions: boolean = true
    private permissions: LabeledPermissions = {}
    private projectUpdateClient: JsonClient = new JsonClient()
    private reloadPage: Boolean = false

    // Project Contact Variables
    private contacts: Contact[] = [] // for testing - will be replaced by data from BE
    private contact: CustomerContactOrNull = null

    // File Management Variables
    private openSupportingFilesPicker: boolean = false
    private showScopeOfWorkUploadDialog: boolean = false
    private scopeOfWorkFile: ProjectFile | null = null
    private isUploadingScopeOfWork: boolean = false
    private files: ProjectFile[] = []
    private filesLoading: boolean = false
    private openUpload: boolean[] = [false]
    private deleteScopeOfWorkConfirmDialog: boolean = false

    // TODO - organize these differently? potentially into different files?
    private assignMeProjectManager: UserCardInput = generateProjectManagerCard('Assign to Me')
    private unassignedProjectManager: UserCardInput = generateProjectManagerCard('Unassigned')
    private projectManager: UserCardInput | null = null
    private projectManagerSession: SessionOrNull = null

    private unassignSalesRep: UserCardInput = generateSalesRepCard('Unassign')
    private assignMeSalesRep: UserCardInput = generateSalesRepCard('Assign to Me')
    private unassignedSalesRep: UserCardInput = generateSalesRepCard('Unassigned')
    private salesRep: UserCardInput | null = null
    private salesRepSession: SessionOrNull = null
    private supportingSalesReps: UserCardInput[] = []
    private supportingSalesRepsSessions: DeletableSession[] = []

    private unassignProposalTeam: UserCardInput = generateProposalTeamCard('Unassign')
    private assignMeProposalTeam: UserCardInput = generateProposalTeamCard('Assign to Me')
    private unassignedProposalTeam: UserCardInput = generateProposalTeamCard('Unassigned')
    private proposalTeam: UserCardInput | null = null
    private proposalTeamSession: SessionOrNull = null

    // Invite and Bid Variables
    private invitesNoStatus: InvitationsOrNull = null
    private contractors: ContractorAllData[] = []
    private invitations: ProjectInvitation[] = []
    private bidsLoading: boolean = false
    private pastBidDueDate: boolean = false
    private bidForm: ProjectBidForm = generateEmptyBidForm()
    private customBidForm: AttachCustomBidFormOrNull = null
    private uploadCustomBidFormDialog: boolean = false
    private deleteCustomBidFormConfirmDialog: boolean = false
    private customBidFormIsDeleting: boolean = false
    private promotingBid: boolean = false
    private inviteContractorDialog: boolean = false
    private resetInviteContractorDialog: boolean = false
    private contractorToDelete: Number = 0
    private showDeleteContractorConfirmDialog: boolean = false
    private isDeletingContractor: boolean = false

    // Addendum Variables
    private submitAddendumConfirmDialog: boolean = false
    private addendumIsSubmitting: boolean = false
    private addendumTimelineItems: AddendumComment[] = []
    private addendumNotes: StringOrNull = null
    private showAllAddendums: boolean = false
    private selectedAddendum: AddendumOrNull = null

    private isDownloadingFiles: boolean = false

    get isDraft (): boolean {
        return this.project ? this.project.status === DRAFT : false
    }

    get canAssignProjectManager (): boolean {
        return !!(this.permissions.assignProjectManager && this.permissions.assignProjectManager.authorized)
    }

    get canUnassignProjectManager (): boolean {
        return !!(this.permissions.unassignProjectManager && this.permissions.unassignProjectManager.authorized)
    }

    get canAssignSalesRep (): boolean {
        // TODO: Rework the permissions on who can assign a sales person
        return !this.salesRep && UserBus.userIs.salesRep()
    }

    get canAssignProposalTeam (): boolean {
        return !!(this.permissions.assignProposalTeam && this.permissions.assignProposalTeam.authorized)
    }

    get canUnassignProposalTeam (): boolean {
        return !!(this.permissions.unassignProposalTeam && this.permissions.unassignProposalTeam.authorized)
    }

    get projectTeamList () {
        let projectTeamUsers = {
            sales: {
                session: this.salesRepSession,
                user: this.salesRep ? this.salesRep : this.unassignedSalesRep,
                unassigned: !this.salesRep,
                assignable: this.canAssignSalesRep
            },
            supportingSales: {
                session: this.supportingSalesRepsSessions,
                user: this.supportingSalesReps.length !== 0 ? this.supportingSalesReps : [],
                unassigned: this.supportingSalesReps.length === 0,
                assignable: this.canAssignSalesRep
            },
            proposalTeam: {
                session: this.proposalTeamSession,
                user: this.proposalTeam ? this.proposalTeam : this.unassignedProposalTeam,
                unassigned: !this.proposalTeam,
                assignable: this.canAssignProposalTeam
            },
            projectManager: {
                session: this.projectManagerSession,
                user: this.projectManager ? this.projectManager : this.unassignedProjectManager,
                unassigned: !this.projectManager,
                assignable: this.canAssignProjectManager
            }
        }
        return projectTeamUsers
    }

    get canEdit (): boolean {
        return !!(this.permissions.edit && this.permissions.edit.authorized)
    }

    get canSubmit (): boolean {
        return !!(this.permissions.submit && this.permissions.submit.authorized && this.project!.invitations!.length)
    }

    get canSendCalendarInvites (): boolean {
        return this.project!.prebidDate !== null && this.project!.prebidTimeZone !== null && this.project!.prebidAddress != null
    }

    get canSendNewCalendarInvites (): boolean {
        return this.canSubmit && this.canSendCalendarInvites
    }

    get canSendUpdatedCalendarInvites (): boolean {
        return this.canEdit && this.canSendCalendarInvites
    }

    get canDeleteFile (): boolean {
        return this.canEdit
    }

    get shownAddendumList (): Addendum[] {
        if (this.showAllAddendums) {
            return this.project!.addendums!
        } else {
            let truncatedAddendumList = this.project!.addendums!.slice(0, 3)
            return truncatedAddendumList
        }
    }

    // NOTE: Computed property that filters out the contractors that have already been invited
    // get uninvitedContractors (): Contractor[] {
    //     return this.contractors.filter(contractor => {
    //         const foundIndex = this.invitations.findIndex(i => i.contractor.id === contractor.id && i.expiredAt === null && i.declinedAt === null)
    //         return foundIndex === -1
    //     })
    // }

    created () {
        this.isLoading = true

        this.loadPage()
    }

    loadPage () {
        this.readContractors()
        this.readBidForm()
        this.readProject()
            .catch(err => {
                console.log('Read Project Error', err)
                this.error = requestErrorMessage
            })
            .finally(() => {
                this.isLoading = false

                this.projectUpdateClient = SocketBus.subscribeClient('/topic/projects/' + this.$route.params.id, msg => {
                    let data = msg.json.data

                    console.debug('Project feed: %o', data)

                    // don't respect notifications triggered by current user
                    if (data.userId !== UserBus.session!.id) {
                        if (data.updated) {
                            this.reloadPage = true
                        }
                    }
                })
            })
    }

    beforeDestroy () {
        this.projectUpdateClient.deactivate()
    }

    async readPermissions () {
        this.permissions = await UserBus.userCan({
            edit: {
                method: 'POST',
                path: '/dbs/api/projects/' + this.$route.params.id
            },
            submit: {
                method: 'POST',
                path: '/dbs/api/projects/' + this.$route.params.id + '/submit'
            },
            assignProjectManager: {
                method: 'POST',
                path: '/dbs/api/projects/' + this.$route.params.id + '/assignProjectManager'
            },
            unassignProjectManager: {
                method: 'DELETE',
                path: '/dbs/api/projects/' + this.$route.params.id + '/assignProjectManager'
            },
            assignProposalTeam: {
                method: 'POST',
                path: '/dbs/api/projects/' + this.$route.params.id + '/assignProposalTeam'
            },
            unassignProposalTeam: {
                method: 'DELETE',
                path: '/dbs/api/projects/' + this.$route.params.id + '/assignProposalTeam'
            }
        })

        this.isLoadingPermissions = false
    }

    readProject () {
        this.readPermissions()

        return axios.get<GetSingleProjectResponse>('/api/projects/' + this.$route.params.id)
            .then(res => {
                this.project = res.data.data
                this.contacts = mapProjectContactOrNullArrayToContactArray(res.data.data.contacts)
                this.timelineItems = res.data.data.comments
                this.files = this.project.files.map(i => {
                    let file = {
                        filename: i.filename,
                        id: i.id,
                        path: i.path,
                        createdAt: i.createdAt,
                        isDeleting: false
                    }
                    return file
                })

                this.invitations = this.project.invitations!.map(i => {
                    let invite = {
                        ...i,
                        status: this.checkInviteStatus(i),
                        isDeleting: false
                    }
                    return invite
                })

                let today: Date = new Date()
                const bidDueDate: Date = new Date(res.data.data.bidDueDate!)
                this.pastBidDueDate = bidDueDate < today

                if (this.project.scopeOfWorkFile) {
                    this.scopeOfWorkFile = {
                        ...this.project.scopeOfWorkFile,
                        isDeleting: false
                    }
                } else {
                    this.scopeOfWorkFile = null
                }

                if (res.data.data.salesRep) {
                    this.salesRep = generateSalesRepCard(res.data.data.salesRep.userName.toString())
                    this.salesRepSession = res.data.data.salesRep
                }

                this.supportingSalesReps = generateSupportingSalesRepCards(res.data.data.supportingSalesReps)
                let deletableSalesReps = res.data.data.supportingSalesReps.map(i => {
                    let session = {
                        id: i!.id,
                        userName: i!.userName,
                        role: i!.role,
                        email: i!.email,
                        isDeleting: false
                    }
                    return session
                })
                this.supportingSalesRepsSessions = deletableSalesReps

                if (res.data.data.projectManager) {
                    this.projectManager = generateProjectManagerCard(res.data.data.projectManager.userName.toString())
                    this.projectManagerSession = res.data.data.projectManager
                } else {
                    this.projectManager = null
                    this.projectManagerSession = null
                }

                if (res.data.data.proposalTeam) {
                    this.proposalTeam = generateProposalTeamCard(res.data.data.proposalTeam.userName.toString())
                    this.proposalTeamSession = res.data.data.proposalTeam
                } else {
                    this.proposalTeam = null
                    this.proposalTeamSession = null
                }

                return res
            })
            .then(res => {
                if (this.project!.status !== 'DRAFT') {
                    this.readAddendumTimeline()
                }
            })
    }

    readBidForm () {
        axios.get('/api/projects/' + this.$route.params.id + '/bidForm')
            .then(res => {
                this.bidForm = res.data.data
            })
    }

    /*
    * Addendum Functionality
    */
    readAddendumTimeline () {
        // filter by the latest addendum date or the date the bid was submitted
        let filterDate
        let projectSentAt = this.project!.comments.filter(i => {
            return i.type === 'INVITATIONS_SENT'
        })

        if (this.project!.addendums!.length > 0) {
            // latest addendum posted date
            filterDate = this.project!.addendums[0].createdAt
        } else if (projectSentAt.length > 0) {
            // project sent date
            filterDate = projectSentAt[0].createdAt
        } else {
            // no filter
            filterDate = null
        }

        axios.post<GetAddendumTimelineResponse>('/api/projects/' + this.$route.params.id + '/timeline', {
            startDate: filterDate
        })
            .then(res => {
                let comments = res.data.data.map(i => {
                    let comment = {
                        id: i.id,
                        type: i.type,
                        title: i.title,
                        body: i.body,
                        createdAt: i.createdAt
                    }
                    return comment
                })

                this.addendumTimelineItems = comments
            })
    }

    onSubmitAddendum () {
        this.addendumIsSubmitting = true
        const data = {
            projectId: this.$route.params.id,
            notes: this.addendumNotes
        }
        const config = {
            params: {
                shouldSendCalendarInvites: this.canSendCalendarInvites ? this.shouldSendCalendarInvites : false
            }
        }
        axios.post<{ data: Addendum }>('/api/addendums', data, config)
            .then(res => {
                let version = res.data.data.version

                SnackBus.showMessage('Addendum ' + version + ' posted successfully.', 'success')
                this.readProject()
            })
            .finally(() => {
                this.addendumNotes = null
                this.addendumIsSubmitting = false
                this.submitAddendumConfirmDialog = false
            })
    }

    checkInviteStatus (invite: Invitation) {
        let inviteStatus!: string

        if (invite.status === 'DECLINED') {
            return 'Invitation Declined'
        }

        // check if there are valid bids present on the project
        if (invite.bids.length > 0 && invite.bids[0].status !== 'INVALID') {
            if (invite.bids[0].awardedAt !== null) {
                return 'Bid Awarded'
            } else if (invite.bids[0].acceptedAt !== null) {
                return 'Bid Accepted'
            } else {
                return 'Bid Submitted'
            }
        }

        if (invite.expiredAt && !this.pastBidDueDate) {
            return 'Invitation Expired'
        }

        if (invite.status === 'CREATED') {
            return 'Invitation Not Sent'
        }

        let lowercaseStatus = invite.status.toLowerCase()
        let formattedStatus = lowercaseStatus.charAt(0).toUpperCase() + lowercaseStatus.slice(1)
        return 'Invitation ' + formattedStatus // CREATED, SENT, VIEWED
    }

    onSubmitConfirmDialog () {
        // check if required fields are valid
        this.validateFields()

        // fields valid - show dialog
        this.submitConfirmDialog = (this.validationErrors.length === 0)
    }

    validateFields () {
        this.validationErrors = []
        // check for required fields
        if (!this.project!.customer) {
            this.validationErrors.push({ id: 0, error: 'Customer' })
        }
        if (!this.project!.customerContact) {
            this.validationErrors.push({ id: 1, error: 'Customer Contact' })
        } else if (!this.project!.customerContact!.name) {
            this.validationErrors.push({ id: 1, error: 'Customer Contact' })
        }
        if (!this.project!.siteName) {
            this.validationErrors.push({ id: 2, error: 'Site Name' })
        }
        if (!this.project!.siteAddress) {
            this.validationErrors.push({ id: 3, error: 'Site Address' })
        } else if (!this.project!.siteAddress!.line1 || !this.project!.siteAddress!.city) {
            this.validationErrors.push({ id: 4, error: 'Site Address (Not Complete)' })
        }
        if (!this.project!.scopeOfWork && !this.project!.scopeOfWorkFile) {
            this.validationErrors.push({ id: 5, error: 'Scope of Work' })
        }
        if (!this.project!.bidDueDate) {
            this.validationErrors.push({ id: 6, error: 'Bid Due Date' })
        }
        if (!this.project!.files.length) {
            this.validationErrors.push({ id: 7, error: 'Supporting Files (Minimum of one file)' })
        }
    }

    onSubmit () {
        this.submitConfirmDialog = false
        this.isSubmitting = true

        axios.post<SubmitProjectResponse>('/api/projects/' + this.$route.params.id + '/submit', null, {
            params: {
                shouldSendCalendarInvites: this.canSendCalendarInvites ? this.shouldSendCalendarInvites : false
            }
        })
            .then(() => {
                SnackBus.showMessage('Project submitted', 'success')
                this.readProject()
            })
            .catch(err => {
                console.log('Submit Project Error', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.isSubmitting = false
            })
    }

    /**
     * edit lead sales rep assignment
     */
    onEditLeadSalesRep (salesRep: Session) {
        axios.post('/api/projects/' + this.project!.id! + '/assignSalesRep', salesRep)
            .then(res => {
                SnackBus.showMessage('Updated Lead Sales Rep', 'success')
                return this.readProject()
            })
            .catch(err => {
                console.log('Error editing lead sales rep', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
    }

    /**
     * add supporting sales reps
     */
    onAssignSupportingSalesReps (salesReps: Session[]) {
        axios.post('/api/projects/' + this.project!.id! + '/assignSupportingSalesReps', salesReps)
            .then(res => {
                let message = salesReps.length > 1 ? 'Added Supporting Sales Reps' : 'Added Supporting Sales Rep'
                SnackBus.showMessage(message, 'success')
                return this.readProject()
            })
            .catch(err => {
                console.log('Error adding sales rep(s)', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
    }

    /**
     * unassign supporting sales rep
     */
    onUnassignSupportingSalesRep (salesRep: Session, index: number) {
        this.$set(this.projectTeamList.supportingSales.session[index], 'isDeleting', true)
        axios.delete('api/projects/' + this.project!.id! + '/assignSupportingSalesReps', {
            data: [salesRep.id]
        })
            .then(res => {
                SnackBus.showMessage('Unassigned Supporting Sales Rep', 'success')
                return this.readProject()
            })
            .catch(err => {
                console.log('Error adding sales rep(s)', err)
                // set isDeleting to false so the loading gif stops if the removal errors out
                this.$set(this.projectTeamList.supportingSales.session[index], 'isDeleting', false)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
    }

    /**
    * Decide which role to assign yourself to
    */
    onAcceptAssignment (user: UserCardInput) {
        if (user.title === 'Project Manager') {
            this.onProjectManagerAccept()
        } else if (user.title === 'Proposal Team') {
            this.onProposalTeamAccept()
        }
    }

    /**
     * assign project manager
     */
    onProjectManagerAccept () {
        axios.post('/api/projects/' + this.$route.params.id + '/assignProjectManager')
            .then(res => {
                SnackBus.showMessage('Assigned as project manager', 'success')
                return this.readProject()
            })
            .catch(err => {
                console.log('Error assigning project manager', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
    }

    /**
     * assign proposal team
     */
    onProposalTeamAccept () {
        axios.post('/api/projects/' + this.$route.params.id + '/assignProposalTeam')
            .then(res => {
                SnackBus.showMessage('Assigned as proposal team', 'success')
                return this.readProject()
            })
            .catch(err => {
                console.log('Error assigning proposal team', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
    }

    /**
     * Determine which role to is to be unassigned
     */

    onAcceptUnassignment (user: UserCardInput) {
        if (user.title === 'Project Manager') {
            this.onProjectManagerUnassign()
        } else if (user.title === 'Proposal Team') {
            this.onProposalTeamUnassign()
        }
    }

    /**
     * unassign proposal team
     */
    onProposalTeamUnassign () {
        axios.delete('/api/projects/' + this.$route.params.id + '/assignProposalTeam')
            .then(res => {
                SnackBus.showMessage('Unassigned proposal team', 'success')
                return this.readProject()
            })
            .catch(err => {
                console.log('Error unassigning proposal team', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
    }

    /**
     * unassign project manager
     */
    onProjectManagerUnassign () {
        axios.delete('/api/projects/' + this.$route.params.id + '/assignProjectManager')
            .then(res => {
                SnackBus.showMessage('Unassigned project manager', 'success')
                return this.readProject()
            })
            .catch(err => {
                console.log('Error unassigning project manager', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
    }

    onReloadPage () {
        console.debug('user reloaded page')

        this.reloadPage = false
        this.loadPage()
    }

    /**
     * Upload Scope of Work Functionality
     */

    uploadScopeOfWork (file: filestack.PickerResponse) {
        this.showScopeOfWorkUploadDialog = false
        this.isUploadingScopeOfWork = true

        const scopeOfWorkFile = file.filesUploaded[0]

        return axios.post('/api/projects/' + this.project!.id + '/scopeOfWork', {
            fileName: scopeOfWorkFile.filename,
            handle: scopeOfWorkFile.handle,
            uri: scopeOfWorkFile.url
        })
            .then(res => {
                SnackBus.showMessage('File uploaded successfully.', 'success')
            })
            .catch(err => {
                console.log('Upload File Error: ', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.readProject().then(() => {
                    this.isUploadingScopeOfWork = false
                })
            })
    }

    /*
    * Contractor Invites Functionality
    */

    readContractors () {
        axios.get<GetContractorsResponse>('/api/contractors')
            .then(res => {
                this.contractors = res.data.data
            })
    }

    async sendInvite (contractor: Contractor) {
        this.bidsLoading = true
        this.inviteContractorDialog = false
        this.resetInviteContractorDialog = true

        let invite
        let inviteArray = []
        let tryUpdateContractor = false
        if (contractor.id) {
            invite = {
                projectId: this.project!.id,
                contractor: {
                    id: contractor.id
                }
            }
            tryUpdateContractor = true
        } else {
            invite = {
                projectId: this.project!.id,
                contractor: contractor
            }
        }
        inviteArray.push(invite)

        if (tryUpdateContractor) { // isExistingContractor
            try {
                await axios.post(`/api/contractors/${contractor!.id!}`, contractor)
            } catch {
                SnackBus.showMessage('There was an error updating your contractor', 'error')
            }
        }

        try {
            await axios.post<PostContractorInvitationResponse>('/api/invitations',
                inviteArray,
                {
                    params: {
                        shouldSendCalendarInvites: this.canSendCalendarInvites ? contractor.shouldSendCalendarInvites : false
                    }
                }
            )
            SnackBus.showMessage('Invitation successfully added.', 'success')
        } catch (err) {
            console.log('Invitation Error: ', err)
            SnackBus.showMessage(requestErrorMessage, 'error')
        } finally {
            this.loadPage()
            this.bidsLoading = false
            this.resetInviteContractorDialog = false
        }
    }

    onPromoteBid (actionOnBid: string, bid: Bid) {
        const action = actionOnBid.toLowerCase()
        this.promotingBid = true
        axios.post('api/invitations/' + bid.invitationId + '/bids/' + bid.id + '/' + action)
            .then(res => {
                SnackBus.showMessage(`Bid successfully ${action}ed.`, 'success')
            })
            .catch(err => {
                console.log('Bid Promotion Error: ', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.promotingBid = false
                this.readProject()
            })
    }

    onDeleteInvitation (invite: ProjectInvitation) {
        this.bidsLoading = true
        axios.delete('/api/invitations/' + invite.id)
            .then(res => {
                SnackBus.showMessage('Invitation deleted successfully.', 'success')
            })
            .catch(err => {
                console.log('Delete Invitation Error: ', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.readProject()
                this.bidsLoading = false
            })
    }

    onResendInvitation (invite: ProjectInvitation) {
        this.bidsLoading = true
        axios.post('/api/invitations/' + invite.id + '/resend')
            .then(res => {
                SnackBus.showMessage('Invitation re-sent successfully.', 'success')
            })
            .catch(err => {
                console.log('Resend Invitation Error: ', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.readProject()
                this.bidsLoading = false
            })
    }

    onDeleteConfirmDialog (contractorId: Number) {
        this.inviteContractorDialog = !this.inviteContractorDialog
        this.showDeleteContractorConfirmDialog = !this.showDeleteContractorConfirmDialog
        this.contractorToDelete = contractorId
    }

    async onDeleteContractor () {
        this.isDeletingContractor = true
        this.resetInviteContractorDialog = true
        this.inviteContractorDialog = !this.inviteContractorDialog
        this.showDeleteContractorConfirmDialog = !this.showDeleteContractorConfirmDialog
        try {
            await axios.delete(`/api/contractors/${this.contractorToDelete}`)
            SnackBus.showMessage('The contractor has successfully been deleted.', 'success')
            this.loadPage()
        } catch {
            SnackBus.showMessage('There was an error deleting this contractor.', 'error')
        } finally {
            this.resetInviteContractorDialog = false
            this.isDeletingContractor = false
            this.inviteContractorDialog = false
        }
    }

    onDeleteFile (file: ProjectFile, index: number) {
        this.filesLoading = true
        const projectId = this.project!.id

        axios.delete('/api/projects/' + projectId + '/filestack/upload', {
            data: [file.id]
        })
            .then(res => {
                SnackBus.showMessage('File deleted successfully.', 'success')
            })
            .catch(err => {
                console.log('Delete File Error: ', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.readProject()
                this.filesLoading = false
            })
    }

    archiveScopeOfWork () {
        this.deleteScopeOfWorkConfirmDialog = true
    }

    onDeleteScopeOfWork () {
        const projectId = this.project!.id

        this.deleteScopeOfWorkConfirmDialog = false
        this.scopeOfWorkFile!.isDeleting = true

        axios.delete('/api/projects/' + projectId + '/scopeOfWork')
            .then(res => {
                SnackBus.showMessage('File deleted successfully.', 'success')
            })
            .catch(err => {
                console.log('Delete File Error: ', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.readProject()
            })
    }

    /*
    * Handling Custom Bid Form
    */

    uploadCustomBidForm (file: filestack.PickerResponse) {
        this.uploadCustomBidFormDialog = false
        axios.post('/api/projects/' + this.$route.params.id + '/bidForm',
            {
                fileName: file.filesUploaded[0].filename,
                uri: file.filesUploaded[0].url
            }
        )
            .then(res => {
                this.readBidForm()
                SnackBus.showMessage('Your custom bid form has been successfully added to the project.', 'success')
            })
            .catch((err) => {
                console.log('Custom Bid Form Upload Error', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.readProject()
            })
    }

    onDeleteCustomBidForm () {
        this.customBidFormIsDeleting = true
        this.deleteCustomBidFormConfirmDialog = false
        axios.delete('api/projects/' + this.$route.params.id + '/bidForm')
            .then(res => {
                SnackBus.showMessage('You have successfully deleted you custom bid form.', 'success')
            })
            .catch((err) => {
                console.log('Delete Custom Bid Form Error', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.customBidFormIsDeleting = false
                this.readBidForm()
                this.readProject()
            })
    }

    // TODO refactor
    onFilestackUploadDone (res: filestack.PickerResponse) {
        this.filesLoading = true
        const projectId: string = this.project!.id

        axios.post<PostUploadsToProjectResponse>('/api/projects/' + projectId + '/filestack/upload',
            res.filesUploaded.map(v => ({
                fileName: v.filename,
                handle: v.handle,
                uri: v.url
            }))
        )
            .then(res => {
                SnackBus.showMessage('Files uploaded successfully.', 'success')
            })
            .catch(err => {
                console.log('Upload Files Error', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.readProject()
                this.filesLoading = false
                this.openSupportingFilesPicker = false
            })
    }

    onDownloadBids () {
        this.isDownloadingFiles = true
        axios.get(`/api/projects/${this.$route.params.id}/filestack/downloadAll`, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/force-download' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = (this.project!.name || 'project') + '-bids.zip'
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(err => {
                console.log('Download Bids Zip Error', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            }).finally(() => {
                this.isDownloadingFiles = false
            })
    }
}
