<template>
    <!-- This component is used to display key value data in a list format. By using an html table
    the values are aligned along the second column -->
    <div class="v-table__overflow">
        <table class="v-table v-datatable theme--light">
            <slot />
        </table>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    tbody td:first-of-type {
        white-space: nowrap;
    }
</style>
