



















import { Component, Vue, Prop } from 'vue-property-decorator'
import SnackBus from '../utils/buses/SnackBus'
import { StringOrNull } from '../types'

@Component
export default class ReloadSnackMessage extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    readonly show!: Boolean

    onReload () {
        this.$emit('reload-page')
    }
}
