var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-timeline",
    {
      staticClass: "project-timeline",
      attrs: { "data-cy": "project-timeline-card", dense: "", "align-top": "" }
    },
    _vm._l(_vm.timelineItems, function(item) {
      return _c(
        "v-timeline-item",
        {
          key: item.id,
          attrs: {
            color: _vm._f("getAvatarColor")(item.user),
            right: "",
            "fill-dot": ""
          },
          scopedSlots: _vm._u(
            [
              {
                key: "icon",
                fn: function() {
                  return [
                    _c("v-avatar", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("getAvatarInitials")(item.user)) +
                          "\n            "
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _c("template", { slot: "opposite" }, [
            _c("div", { staticClass: "mt-2" }, [
              _c("span", { staticClass: "grey--text text--darken-2" }, [
                _vm._v(_vm._s(_vm._f("formatDate")(item.createdAt, "MMM DD")))
              ]),
              _c("br"),
              _c("span", { staticClass: "caption" }, [
                _vm._v(_vm._s(item.user.userName))
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "mt-2" },
            [_c("timeline-item", { attrs: { item: item, show: item.show } })],
            1
          )
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }