var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { attrs: { "primary-title": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-start": "" } },
            [
              _c("v-flex", [
                _c("h3", { staticClass: "headline" }, [
                  _vm._v(
                    "\n                    Bid from " +
                      _vm._s(_vm.contractor.companyName) +
                      "\n                "
                  )
                ]),
                _c("span", { staticClass: "grey--text" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.contractor.name) +
                      "\n                "
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-list",
        { attrs: { "two-line": "" } },
        [
          _c("v-divider"),
          _c("v-subheader", [
            _vm._v("\n            Bid File"),
            _vm.bid.files.length > 1 ? _c("span", [_vm._v("s")]) : _vm._e()
          ]),
          _vm._l(_vm.bid.files, function(file) {
            return _c(
              "v-list-tile",
              { key: file.path, attrs: { href: file.path, target: "_blank" } },
              [
                _c(
                  "v-list-tile-avatar",
                  [
                    _c("v-icon", { attrs: { size: "16", left: "" } }, [
                      _vm._v(
                        "\n                    fas fa-download\n                "
                      )
                    ])
                  ],
                  1
                ),
                _c("v-list-tile-content", [
                  _c("div", { staticClass: "text-truncate" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(file.filename) +
                        "\n                "
                    )
                  ]),
                  _c("span", { staticClass: "caption grey--text" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("formatCalendarDate")(file.createdAt)) +
                        "\n                "
                    )
                  ])
                ])
              ],
              1
            )
          }),
          _vm.bid.notes !== ""
            ? [
                _c("v-subheader", [
                  _vm._v("\n                Bid Notes\n            ")
                ]),
                _c("v-card-text", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.bid.notes) +
                      "\n            "
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.$emit("close-dialog")
                }
              }
            },
            [_vm._v("\n            Close\n        ")]
          ),
          _vm.canPromoteBid()
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    disabled: _vm.bid.invalidatedAt !== null,
                                    loading: _vm.promotingBid,
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.promoteBidDialog()
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.actionOnBid + " Bid") +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2861709408
                  )
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.bid.invalidatedAt !== null
                          ? "This contractor has not updated their bid since the latest addendum."
                          : _vm.actionOnBid + " this contractor's bid?"
                      )
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.canPromoteBid()
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "290" },
              model: {
                value: _vm.promoteBidConfirmDialog,
                callback: function($$v) {
                  _vm.promoteBidConfirmDialog = $$v
                },
                expression: "promoteBidConfirmDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline", attrs: { "primary-title": "" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.actionOnBid) +
                          " " +
                          _vm._s(_vm.contractor.companyName) +
                          "'s Bid?\n            "
                      )
                    ]
                  ),
                  _c("v-card-text", [
                    _vm._v("\n                Are you sure you want to "),
                    _c("span", { staticClass: "text-lowercase" }, [
                      _vm._v(_vm._s(_vm.actionOnBid))
                    ]),
                    _vm._v(" this contractor's bid?\n            ")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "" },
                          on: {
                            click: function($event) {
                              return _vm.cancelPromoteBidDialog()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Cancel\n                "
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.onPromoteBid()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.actionOnBid) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }