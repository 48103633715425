/// Project type

export const UNSPECIFIED = 'UNSPECIFIED'
export const PRIVATE = 'PRIVATE'
export const PREMIER = 'PREMIER'
export const HEALTH_TRUST = 'HEALTH_TRUST'
export const OMNIA_PUBLIC = 'OMNIA_PUBLIC'
export const OMNIA_PRIVATE = 'OMNIA_PRIVATE'
export const DBS_NON_COOP = 'DBS_NON_COOPERATIVE'
export const DBS_NATIONAL_ACCOUNT = 'DBS_NATIONAL_ACCOUNT'
export const DRY_ZONE = 'DRY_ZONE'
export const PARTNERS = 'PARTNERS'
export const GSA = 'GSA'
export const VIZIENT = 'VIZIENT'
export const EQUALIS = 'EQUALIS'

export interface ProjectType {
    text: string;
    value: string;
}

export const projectTypes: {
    [key: string]: ProjectType
} = {
    [UNSPECIFIED]: {
        text: 'Unspecified',
        value: UNSPECIFIED
    },
    [DBS_NON_COOP]: {
        text: 'DBS (Non-Cooperative)',
        value: DBS_NON_COOP
    },
    [DBS_NATIONAL_ACCOUNT]: {
        text: 'DBS (National Account)',
        value: DBS_NATIONAL_ACCOUNT
    },
    [DRY_ZONE]: {
        text: 'Dry Zone',
        value: DRY_ZONE
    },
    [GSA]: {
        text: 'GSA',
        value: GSA
    },
    [OMNIA_PRIVATE]: {
        text: 'OMNIA Private',
        value: OMNIA_PRIVATE
    },
    [OMNIA_PUBLIC]: {
        text: 'OMNIA Public',
        value: OMNIA_PUBLIC
    },
    [PARTNERS]: {
        text: 'Partners',
        value: PARTNERS
    },
    [PREMIER]: {
        text: 'Premier',
        value: PREMIER
    },
    [VIZIENT]: {
        text: 'Vizient',
        value: VIZIENT
    },
    [EQUALIS]: {
        text: 'Equalis',
        value: EQUALIS
    },
    // allowed to read but no write
    [PRIVATE]: {
        text: 'Private',
        value: PRIVATE
    },
    [HEALTH_TRUST]: {
        text: 'Health Trust',
        value: HEALTH_TRUST
    }
}

export const projectTypeArray: ProjectType[] = Object.keys(projectTypes).filter(key => key !== HEALTH_TRUST && key !== PRIVATE).map(key => projectTypes[key])
