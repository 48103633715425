import { StringOrNull, AddressOrNull, CustomerOrNull } from './customTypes'
import { emptyAddress } from './address'
import { Customer } from './customers'
import { Contact, User } from '@/graphql/graphql'

export interface CustomerContact {
    id?: string;
    name: StringOrNull;
    title: StringOrNull;
    companyName: StringOrNull;
    phone: StringOrNull;
    email: StringOrNull;
    address: AddressOrNull;
    customer: CustomerOrNull;
}

export const generateEmptyCustomerContact = (customer: CustomerOrNull): CustomerContact => ({
    name: null,
    title: null,
    companyName: null,
    phone: null,
    email: null,
    address: { ...emptyAddress },
    customer: customer
})

export interface ProjectContact {
    id?: string;
    name: StringOrNull;
    title: StringOrNull;
    companyName: StringOrNull;
    phone: StringOrNull;
    email: StringOrNull;
    address: AddressOrNull;
}

export const generateEmptyProjectContact = (): ProjectContact => ({
    name: null,
    title: null,
    companyName: null,
    phone: null,
    email: null,
    address: { ...emptyAddress }
})

export const generateEmptyUser = (): User => ({
    email: '',
    id: '',
    role: '',
    userName: ''
})

export const generateEmptyContact = (): Contact => ({
    address: { ...emptyAddress, id: '' },
    companyName: '',
    customers: [],
    email: '',
    id: '',
    name: '',
    phone: '',
    sharedLinks: [],
    title: '', // maybe
    /** User who created this contact **/
    createdBy: generateEmptyUser()
})
