import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
    iconfont: 'fa',
    theme: {
        primary: '#1f3346',
        secondary: '#e25118'
    }
})
