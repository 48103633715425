

















































import { Component, Vue, Prop } from 'vue-property-decorator'
import {
    AddendumOrNull
} from '../../types'
import SnackBus from '../../utils/buses/SnackBus'
import AddendumProjectTimeline from './AddendumProjectTimeline.vue'

@Component({
    components: {
        AddendumProjectTimeline
    }
})
export default class ConfirmationDialog extends Vue {
    @Prop({
        default: false
    })
    showDialog!:Boolean

    @Prop({
        default: null
    })
    selectedAddendum!: AddendumOrNull

    get showDialogModel () {
        return this.showDialog
    }

    set showDialogModel (ignoreThis) {
        this.$emit('close-dialog')
    }
}
