import { render, staticRenderFns } from "./Projects.vue?vue&type=template&id=9a15cf76&scoped=true&"
import script from "./Projects.vue?vue&type=script&lang=ts&"
export * from "./Projects.vue?vue&type=script&lang=ts&"
import style0 from "./Projects.vue?vue&type=style&index=0&id=9a15cf76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a15cf76",
  null
  
)

/* vuetify-loader */
import installComponents from "!/codebuild/output/src2994353656/src/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib'
import { VBadge } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCheckbox } from 'vuetify/lib'
import { VChip } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAction } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarTitle } from 'vuetify/lib'
import { VTooltip } from 'vuetify/lib'
installComponents(component, {VAutocomplete,VBadge,VBtn,VCard,VCheckbox,VChip,VContainer,VDataTable,VDivider,VFlex,VIcon,VLayout,VListTile,VListTileAction,VListTileContent,VListTileTitle,VSpacer,VTextField,VToolbar,VToolbarTitle,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2994353656/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('9a15cf76', component.options)
    } else {
      api.reload('9a15cf76', component.options)
    }
    module.hot.accept("./Projects.vue?vue&type=template&id=9a15cf76&scoped=true&", function () {
      api.rerender('9a15cf76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Projects/Projects.vue"
export default component.exports