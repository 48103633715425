
















































import { Component, Vue } from 'vue-property-decorator'
import { setCookie, getCookie } from '../utils/helpers'
import { AUTH_COOKIE_NAME } from '../utils/buses/UserBus'
import { mainOidc } from '@/oidc'
import SnackBus from '@/utils/buses/SnackBus'

@Component
export default class Login extends Vue {
    private email: string = ''
    private password: string = ''

    get user () {
        return { ...mainOidc.userProfile, accessToken: mainOidc.accessToken }
    }

    get claims () {
        if (this.user) {
            return Object.keys(this.user).map(key => ({
                key,
                value: (this.user as any)[key]
            }))
        }
        return []
    }

    onSubmit () {
        const defaultToken = process.env.VUE_APP_DEFAULT_TOKEN

        setCookie(AUTH_COOKIE_NAME, defaultToken, 30)
        this.$router.push({ name: 'projects' })
    }

    copy (event: Event) {
        const selBox = document.createElement('textarea')
        selBox.value = (event.target as HTMLElement).innerText.includes('JWT')
            // @ts-ignore
            ? mainOidc.user.id_token
            : sessionStorage.getItem(`oidc.user:${process.env.VUE_APP_OIDC_AUTHORITY_URL}:${process.env.VUE_APP_OIDC_CLIENT_ID}`)
        document.body.appendChild(selBox)
        selBox.select()
        document.execCommand('copy')
        document.body.removeChild(selBox)
        SnackBus.showMessage('Copied!', 'success')
    }
}
