




























import { Component, Vue, Prop } from 'vue-property-decorator'
import { UserCardInput, SessionOrNull } from '@/types'
import UserBus from '../../utils/buses/UserBus'

@Component
export default class UserCard extends Vue {
    @Prop({
        required: true
    })
    user!: UserCardInput
    @Prop({
        default: false
    })
    unassigned!: boolean
    @Prop({
        default: null
    })
    session!: SessionOrNull

    get iconBackground () {
        if (this.session) {
            return UserBus.avatarColor(this.session)
        } else {
            return this.unassigned ? 'grey lighten-1' : 'primary'
        }
    }
}
