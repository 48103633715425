var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { "data-cy": "project-team-card" } },
    [
      _c(
        "v-card-title",
        { staticClass: "headline pb-2", attrs: { "primary-title": "" } },
        [_vm._v("\n        Project Team\n    ")]
      ),
      [
        _c(
          "v-list",
          { attrs: { subheader: "" } },
          [
            _c("v-subheader", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.users.sales.user.title) +
                  "\n            "
              )
            ]),
            _c(
              "v-list-tile",
              [
                _c(
                  "v-list-tile-avatar",
                  { attrs: { color: _vm.salesIconBackground } },
                  [
                    !_vm.users.sales.session
                      ? _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.users.sales.user.icon) +
                              "\n                    "
                          )
                        ])
                      : [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("getAvatarInitials")(
                                  _vm.users.sales.session
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                  ],
                  2
                ),
                _c(
                  "v-list-tile-content",
                  [
                    _c("v-list-tile-title", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.users.sales.user.name) +
                          "\n                    "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-list-tile-action",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { flat: "", color: "secondary" },
                        on: { click: _vm.editSalesRep }
                      },
                      [
                        _vm._v(
                          "\n                        Edit\n                    "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-subheader", [
              _vm._v("\n                Supporting Sales Reps\n            ")
            ]),
            !_vm.users.supportingSales.unassigned
              ? _vm._l(_vm.users.supportingSales.session, function(
                  supportingSalesRep,
                  index
                ) {
                  return _c(
                    "v-list-tile",
                    { key: supportingSalesRep.id },
                    [
                      _c(
                        "v-list-tile-avatar",
                        {
                          attrs: {
                            color: _vm.getAvatarColor(supportingSalesRep)
                          }
                        },
                        [
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm._f("getAvatarInitials")(
                                    supportingSalesRep
                                  )
                                ) +
                                "\n                        "
                            )
                          ]
                        ],
                        2
                      ),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(supportingSalesRep.userName) +
                                "\n                        "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-action",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                loading: supportingSalesRep.isDeleting,
                                icon: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.unassignSupportingSalesRep(
                                    supportingSalesRep,
                                    index
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "rgba(0,0,0,0.54)",
                                    small: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                fas fa-trash\n                            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              : _vm._e(),
            _c(
              "v-list-tile",
              [
                _c(
                  "v-list-tile-avatar",
                  { attrs: { color: "grey lighten-1" } },
                  [
                    _c("v-icon", { attrs: { dark: "", size: "20" } }, [
                      _vm._v(
                        "\n                        fas fa-chart-line\n                    "
                      )
                    ])
                  ],
                  1
                ),
                _c("v-list-tile-content", [
                  _c("div", { staticClass: "text-truncate" }, [
                    _vm._v(
                      "\n                        Unassigned\n                    "
                    )
                  ])
                ]),
                _c(
                  "v-list-tile-action",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.isAddingSupportingSalesRep,
                          disabled: _vm.isAddingSupportingSalesRep,
                          color: "secondary",
                          flat: ""
                        },
                        on: { click: _vm.assignSupportingSalesReps }
                      },
                      [
                        _vm._v(
                          "\n                        Add\n                    "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-subheader", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.users.proposalTeam.user.title) +
                  "\n            "
              )
            ]),
            _c(
              "v-list-tile",
              [
                _c(
                  "v-list-tile-avatar",
                  { attrs: { color: _vm.proposalTeamIconBackground } },
                  [
                    !_vm.users.proposalTeam.session
                      ? _c("v-icon", { attrs: { dark: "", size: "20" } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.users.proposalTeam.user.icon) +
                              "\n                    "
                          )
                        ])
                      : [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("getAvatarInitials")(
                                  _vm.users.proposalTeam.session
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                  ],
                  2
                ),
                _c(
                  "v-list-tile-content",
                  [
                    _c("v-list-tile-title", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.users.proposalTeam.user.name) +
                          "\n                    "
                      )
                    ])
                  ],
                  1
                ),
                _vm.users.proposalTeam.assignable
                  ? _c(
                      "v-list-tile-action",
                      [
                        _vm.users.proposalTeam.unassigned
                          ? _c(
                              "v-btn",
                              {
                                attrs: { color: "secondary", flat: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.confirmAssign(
                                      _vm.users.proposalTeam.user
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        Assign\n                    "
                                )
                              ]
                            )
                          : _c(
                              "v-btn",
                              {
                                attrs: { color: "secondary", flat: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.confirmUnassign(
                                      _vm.users.proposalTeam.user
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        Unassign\n                    "
                                )
                              ]
                            )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("v-subheader", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.users.projectManager.user.title) +
                  "\n            "
              )
            ]),
            _c(
              "v-list-tile",
              [
                _c(
                  "v-list-tile-avatar",
                  { attrs: { color: _vm.projectManagerIconBackground } },
                  [
                    !_vm.users.projectManager.session
                      ? _c("v-icon", { attrs: { dark: "", size: "20" } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.users.projectManager.user.icon) +
                              "\n                    "
                          )
                        ])
                      : [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("getAvatarInitials")(
                                  _vm.users.projectManager.session
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                  ],
                  2
                ),
                _c(
                  "v-list-tile-content",
                  [
                    _c("v-list-tile-title", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.users.projectManager.user.name) +
                          "\n                    "
                      )
                    ])
                  ],
                  1
                ),
                _vm.users.projectManager.assignable
                  ? _c(
                      "v-list-tile-action",
                      [
                        _vm.users.projectManager.unassigned
                          ? _c(
                              "v-btn",
                              {
                                attrs: { color: "secondary", flat: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.confirmAssign(
                                      _vm.users.projectManager.user
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        Assign\n                    "
                                )
                              ]
                            )
                          : _c(
                              "v-btn",
                              {
                                attrs: { color: "secondary", flat: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.confirmUnassign(
                                      _vm.users.projectManager.user
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        Unassign\n                    "
                                )
                              ]
                            )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          2
        )
      ],
      _c(
        "v-dialog",
        {
          attrs: { width: "600px" },
          model: {
            value: _vm.editLeadSalesRepDialog,
            callback: function($$v) {
              _vm.editLeadSalesRepDialog = $$v
            },
            expression: "editLeadSalesRepDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline", attrs: { "primary-title": "" } },
                [_vm._v("\n                Edit Lead Sales Rep\n            ")]
              ),
              _c(
                "v-card-text",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      label: "Lead Sales Rep",
                      items: _vm.filteredSalesRepList,
                      loading: _vm.isLoading,
                      "item-text": "userName",
                      "item-value": "userName",
                      type: "search",
                      "return-object": "",
                      clearable: "",
                      "clear-icon": "fas fa-times",
                      chips: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function(data) {
                          return [
                            _c(
                              "v-list-tile-avatar",
                              {
                                attrs: {
                                  color: _vm._f("getAvatarColor")(data.item)
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("getAvatarInitials")(data.item)
                                    ) +
                                    "\n                        "
                                )
                              ]
                            ),
                            _vm._v(
                              "\n                        " +
                                _vm._s(data.item.userName) +
                                "\n                    "
                            )
                          ]
                        }
                      },
                      {
                        key: "selection",
                        fn: function(data) {
                          return [
                            _c("user-chip", { attrs: { session: data.item } })
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedLeadSalesRep,
                      callback: function($$v) {
                        _vm.selectedLeadSalesRep = $$v
                      },
                      expression: "selectedLeadSalesRep"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: { click: _vm.cancelEditLeadSalesRep }
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.selectedLeadSalesRep,
                        color: "primary"
                      },
                      on: { click: _vm.confirmEditLeadSalesRep }
                    },
                    [_vm._v("\n                    Save\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600px" },
          model: {
            value: _vm.addSupportingSalesRepsDialog,
            callback: function($$v) {
              _vm.addSupportingSalesRepsDialog = $$v
            },
            expression: "addSupportingSalesRepsDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline", attrs: { "primary-title": "" } },
                [
                  _vm._v(
                    "\n                Add Supporting Sales Reps\n            "
                  )
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      label: "Supporting Sales Rep(s)",
                      items: _vm.filteredSalesRepList,
                      loading: _vm.isLoading,
                      "item-text": "userName",
                      "item-value": "userName",
                      type: "search",
                      multiple: "",
                      "hide-selected": "",
                      "return-object": "",
                      clearable: "",
                      "clear-icon": "fas fa-times",
                      chips: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function(data) {
                          return [
                            _c(
                              "v-list-tile-avatar",
                              {
                                attrs: {
                                  color: _vm._f("getAvatarColor")(data.item)
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("getAvatarInitials")(data.item)
                                    ) +
                                    "\n                        "
                                )
                              ]
                            ),
                            _vm._v(
                              "\n                        " +
                                _vm._s(data.item.userName) +
                                "\n                    "
                            )
                          ]
                        }
                      },
                      {
                        key: "selection",
                        fn: function(data) {
                          return [
                            _c("user-chip", { attrs: { session: data.item } })
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedSupportingSalesReps,
                      callback: function($$v) {
                        _vm.selectedSupportingSalesReps = $$v
                      },
                      expression: "selectedSupportingSalesReps"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: { click: _vm.cancelAssignSupportingSalesReps }
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.selectedSupportingSalesReps.length == 0,
                        color: "primary"
                      },
                      on: { click: _vm.confirmAssignSupportingSalesReps }
                    },
                    [_vm._v("\n                    Add\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "350px" },
          model: {
            value: _vm.unassignSupportingSalesRepDialog,
            callback: function($$v) {
              _vm.unassignSupportingSalesRepDialog = $$v
            },
            expression: "unassignSupportingSalesRepDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline", attrs: { "primary-title": "" } },
                [
                  _vm._v(
                    "\n                Unassign " +
                      _vm._s(_vm.salesRepToUnassign.userName) +
                      "?\n            "
                  )
                ]
              ),
              _c("v-card-text", [
                _vm._v(
                  "\n                Are you sure you wish to remove " +
                    _vm._s(_vm.salesRepToUnassign.userName) +
                    " as a supporting sales rep?\n            "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.unassignSupportingSalesRepDialog = false
                        }
                      }
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.onConfirmUnassignSupportingSalesRep()
                        }
                      }
                    },
                    [_vm._v("\n                    Unassign\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "350px" },
          model: {
            value: _vm.assignmentConfirmationDialog,
            callback: function($$v) {
              _vm.assignmentConfirmationDialog = $$v
            },
            expression: "assignmentConfirmationDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline", attrs: { "primary-title": "" } },
                [
                  _vm._v(
                    "\n                Assign yourself as " +
                      _vm._s(_vm.userToAssign.title) +
                      "?\n            "
                  )
                ]
              ),
              _c("v-card-text", [
                _vm._v(
                  "\n                Are you sure you want to assign yourself as " +
                    _vm._s(_vm.userToAssign.title)
                ),
                _vm.userToAssign.title === "Proposal Team"
                  ? _c("span", [_vm._v(" Member")])
                  : _vm._e(),
                _vm._v(" for this project?\n            ")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { flat: "" }, on: { click: _vm.cancelAssign } },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onAcceptAssignment()
                        }
                      }
                    },
                    [_vm._v("\n                    Assign\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !!_vm.userToUnassign
        ? _c(
            "v-dialog",
            {
              attrs: { width: "350px" },
              model: {
                value: _vm.unassignmentConfirmationDialog,
                callback: function($$v) {
                  _vm.unassignmentConfirmationDialog = $$v
                },
                expression: "unassignmentConfirmationDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline", attrs: { "primary-title": "" } },
                    [
                      _vm._v(
                        "\n                Unassign " +
                          _vm._s(_vm.userToUnassign.title) +
                          "?\n            "
                      )
                    ]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      "\n                Are you sure you wish to unassign the " +
                        _vm._s(_vm.userToUnassign.title)
                    ),
                    _vm.userToUnassign.title === "Proposal Team"
                      ? _c("span", [_vm._v(" Member")])
                      : _vm._e(),
                    _vm._v(" for this project?\n            ")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "" },
                          on: {
                            click: function($event) {
                              return _vm.cancelUnassign()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Cancel\n                "
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              return _vm.onAcceptUnassignment()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Unassign\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }