var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline justify-center",
                  attrs: { "primary-title": "" }
                },
                [_vm._v("\n                Login\n            ")]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { staticClass: "text-xs-center" },
                    [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.onSubmit($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            { attrs: { type: "submit", color: "primary" } },
                            [
                              _vm._v(
                                "\n                            Simulate Login\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.copy }
                        },
                        [
                          _vm._v(
                            "\n                        Copy JWT to clipboard\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("v-container", [
                    _c(
                      "ul",
                      _vm._l(_vm.claims, function(c) {
                        return _c("li", { key: c.key }, [
                          _c("strong", [_vm._v(_vm._s(c.key))]),
                          _vm._v(
                            ": " +
                              _vm._s(c.value) +
                              "\n                        "
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }