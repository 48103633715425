import { NavigationGuard } from 'vue-router'
import UserBus, { AUTH_COOKIE_NAME, ROLE_SALES_REP } from '../buses/UserBus'
import SnackBus from '../buses/SnackBus'
import { requestErrorMessage } from '../constants/errors'
import { getCookie } from '../helpers'

const guard: NavigationGuard = (to, from, next) => {
    // if token not avail - send to auth check to check / set http cookie
    if (getCookie(AUTH_COOKIE_NAME) === '') {
        window.location.href = process.env.VUE_APP_AUTH_CHECK_URL || '/auth-check-error'
        return false
    }

    // validate token
    return UserBus.validateUser()
        .then(res => {
            if (res.data.data.role === ROLE_SALES_REP) {
                window.location.replace(process.env.VUE_APP_PROJECT_CENTER_URL + to.fullPath)
            }
            next()
        })
        .catch(err => {
            // if error is a 401, redirect user
            if (err && err.status === 401) {
                // if error is from login, trigger a manual redirect to target location
                if (from.name === 'login') {
                    window.location.href = to.fullPath
                }

                if (process.env.NODE_ENV === 'development') {
                    next({ name: 'login' }) // simulate login screen
                } else {
                    window.location.href = 'https://www.garlandhq.com' // force to login to HQ
                }
            } else {
                // show error message for any other errors
                SnackBus.showMessage(requestErrorMessage, 'error')
            }
        })
}

export default guard
