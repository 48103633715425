import { render, staticRenderFns } from "./SnackMessages.vue?vue&type=template&id=c5be528c&"
import script from "./SnackMessages.vue?vue&type=script&lang=ts&"
export * from "./SnackMessages.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!/codebuild/output/src2994353656/src/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VSnackbar } from 'vuetify/lib'
installComponents(component, {VBtn,VIcon,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2994353656/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('c5be528c', component.options)
    } else {
      api.reload('c5be528c', component.options)
    }
    module.hot.accept("./SnackMessages.vue?vue&type=template&id=c5be528c&", function () {
      api.rerender('c5be528c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SnackMessages.vue"
export default component.exports