var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-content",
    { attrs: { loading: _vm.isLoading, error: _vm.error } },
    [
      _vm.project
        ? _c(
            "v-container",
            { attrs: { "grid-list-lg": "", "data-cy": "project-details" } },
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "transparent" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        to: { name: "projects" },
                        "active-class": ""
                      }
                    },
                    [_c("v-icon", [_vm._v("fas fa-arrow-left")])],
                    1
                  ),
                  _c("v-toolbar-title", [
                    _vm._v(_vm._s(_vm.project.name) + " "),
                    _vm.project.projectNumber != null
                      ? _c("span", [
                          _vm._v("(" + _vm._s(_vm.project.projectNumber) + ")")
                        ])
                      : _vm._e()
                  ]),
                  _c("v-spacer")
                ],
                1
              ),
              _c(
                "transition-group",
                {
                  staticClass: "text-xs-right",
                  attrs: { name: "fade-transition", tag: "div" }
                },
                [
                  _vm.isLoadingPermissions
                    ? _c("v-btn", {
                        key: "loading",
                        attrs: { icon: "", loading: _vm.isLoadingPermissions }
                      })
                    : _vm._e(),
                  !_vm.pastBidDueDate
                    ? _c(
                        "v-dialog",
                        {
                          key: "inviteContractorsDialog",
                          attrs: { width: "600" },
                          scopedSlots: _vm._u(
                            [
                              !_vm.pastBidDueDate
                                ? {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              key: "inviteContractors",
                                              attrs: { color: "secondary" }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "pr-2",
                                                attrs: { size: "20" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                            fas fa-user-plus\n                        "
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                        Invite Contractors\n                    "
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                : null
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.inviteContractorDialog,
                            callback: function($$v) {
                              _vm.inviteContractorDialog = $$v
                            },
                            expression: "inviteContractorDialog"
                          }
                        },
                        [
                          !_vm.pastBidDueDate
                            ? _c("invite-contractor", {
                                attrs: {
                                  "contractor-list": _vm.contractors,
                                  "reset-contractor-form":
                                    _vm.resetInviteContractorDialog,
                                  "is-deleting": _vm.isDeletingContractor,
                                  "can-send-calendar-invites":
                                    _vm.canSendCalendarInvites && !_vm.isDraft
                                },
                                on: {
                                  "close-dialog": function($event) {
                                    _vm.inviteContractorDialog = false
                                  },
                                  "reload-data": _vm.readContractors,
                                  "send-invite": _vm.sendInvite,
                                  "confirm-delete": _vm.onDeleteConfirmDialog
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-dialog",
                    {
                      key: "supportingFilePicker",
                      attrs: { width: "700" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      key: "inviteContractors",
                                      attrs: {
                                        loading: _vm.filesLoading,
                                        color: "secondary"
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "pr-2",
                                        attrs: { size: "20" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            fas fa-file-upload\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                        Upload Files\n                    "
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        547623107
                      ),
                      model: {
                        value: _vm.openSupportingFilesPicker,
                        callback: function($$v) {
                          _vm.openSupportingFilesPicker = $$v
                        },
                        expression: "openSupportingFilesPicker"
                      }
                    },
                    [
                      _vm.openSupportingFilesPicker
                        ? _c("file-upload", {
                            attrs: {
                              "root-id": "supportingFiles",
                              title: "Supporting Files",
                              description:
                                "Please attach scope of work, specification, details, RAMP report, asbestos testing, wind uplift, pull tests, architect/engineering documents, permitting requirements, etc."
                            },
                            on: {
                              "close-dialog": function($event) {
                                _vm.openSupportingFilesPicker = false
                              },
                              "on-filestack-upload-done":
                                _vm.onFilestackUploadDone
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.canEdit
                    ? _c(
                        "v-tooltip",
                        {
                          key: "editProjectTooltip",
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _vm.canEdit
                                      ? _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              key: "edit",
                                              staticClass: "secondary",
                                              attrs: {
                                                to: {
                                                  name: "editProject",
                                                  props: { id: _vm.project.id }
                                                },
                                                "data-cy": "project-edit-button"
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "pr-2",
                                                attrs: { size: "20" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                            fas fa-pencil-alt\n                        "
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                        Edit Project\n                    "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3839189629
                          )
                        },
                        [_c("span", [_vm._v("Edit Project")])]
                      )
                    : _vm._e(),
                  _vm.canEdit &&
                  _vm.addendumTimelineItems.length &&
                  !_vm.pastBidDueDate &&
                  _vm.project.status !== "DRAFT"
                    ? _c(
                        "v-tooltip",
                        {
                          key: "submitAddendumTooltip",
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          key: "submitAddendum",
                                          staticClass: "secondary",
                                          attrs: {
                                            loading: _vm.addendumIsSubmitting
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.submitAddendumConfirmDialog = true
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-badge",
                                          {
                                            staticClass:
                                              "dbs-icon-button-badge",
                                            attrs: { left: "", top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "badge",
                                                  fn: function() {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .addendumTimelineItems
                                                              .length
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "pr-2",
                                                attrs: { size: "20" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                fas fa-paper-plane\n                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                            Send Addendum"
                                            ),
                                            _vm.addendumTimelineItems > 1
                                              ? _c("span", [_vm._v("s")])
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1656191460
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Send " +
                                _vm._s(_vm.addendumTimelineItems.length) +
                                " change"
                            ),
                            _vm.addendumTimelineItems.length > 1
                              ? _c("span", [_vm._v("s")])
                              : _vm._e(),
                            _vm._v(" to contractors.")
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.canSubmit
                    ? _c(
                        "v-tooltip",
                        {
                          key: "submitProjectTooltip",
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          key: "submitProject",
                                          staticClass: "secondary",
                                          attrs: {
                                            disabled: _vm.isSubmitting,
                                            loading: _vm.isSubmitting
                                          },
                                          on: {
                                            click: _vm.onSubmitConfirmDialog
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "pr-2",
                                            attrs: { size: "20" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            fas fa-paper-plane\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                        Send Invitation"
                                        ),
                                        _vm.invitations.length > 1
                                          ? _c("span", [_vm._v("s")])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3042269155
                          )
                        },
                        [_c("span", [_vm._v("Submit Project")])]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "600", scrollable: "" },
                  model: {
                    value: _vm.submitAddendumConfirmDialog,
                    callback: function($$v) {
                      _vm.submitAddendumConfirmDialog = $$v
                    },
                    expression: "submitAddendumConfirmDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { attrs: { "primary-title": "" } }, [
                        _c("h3", { staticClass: "headline" }, [
                          _vm._v(
                            "\n                        Submit Addendum\n                    "
                          )
                        ])
                      ]),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        [
                          _c("h3", { staticClass: "title py-2" }, [
                            _vm._v(
                              "\n                        Project Changes\n                    "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "my-3" },
                            [
                              _c("addendum-project-timeline", {
                                attrs: { items: _vm.addendumTimelineItems }
                              })
                            ],
                            1
                          ),
                          _c("h3", { staticClass: "title" }, [
                            _vm._v(
                              "\n                        Addendum Notes\n                    "
                            )
                          ]),
                          _c("v-textarea", {
                            attrs: { placeholder: "Enter notes here" },
                            model: {
                              value: _vm.addendumNotes,
                              callback: function($$v) {
                                _vm.addendumNotes = $$v
                              },
                              expression: "addendumNotes"
                            }
                          }),
                          _c("h3", { staticClass: "subheading" }, [
                            _vm._v(
                              "\n                        Once you submit the addendum for this project, contractors will be notified of these project changes.\n                    "
                            )
                          ]),
                          _vm.canSendUpdatedCalendarInvites
                            ? _c("v-checkbox", {
                                attrs: {
                                  label:
                                    "Send updated Pre-Bid calendar invite, if applicable.",
                                  color: "secondary",
                                  reverse: ""
                                },
                                model: {
                                  value: _vm.shouldSendCalendarInvites,
                                  callback: function($$v) {
                                    _vm.shouldSendCalendarInvites = $$v
                                  },
                                  expression: "shouldSendCalendarInvites"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "" },
                              on: {
                                click: function($event) {
                                  _vm.submitAddendumConfirmDialog = false
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        Cancel\n                    "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.addendumIsSubmitting
                              },
                              on: { click: _vm.onSubmitAddendum }
                            },
                            [
                              _vm._v(
                                "\n                        Submit\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "290" },
                  model: {
                    value: _vm.submitConfirmDialog,
                    callback: function($$v) {
                      _vm.submitConfirmDialog = $$v
                    },
                    expression: "submitConfirmDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "headline",
                          attrs: { "primary-title": "" }
                        },
                        [
                          _vm._v(
                            "\n                    Submit project?\n                "
                          )
                        ]
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c("p", [
                            _vm._v(
                              "\n                        Once you submit this project, contractors will receive their invitations to bid. Are you sure you wish to continue?\n                    "
                            )
                          ]),
                          _vm.canSendNewCalendarInvites
                            ? _c("v-checkbox", {
                                attrs: {
                                  label: "Send Pre-Bid Calendar Invite",
                                  color: "secondary",
                                  reverse: ""
                                },
                                model: {
                                  value: _vm.shouldSendCalendarInvites,
                                  callback: function($$v) {
                                    _vm.shouldSendCalendarInvites = $$v
                                  },
                                  expression: "shouldSendCalendarInvites"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "" },
                              on: {
                                click: function($event) {
                                  _vm.submitConfirmDialog = false
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        Cancel\n                    "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.onSubmit }
                            },
                            [
                              _vm._v(
                                "\n                        Yes\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("confirmation-dialog", {
                attrs: {
                  "show-dialog": _vm.showDeleteContractorConfirmDialog,
                  message: "This will delete the contractor."
                },
                on: {
                  "close-dialog": function($event) {
                    return _vm.onDeleteConfirmDialog(0)
                  },
                  "confirm-action": _vm.onDeleteContractor
                }
              }),
              _c(
                "v-layout",
                { attrs: { "justify-center": "", row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm8: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-left": "", row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.validationErrors.length
                                ? _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        value: true,
                                        color: "error",
                                        icon: "fas fa-exclamation-triangle"
                                      }
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          "The following items are required before invitations can be sent:"
                                        )
                                      ]),
                                      _c(
                                        "ul",
                                        _vm._l(_vm.validationErrors, function(
                                          error
                                        ) {
                                          return _c(
                                            "li",
                                            {
                                              key: error.id,
                                              attrs: { error: error.error }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(error.error) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { attrs: { "primary-title": "" } },
                                    [
                                      _c("div", { staticClass: "headline" }, [
                                        _vm._v(
                                          "\n                                    Project Details\n                                "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c("key-value-table", [
                                    _c("tbody", [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          { staticClass: "shrink-column" },
                                          [_vm._v("Status")]
                                        ),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("getStatusText")(
                                                _vm.project.status
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          { staticClass: "shrink-column" },
                                          [_vm._v("Project Type")]
                                        ),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("getProjectTypeText")(
                                                _vm.project.type
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("Customer Name")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.project.customer &&
                                                _vm.project.customer.name
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v("Customer Contact Details")
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "text-pre-wrap" },
                                          [
                                            _vm.project.customerContact
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f(
                                                        "getCustomerContactString"
                                                      )(
                                                        _vm.project
                                                          .customerContact
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      ]),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v("Customer Contact Address")
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "text-pre-wrap" },
                                          [
                                            _vm.project.customerContact
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f(
                                                        "getAddressString"
                                                      )(
                                                        _vm.project
                                                          .customerContact
                                                          .address
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("Site Name")]),
                                        _c("td", [
                                          _vm._v(_vm._s(_vm.project.siteName))
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("Site Address")]),
                                        _c(
                                          "td",
                                          { staticClass: "text-pre-wrap" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("getAddressString")(
                                                  _vm.project.siteAddress
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm.project.prebidDate
                                        ? _c("tr", [
                                            _c("td", [
                                              _vm._v("Pre-Bid Meeting Date")
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatLocalDate")(
                                                    _vm.project.prebidDate,
                                                    "L LT z",
                                                    _vm.project.prebidTimeZone
                                                  )
                                                )
                                              )
                                            ])
                                          ])
                                        : _vm._e(),
                                      _vm.project.prebidAddress
                                        ? _c("tr", [
                                            _c("td", [
                                              _vm._v("Pre-Bid Meeting Location")
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-pre-wrap" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f(
                                                        "getAddressString"
                                                      )(
                                                        _vm.project
                                                          .prebidAddress
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm.project.prebidNote
                                        ? _c("tr", [
                                            _c("td", [_vm._v("Pre-Bid Notes")]),
                                            _c("td", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-pre-wrap"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.project.prebidNote
                                                    )
                                                  )
                                                ]
                                              )
                                            ])
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "tr",
                                        { staticClass: "dbs-file-added-row" },
                                        [
                                          _c("td", [_vm._v("Scope of Work")]),
                                          _vm.scopeOfWorkFile
                                            ? [
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _c(
                                                          "v-list-tile",
                                                          {
                                                            staticClass:
                                                              "elevation-1 white dbs-file-tile-content",
                                                            attrs: {
                                                              href:
                                                                _vm
                                                                  .scopeOfWorkFile
                                                                  .path,
                                                              target: "_blank"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  size: "16",
                                                                  left: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                            fas fa-download\n                                                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-tile-content",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-truncate"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .scopeOfWorkFile
                                                                            .filename
                                                                        ) +
                                                                        "\n                                                            "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-tile-action",
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      flat: "",
                                                                      icon: "",
                                                                      color:
                                                                        "rgba(0,0,0,0.54)",
                                                                      disabled:
                                                                        _vm
                                                                          .scopeOfWorkFile
                                                                          .isDeleting,
                                                                      loading:
                                                                        _vm
                                                                          .scopeOfWorkFile
                                                                          .isDeleting
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        _vm.deleteScopeOfWorkConfirmDialog = true
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          size:
                                                                            "20"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    fas fa-trash\n                                                                "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.deleteScopeOfWorkConfirmDialog
                                                                  ? _c(
                                                                      "confirmation-dialog",
                                                                      {
                                                                        attrs: {
                                                                          "show-dialog":
                                                                            _vm.deleteScopeOfWorkConfirmDialog,
                                                                          header:
                                                                            "Delete File?",
                                                                          message:
                                                                            "This will permanently remove the file from this project.",
                                                                          action:
                                                                            "Delete"
                                                                        },
                                                                        on: {
                                                                          "close-dialog": function(
                                                                            $event
                                                                          ) {
                                                                            _vm.deleteScopeOfWorkConfirmDialog = false
                                                                          },
                                                                          "confirm-action":
                                                                            _vm.onDeleteScopeOfWork
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            : [
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          flat: "",
                                                          color: "secondary",
                                                          disabled:
                                                            _vm.isUploadingScopeOfWork,
                                                          loading:
                                                            _vm.isUploadingScopeOfWork
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.showScopeOfWorkUploadDialog = true
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Upload File\n                                                "
                                                        )
                                                      ]
                                                    ),
                                                    _vm.project.scopeOfWork
                                                      ? [
                                                          _c("v-divider", {
                                                            staticClass: "pb-2"
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-pre-wrap"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.project
                                                                    .scopeOfWork
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                )
                                              ],
                                          _c(
                                            "v-dialog",
                                            {
                                              attrs: { width: "700px" },
                                              model: {
                                                value:
                                                  _vm.showScopeOfWorkUploadDialog,
                                                callback: function($$v) {
                                                  _vm.showScopeOfWorkUploadDialog = $$v
                                                },
                                                expression:
                                                  "showScopeOfWorkUploadDialog"
                                              }
                                            },
                                            [
                                              _vm.showScopeOfWorkUploadDialog
                                                ? _c("file-upload", {
                                                    attrs: {
                                                      "root-id": "scopeOfWork",
                                                      title:
                                                        "Upload a Scope Of Work Document",
                                                      description:
                                                        "If you have a document detailing the scope of work, please attach that here.",
                                                      "max-files": 1
                                                    },
                                                    on: {
                                                      "close-dialog": function(
                                                        $event
                                                      ) {
                                                        _vm.showScopeOfWorkUploadDialog = false
                                                      },
                                                      "on-filestack-upload-done":
                                                        _vm.uploadScopeOfWork
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      ),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(
                                            "Will this project require a torch?"
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              this.project.torchRequired
                                                ? "Yes"
                                                : "No"
                                            )
                                          )
                                        ])
                                      ]),
                                      _vm.bidForm
                                        ? _c(
                                            "tr",
                                            {
                                              staticClass: "dbs-file-added-row"
                                            },
                                            [
                                              _c("td", [_vm._v("Bid Form")]),
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "v-list",
                                                    [
                                                      _c(
                                                        "v-list-tile",
                                                        {
                                                          staticClass:
                                                            "elevation-1 white dbs-file-tile-content",
                                                          attrs: {
                                                            href:
                                                              _vm.bidForm.path,
                                                            target: "_blank"
                                                          }
                                                        },
                                                        [
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  size: "16",
                                                                  left: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                            fas fa-download\n                                                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-tile-content",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-truncate"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .bidForm
                                                                            .filename
                                                                        ) +
                                                                        "\n                                                            "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          _vm.bidForm.id !==
                                                          null
                                                            ? _c(
                                                                "v-list-tile-action",
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        flat:
                                                                          "",
                                                                        icon:
                                                                          "",
                                                                        color:
                                                                          "rgba(0,0,0,0.54)",
                                                                        disabled:
                                                                          _vm.customBidFormIsDeleting,
                                                                        loading:
                                                                          _vm.customBidFormIsDeleting
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          _vm.deleteCustomBidFormConfirmDialog = true
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                            size:
                                                                              "20"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                fas fa-trash\n                                                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm.deleteCustomBidFormConfirmDialog
                                                                    ? _c(
                                                                        "confirmation-dialog",
                                                                        {
                                                                          attrs: {
                                                                            "show-dialog":
                                                                              _vm.deleteCustomBidFormConfirmDialog,
                                                                            header:
                                                                              "Delete File?",
                                                                            message:
                                                                              "This will permanently remove the file from this project.",
                                                                            action:
                                                                              "Delete"
                                                                          },
                                                                          on: {
                                                                            "close-dialog": function(
                                                                              $event
                                                                            ) {
                                                                              _vm.deleteCustomBidFormConfirmDialog = false
                                                                            },
                                                                            "confirm-action":
                                                                              _vm.onDeleteCustomBidForm
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.bidForm.id ===
                                                          null
                                                            ? _c(
                                                                "v-list-tile-action",
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "px-3",
                                                                      attrs: {
                                                                        flat:
                                                                          "",
                                                                        color:
                                                                          "secondary"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          _vm.uploadCustomBidFormDialog = true
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                            Upload File\n                                                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-dialog",
                                                    {
                                                      attrs: { width: "700px" },
                                                      model: {
                                                        value:
                                                          _vm.uploadCustomBidFormDialog,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.uploadCustomBidFormDialog = $$v
                                                        },
                                                        expression:
                                                          "uploadCustomBidFormDialog"
                                                      }
                                                    },
                                                    [
                                                      _vm.uploadCustomBidFormDialog
                                                        ? _c("file-upload", {
                                                            attrs: {
                                                              "root-id":
                                                                "customBidForm",
                                                              title:
                                                                "Upload Custom Bid Form",
                                                              description:
                                                                "If you have a bid form with changes that you would like contractors to complete, please attach that here.",
                                                              "max-files": 1
                                                            },
                                                            on: {
                                                              "close-dialog": function(
                                                                $event
                                                              ) {
                                                                _vm.uploadCustomBidFormDialog = false
                                                              },
                                                              "on-filestack-upload-done":
                                                                _vm.uploadCustomBidForm
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("tr", [
                                        _c("td", [_vm._v("Bid Due Date")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatLocalDate")(
                                                _vm.project.bidDueDate,
                                                "L LT z",
                                                _vm.project.bidDueTimeZone
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v("Requested Proposal Date")
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatUtcDate")(
                                                _vm.project.proposalDueDate
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v("Special Instructions")
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "text-pre-wrap" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.project.specialInstructions
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("Estimated Budget")]),
                                        _c(
                                          "td",
                                          { staticClass: "text-pre-wrap" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatCurrency")(
                                                  _vm.project.estimatedBudget
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", md6: "" } },
                            [
                              _c("contractor-invites", {
                                attrs: {
                                  "bid-invitations": _vm.invitations,
                                  "bid-due-date": _vm.project.bidDueDate,
                                  "bid-due-time-zone":
                                    _vm.project.bidDueTimeZone,
                                  "is-loading": _vm.bidsLoading,
                                  "promoting-bid": _vm.promotingBid,
                                  "show-download-spinner":
                                    _vm.isDownloadingFiles
                                },
                                on: {
                                  "delete-invite": _vm.onDeleteInvitation,
                                  "resend-invite": _vm.onResendInvitation,
                                  "on-promote-bid": _vm.onPromoteBid,
                                  "reload-data": _vm.loadPage,
                                  "add-invite": function($event) {
                                    _vm.inviteContractorDialog = true
                                  },
                                  "download-bids": _vm.onDownloadBids
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", md6: "" } },
                            [
                              _c("supporting-files-card", {
                                attrs: {
                                  files: _vm.files,
                                  "is-loading": _vm.filesLoading
                                },
                                on: {
                                  "delete-file": _vm.onDeleteFile,
                                  "add-file": function($event) {
                                    _vm.openSupportingFilesPicker = true
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", md6: "" } },
                            [
                              _c("shared-links-card", {
                                attrs: { contacts: _vm.contacts },
                                on: { "reload-data": _vm.readProject }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm4: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-center": "", row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("project-team", {
                                attrs: { users: _vm.projectTeamList },
                                on: {
                                  "on-edit-lead-sales-rep":
                                    _vm.onEditLeadSalesRep,
                                  "on-accept-assignment":
                                    _vm.onAcceptAssignment,
                                  "on-accept-unassignment":
                                    _vm.onAcceptUnassignment,
                                  "on-assign-supporting-sales-reps":
                                    _vm.onAssignSupportingSalesReps,
                                  "on-unassign-supporting-sales-rep":
                                    _vm.onUnassignSupportingSalesRep
                                }
                              })
                            ],
                            1
                          ),
                          _vm.project.addendums.length > 0
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("addenda-card", {
                                    attrs: {
                                      addendums: _vm.shownAddendumList,
                                      "show-all": _vm.showAllAddendums,
                                      expandable:
                                        _vm.project.addendums.length > 3
                                    },
                                    on: {
                                      "show-all-addendums": function($event) {
                                        _vm.showAllAddendums = !_vm.showAllAddendums
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { attrs: { "primary-title": "" } },
                                    [
                                      _c("div", { staticClass: "headline" }, [
                                        _vm._v(
                                          "\n                                    Project Timeline\n                                "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "projectTimelineColumn" },
                                    [
                                      _c("project-timeline", {
                                        attrs: { items: _vm.timelineItems }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("reload-snack-message", {
        attrs: { show: _vm.reloadPage },
        on: { "reload-page": _vm.onReloadPage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }