/// Project statuses

export const DRAFT = 'DRAFT'
export const UNDER_REVIEW = 'UNDER_REVIEW'
export const ASSIGNED = 'ASSIGNED'
export const INVITATIONS_SENT = 'INVITATIONS_SENT'
export const BID_ACCEPTED = 'BID_ACCEPTED'
export const MANAGED = 'MANAGED'
export const CLOSED = 'CLOSED'
export const ARCHIVED = 'ARCHIVED'

export interface Status {
    text: string;
    value: string;
    icon?: string;
}

export const statuses: {
    [key: string]: Status
} = {
    [DRAFT]: {
        text: 'Draft',
        value: DRAFT,
        icon: 'fas fa-file-signature'
    },
    [UNDER_REVIEW]: {
        text: 'Under Review',
        value: UNDER_REVIEW,
        icon: 'fas fa-search'
    },
    [ASSIGNED]: {
        text: 'Assigned',
        value: ASSIGNED,
        icon: 'fas fa-user-tag'
    },
    [INVITATIONS_SENT]: {
        text: 'Invitations Sent',
        value: INVITATIONS_SENT,
        icon: 'fa fa-envelope'
    },
    [BID_ACCEPTED]: {
        text: 'Bids Accepted',
        value: BID_ACCEPTED,
        icon: 'fas fa-clipboard-check'
    },
    [MANAGED]: {
        text: 'Managed',
        value: MANAGED,
        icon: 'fas fa-tasks'
    },
    [CLOSED]: {
        text: 'Closed',
        value: CLOSED,
        icon: 'far fa-check-square'
    },
    [ARCHIVED]: {
        text: 'Archived',
        value: ARCHIVED,
        icon: 'far fa-archive'
    }
}

export const statusesArray: Status[] = Object.keys(statuses).map(key => statuses[key])
