























































































































































































































































































































































































































































































































































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import {
    Address,
    AddressOrNull,
    CustomerAllData,
    CustomerContact,
    CustomerContactOrNull,
    CustomerOrNull,
    emptyAddress,
    generateEmptyCustomer,
    generateEmptyCustomerContact,
    NumberOrNull,
    PostProjectResponse,
    PostUploadsToProjectResponse,
    SessionOrNull,
    StringOrNull,
    VForm
} from '@/types'
import SnackBus from '../../utils/buses/SnackBus'
import { defaultError422, requestErrorMessage } from '@/utils/constants/errors'
import moment from 'moment-timezone'
import FileUpload from '../../components/Files/FileUpload.vue'
import AddressForm from '../../components/AddressForm.vue'
import CustomerForm from '../../components/CustomerForm.vue'
import ContactForm from '../../components/Contacts/ContactForm.vue'
import UserChip from '../../components/UserChip.vue'
import UserBus from '../../utils/buses/UserBus'
import * as filestack from 'filestack-js'
import { validationRules } from '@/utils/rules'
import {
    getTimes,
    getTimeZones,
    SelectItem,
    verifyAddressIsPresent,
    verifyAddressReturned,
    verifyContactReturned
} from '@/utils/helpers'
import { ProjectType, projectTypeArray, UNSPECIFIED } from '@/utils/constants/projectTypes'
import { DateTime } from 'luxon-business-days'
import {
    Contact,
    createContactMutation,
    createProjectCenterAddressMutation,
    useContactsQuery,
    useGetCustomersQuery,
    createCustomerMutation
} from '@/graphql/graphql'
import {
    mapCustomerContactToContactInput,
    mapProjectCenterAddressToCreateProjectCenterAddressInput
} from '@/utils/contactConversions'

const times = getTimes()
const timeZones = getTimeZones()

interface ProjectForm {
    name: StringOrNull,
    type: StringOrNull,
    projectNumber: StringOrNull,
    customer: CustomerOrNull,
    customerContact: CustomerContactOrNull,
    customerContactId: string | null,
    siteName: StringOrNull,
    siteAddress: Address,
    scopeOfWork: StringOrNull,
    bidDueDate: StringOrNull,
    bidDueTime: StringOrNull,
    bidDueTimeZone: StringOrNull,
    prebidDate: StringOrNull,
    prebidTime: StringOrNull,
    prebidTimeZone: StringOrNull,
    prebidAddress: AddressOrNull,
    prebidNote: StringOrNull,
    proposalDueDate: StringOrNull,
    specialInstructions: StringOrNull,
    estimatedBudget: NumberOrNull,
    torchRequired: boolean,
    bidManagementType: string,
}

@Component({
    components: {
        FileUpload,
        AddressForm,
        CustomerForm,
        ContactForm,
        UserChip
    },
    apollo: {
    /* eslint-disable no-use-before-define */
        contactList: useContactsQuery<AddProject>({
            variables: {},
            fetchPolicy: 'no-cache',
            update (data: any) {
                return data.contacts
            }
        }),
        customers: useGetCustomersQuery<AddProject>({
            variables: {},
            fetchPolicy: 'no-cache'
        })
    /* eslint-enable no-use-before-define */
    }
})
export default class AddProject extends Vue {
    private form: ProjectForm = {
        name: null,
        type: UNSPECIFIED,
        projectNumber: null,
        customer: generateEmptyCustomer(),
        customerContact: generateEmptyCustomerContact(null),
        customerContactId: null,
        siteName: null,
        siteAddress: { ...emptyAddress },
        scopeOfWork: null,
        bidDueDate: null,
        bidDueTime: times[0].value,
        bidDueTimeZone: timeZones[0].value,
        prebidDate: null,
        prebidTime: times[0].value,
        prebidTimeZone: timeZones[0].value,
        prebidAddress: { ...emptyAddress },
        prebidNote: null,
        proposalDueDate: null,
        specialInstructions: null,
        estimatedBudget: null,
        torchRequired: false,
        bidManagementType: 'DBS_MANAGED'
    }
    private files: filestack.PickerFileMetadata[] = []
    private scopeOfWorkFile: filestack.PickerFileMetadata | null = null
    private isUploadingScopeOfWork: boolean = false
    private isIncludingPrebid: boolean = false
    private menus = {
        bidDueDate: null,
        proposalDueDate: null,
        prebidDate: null
    }
    private openScopeOfWorkUpload: boolean = false
    private openFilesUpload: boolean = false
    private proposalPickerDate: string = ''
    private isSaving: boolean = false
    private customers: CustomerAllData[] = []
    private projectTypes: ProjectType[] = projectTypeArray
    private contactList: Contact[] = []
    private resetContactValidation: Boolean = false
    private rules: object = validationRules
    private times: SelectItem<String>[] = times
    private timeZones: SelectItem<String>[] = timeZones

    get prebidDateISO (): StringOrNull {
        return this.form.prebidDate
            ? moment.tz(this.form.prebidDate + ' ' + this.form.prebidTime, this.form.prebidTimeZone || '').toISOString()
            : null
    }

    get bidDueDateISO (): StringOrNull {
        return this.form.bidDueDate
            ? moment.tz(this.form.bidDueDate + ' ' + this.form.bidDueTime, this.form.bidDueTimeZone || '').toISOString()
            : null
    }

    get minBidDueDate (): string {
        return DateTime.now().plusBusiness({ days: 1 }).toISODate()
    }

    get maxBidDueDate (): string {
        return this.form.proposalDueDate
            ? DateTime.utc(this.form.proposalDueDate)
                .minusBusiness({ days: 3 })
                .toISODate()
            : ''
    }

    get proposalDueDateISO (): StringOrNull {
        return this.form.proposalDueDate ? moment.utc(this.form.proposalDueDate).toISOString() : null
    }

    // TODO make this min date smart enough to prevent locking out the bid due date
    get minProposalDueDate (): string {
        this.proposalPickerDate = this.form.bidDueDate ? DateTime.fromISO(this.form.bidDueDate).toFormat('yyyy-MM') : ''
        return this.form.bidDueDate
            ? DateTime.fromISO(this.form.bidDueDate)
                .plusBusiness({ days: 3 })
                .toISODate()
            : ''
    }

    get showCustomerForm (): boolean {
        return !(this.form.customer && this.form.customer.id)
    }

    get contacts (): CustomerContact[] {
        if (!(this.form.customer && this.form.customer.id)) {
            return []
        }

        let customer: CustomerAllData = this.customers.find(v => v.id === this.form.customer!.id) || generateEmptyCustomer()

        return customer.contacts.map<CustomerContact>(v => ({
            ...v,
            customer: {
                id: customer.id,
                userId: customer.userId,
                name: customer.name
            }
        }))
    }

    get session (): SessionOrNull {
        return UserBus.session
    }

    get allowedUsers (): Boolean {
        const role = UserBus!.session!.role!
        return role === 'DBS_ADMIN' || role === 'DBS_PROJECT_MANAGEMENT' || role === 'DBS_PROPOSAL_TEAM'
    }

    allowedDueDates (val: string): boolean {
        let day = moment(val).day()

        return day > 0 && day < 6
    }

    uploadScopeofWork (res: filestack.PickerResponse) {
        if (res.filesUploaded.length) {
            this.scopeOfWorkFile = res.filesUploaded[0]
        }
        this.openScopeOfWorkUpload = false
    }

    uploadSupportingFiles (res: filestack.PickerResponse) {
        if (res.filesUploaded.length) {
            this.files = res.filesUploaded
        }
        this.openFilesUpload = false
    }

    onCustomerSelect (value: CustomerAllData) {
        this.form.customer = value
        this.form.customerContact = generateEmptyCustomerContact(this.form.customer)
    }

    onCustomerClear () {
        this.form.customer = generateEmptyCustomer()
    }

    onContactSelect (value: CustomerContact) {
        this.form.customerContact = value !== undefined ? value : generateEmptyCustomerContact(this.form.customer)
        this.resetContactValidation = !this.resetContactValidation
    }

    async onSave () {
        if (!(this.$refs.projectForm as VForm).validate()) {
            SnackBus.showMessage(defaultError422, 'error')
            return
        }

        // TODO validate file size
        // // validate file size
        // for (let i = 0, length = this.form.files.length; i < length; i++) {
        //     const file = this.form.files[i]
        //     // if file is new & exceeds 20MB show error
        //     if (file.size > MAX_FILE_SIZE) {
        //         SnackBus.showMessage(file.name + ' exceeds the 20MB limit', 'error')
        //         return
        //     }
        // }

        this.isSaving = true
        let projectId: string

        // is this a new customer?
        if (this.form.customer && !this.form.customer.id) {
            try {
                const data = (await createCustomerMutation(this, {
                    variables: {
                        customerInput: { name: this.form.customer.name! } // Safe to ! here because field is required for submission
                    }
                })).data
                const newCustomer = data ? data.createCustomer : undefined
                if (!newCustomer || !newCustomer.id) {
                    throw Error('Project Center Customer returned null')
                }
                this.form.customer.id = newCustomer.id
            } catch (error) {
                SnackBus.showMessage(requestErrorMessage, 'error')
                this.isSaving = false
                return
            }
        }

        // set customer on new contact
        if (this.form.customerContact && !this.form.customerContact.id) {
            try {
                verifyAddressIsPresent(this.form.customerContact)
                const newAddressReturn = (await createProjectCenterAddressMutation(this, {
                    variables: {
                        address: mapProjectCenterAddressToCreateProjectCenterAddressInput(this.form.customerContact!.address!)
                    }
                })).data
                const newAddress = newAddressReturn ? newAddressReturn.createProjectCenterAddress : undefined
                verifyAddressReturned(newAddress)
                const newContactReturn = (await createContactMutation(this, {
                    variables: {
                        input: {
                            ...mapCustomerContactToContactInput(this.form.customerContact, newAddress!.id!)
                        }
                    }
                })).data
                const newContact = newContactReturn ? newContactReturn.createContact as Contact : null

                verifyContactReturned(newContact)
                this.form.customerContact = { ...newContact, customer: this.form.customer } as CustomerContact
            } catch (e) {
                SnackBus.showMessage(requestErrorMessage, 'error')
                this.isSaving = false
                return
            }
        }

        let formData: ProjectForm = {
            ...this.form,
            prebidDate: (this.isIncludingPrebid) ? this.prebidDateISO : null,
            prebidTime: (this.isIncludingPrebid) ? this.form.prebidTime : null,
            prebidTimeZone: (this.isIncludingPrebid) ? this.form.prebidTimeZone : null,
            prebidAddress: (this.isIncludingPrebid) ? this.form.prebidAddress : null,
            prebidNote: (this.isIncludingPrebid) ? this.form.prebidNote : null,
            bidDueDate: this.bidDueDateISO,
            proposalDueDate: this.proposalDueDateISO,
            bidManagementType: 'DBS_MANAGED',
            customerContactId: (this.form.customerContact) ? this.form.customerContact.id! : null
        }

        axios.post<PostProjectResponse>('/api/projects', formData)
            .then(async res => {
                projectId = res.data.data.id
                let now = Date.now()

                if (this.files.length) {
                    return axios.post<PostUploadsToProjectResponse>('/api/projects/' + projectId + '/filestack/upload',
                        this.files.map(v => ({
                            fileName: v.filename,
                            handle: v.handle,
                            uri: v.url,
                            createdAt: now
                        }))
                    )
                }

                return res
            })
            .then(res => {
                if (this.isUploadingScopeOfWork && this.scopeOfWorkFile) {
                    return axios.post('/api/projects/' + projectId + '/scopeOfWork', {
                        fileName: this.scopeOfWorkFile.filename,
                        handle: this.scopeOfWorkFile.handle,
                        uri: this.scopeOfWorkFile.url
                    })
                }

                return res
            })
            .then(_ => {
                SnackBus.showMessage('Project created', 'success')
                this.$router.push({ name: 'readProject', params: { id: projectId.toString() } })
            })
            // TODO - find a way to catch errors individually to throw custom error
            .catch(err => {
                console.log('Create Project Error', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.isSaving = false
            })
    }

    onDeleteFile (file: filestack.PickerFileMetadata, index: number) {
        // remove selected file
        const newFiles = []
        for (let fileIndex = 0; fileIndex < this.files.length; fileIndex++) {
            if (index !== fileIndex) {
                newFiles.push(this.files[fileIndex])
            }
        }
        this.files = newFiles
    }
}
