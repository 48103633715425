import { render, staticRenderFns } from "./UserCardUnassign.vue?vue&type=template&id=9daea0a4&"
import script from "./UserCardUnassign.vue?vue&type=script&lang=ts&"
export * from "./UserCardUnassign.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!/codebuild/output/src2994353656/src/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VHover } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
installComponents(component, {VAvatar,VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VFlex,VHover,VIcon,VLayout,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2994353656/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('9daea0a4', component.options)
    } else {
      api.reload('9daea0a4', component.options)
    }
    module.hot.accept("./UserCardUnassign.vue?vue&type=template&id=9daea0a4&", function () {
      api.rerender('9daea0a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Projects/UserCardUnassign.vue"
export default component.exports