import Vue from 'vue'
import moment from 'moment-timezone'
import { statuses } from './constants/statuses'
import { projectTypes } from './constants/projectTypes'
import { StringOrNull, AddressOrNull, CustomerOrNull, Session, CustomerContactOrNull } from '@/types'
import UserBus from './buses/UserBus'

/**
 * filter for moment formatting
 */
Vue.filter('formatDate', (value: string, format: string = 'L'): string => {
    return value ? moment(value).format(format) : ''
})

Vue.filter('formatLocalDate', (value: string, format: string = 'L', tz: string = ''): string => {
    const safeTz = tz || moment.tz.guess()

    return value ? moment(value).tz(safeTz).format(format) : ''
})

// TODO figure out better way to deal with due dates
Vue.filter('formatUtcDate', (value: string, format: string = 'L'): string => {
    return value ? moment.utc(value).format(format) : ''
})

/**
 * filter for relative date formatting
 */
Vue.filter('formatCalendarDate', (value: string): string => {
    return value ? moment(value).calendar() : ''
})

/**
 * format number to byte abbreviation
 */
Vue.filter('bytesFormat', function (value: number): string {
    if (value === null || value === undefined) return ''
    const length = value.toString().length
    if (length <= 3) {
        return (Math.round(value * 10) / 10).toString() + ' B'
    } else if (length <= 6) {
        return (Math.round(value / 1000 * 10) / 10).toString() + ' KB'
    } else if (length <= 9) {
        return (Math.round(value / 1000000 * 10) / 10).toString() + ' MB'
    } else {
        return (Math.round(value / 1000000000 * 10) / 10).toString() + ' GB'
    }
})

/**
 * get status text based on status value
 */
Vue.filter('getStatusText', function (status: string): string {
    if (statuses[status]) {
        return statuses[status].text.toUpperCase()
    } else {
        return status.toUpperCase()
    }
})

/**
 * get project type text based on type value
 */
Vue.filter('getProjectTypeText', function (type: string): string {
    if (projectTypes[type]) {
        return projectTypes[type].text.toUpperCase()
    } else {
        return type.toUpperCase()
    }
})

/**
 * format address
 */
Vue.filter('getAddressString', function (address: AddressOrNull): string {
    if (address == null) {
        return ''
    }

    let lines: StringOrNull[] = [address.line1, address.line2]
    let cityState: StringOrNull[] = [address.city, address.state]

    lines.push(cityState.filter(v => v !== null).join(', ') + (address.zip ? ' ' + address.zip : ''))

    return lines.filter(Boolean).join('\n')
})

/**
 * format customer contact info
 */
Vue.filter('getCustomerContactString', function (contact: CustomerContactOrNull): string {
    if (contact == null) {
        return ''
    }

    let phoneNumber = contact.phone
        ? contact.phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        : null
    let lines: StringOrNull[] = [contact.name, contact.title, contact.companyName, phoneNumber, contact.email]

    return lines.filter(Boolean).join('\n')
})

/**
 * format restricted customer contact info
 */
Vue.filter('getRestrictedCustomerContactString', function (contact: CustomerContactOrNull): string {
    if (contact == null) {
        return ''
    }

    let phoneNumber = contact.phone
        ? contact.phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        : null
    let lines: StringOrNull[] = [contact.name, contact.title]

    return lines.filter(v => v !== null).join('\n')
})

/**
 * format USD
 */
Vue.filter('formatCurrency', function (value: number): string {
    if (!value) {
        return ''
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    })
    return formatter.format(value)
})

/**
 * get avatar color for session
 */
Vue.filter('getAvatarColor', function (session: Session): string {
    return UserBus.avatarColor(session)
})

Vue.filter('getAvatarInitials', function (session: Session): string {
    return UserBus.avatarInitials(session)
})
