







































































































































































































































































































































































































































































































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import {
    StringOrNull,
    VForm,
    PostProjectResponse,
    AddressOrNull,
    CustomerOrNull,
    CustomerContactOrNull,
    emptyAddress,
    GetCustomersResponse,
    ProjectAllData,
    Address,
    GetSingleProjectResponse,
    generateEmptyCustomer,
    generateEmptyCustomerContact,
    CustomerContact,
    CustomerAllData,
    NumberOrNull
} from '@/types'
import SnackBus from '../../utils/buses/SnackBus'
import { defaultError422, requestErrorMessage } from '@/utils/constants/errors'
import moment from 'moment-timezone'
import FileUpload from '../../components/Files/FileUpload.vue'
import AddressForm from '../../components/AddressForm.vue'
import CustomerForm from '../../components/CustomerForm.vue'
import ContactForm from '../../components/Contacts/ContactForm.vue'
import ConfirmationDialog from '../../components/ConfirmationDialog.vue'
import { Status, statusesArray } from '@/utils/constants/statuses'
import UserBus from '../../utils/buses/UserBus'
import * as filestack from 'filestack-js'
import {
    getTimes,
    getTimeZones,
    SelectItem,
    verifyAddressIsPresent,
    verifyAddressReturned,
    verifyContactReturned
} from '@/utils/helpers'
import { validationRules } from '@/utils/rules'
import { ProjectType, projectTypeArray } from '@/utils/constants/projectTypes'
import { DateTime } from 'luxon-business-days'
import {
    Contact,
    createContactMutation, createCustomerMutation,
    createProjectCenterAddressMutation,
    useContactsQuery,
    useGetCustomersQuery
} from '@/graphql/graphql'
import {
    mapCustomerContactToContactInput,
    mapProjectCenterAddressToCreateProjectCenterAddressInput
} from '@/utils/contactConversions'

const times = getTimes()
const timeZones = getTimeZones()

interface ProjectForm {
    status: StringOrNull,
    type: StringOrNull,
    name: StringOrNull,
    projectNumber: StringOrNull,
    customer: CustomerOrNull,
    customerContact: CustomerContactOrNull,
    customerContactId: string | null,
    siteName: StringOrNull,
    siteAddress: Address,
    scopeOfWork: StringOrNull,
    prebidDate: StringOrNull,
    prebidTime: StringOrNull,
    prebidTimeZone: StringOrNull,
    prebidAddress: AddressOrNull,
    prebidNote: StringOrNull,
    bidDueDate: StringOrNull,
    bidDueTime: StringOrNull,
    bidDueTimeZone: StringOrNull,
    proposalDueDate: StringOrNull,
    specialInstructions: StringOrNull,
    estimatedBudget: NumberOrNull,
    torchRequired: boolean,
    bidManagementType: string,
}

interface ProjectFile {
    createdAt: Date
    filename: string
    id: string
    path: string
}

@Component({
    components: {
        FileUpload,
        AddressForm,
        CustomerForm,
        ContactForm,
        ConfirmationDialog
    },
    apollo: {
    /* eslint-disable no-use-before-define */
        contactList: useContactsQuery<EditProject>({
            variables: {},
            fetchPolicy: 'no-cache',
            update (data: any) {
                return data.contacts
            }
        }),
        customers: useGetCustomersQuery<EditProject>({
            variables: {},
            fetchPolicy: 'no-cache',
            update (data: any) {
                return data.customers
            }
        })
    /* eslint-enable no-use-before-define */
    }
})
export default class EditProject extends Vue {
    private project: ProjectAllData | null = null;
    private form: ProjectForm = {
        status: null,
        type: null,
        name: null,
        projectNumber: null,
        customer: null,
        customerContact: null,
        customerContactId: null,
        siteName: null,
        siteAddress: { ...emptyAddress },
        scopeOfWork: null,
        bidDueDate: null,
        bidDueTime: times[0].value,
        bidDueTimeZone: timeZones[0].value,
        prebidDate: null,
        prebidTime: times[0].value,
        prebidTimeZone: timeZones[0].value,
        prebidAddress: { ...emptyAddress },
        prebidNote: null,
        proposalDueDate: null,
        specialInstructions: null,
        estimatedBudget: null,
        torchRequired: false,
        bidManagementType: 'DBS_MANAGED'
    }
    private scopeOfWorkFileUpload: filestack.PickerFileMetadata | null = null
    private scopeOfWorkFile: ProjectFile | null = null
    private openScopeOfWorkUpload: boolean = false
    private deleteScopeOfWorkConfirmDialog = false
    private isUploadingScopeOfWork: boolean = false
    private isIncludingPrebid: boolean = false
    private menus = {
        prebidDate: null,
        bidDueDate: null,
        proposalDueDate: null
    }
    private showConfirmArchiveDialog: boolean = false
    private proposalPickerDate: string = ''
    private isLoading: boolean = false
    private error: StringOrNull = null
    private isSaving: boolean = false
    private rules: object = validationRules
    private statuses: Status[] = statusesArray
    private projectTypes: ProjectType[] = projectTypeArray
    private contactList: Contact[] = []
    private customers: CustomerAllData[] = []
    private customersLoading: boolean = false

    private times: SelectItem<String>[] = times
    private timeZones: SelectItem<String>[] = timeZones

    get prebidDateISO (): StringOrNull {
        return this.form.prebidDate
            ? moment.tz(this.form.prebidDate + ' ' + this.form.prebidTime, this.form.prebidTimeZone || '').toISOString()
            : null
    }

    get bidDueDateISO (): StringOrNull {
        return this.form.bidDueDate
            ? moment.tz(this.form.bidDueDate + ' ' + this.form.bidDueTime, this.form.bidDueTimeZone || '').toISOString()
            : null
    }

    get minBidDueDate (): string {
        return DateTime.now().plusBusiness({ days: 1 }).toISODate()
    }

    get maxBidDueDate (): string {
        return this.form.proposalDueDate
            ? DateTime.utc(this.form.proposalDueDate)
                .minusBusiness({ days: 3 })
                .toISODate()
            : ''
    }

    get proposalDueDateISO (): StringOrNull {
        return this.form.proposalDueDate ? moment.utc(this.form.proposalDueDate).toISOString() : null
    }

    get minProposalDueDate (): string {
        this.proposalPickerDate = this.form.bidDueDate ? DateTime.fromISO(this.form.bidDueDate).toFormat('yyyy-MM') : ''
        return this.form.bidDueDate
            ? DateTime.fromISO(this.form.bidDueDate)
                .plusBusiness({ days: 3 })
                .toISODate()
            : ''
    }

    get showCustomerForm (): boolean {
        return !(this.form.customer && this.form.customer.id)
    }

    get allowedStatuses (): Status[] {
        const allowedUser = UserBus.userIs.admin()

        let newStatusArray = this.statuses.filter(i => {
            return allowedUser ? i.value !== 'DRAFT' : i.value !== 'DRAFT' && i.value !== 'ARCHIVED'
        })
        return newStatusArray
    }

    get allowedUsers (): Boolean {
        const role = UserBus!.session!.role!
        return role === 'DBS_ADMIN' || role === 'DBS_PROJECT_MANAGEMENT' || role === 'DBS_PROPOSAL_TEAM'
    }

    allowedDueDates (val: string): boolean {
        let day = moment(val).day()

        return day > 0 && day < 6
    }

    created () {
        this.readProject()
    }

    readProject () {
        this.isLoading = true

        axios.get<GetSingleProjectResponse>('/api/projects/' + this.$route.params.id)
            .then(res => {
                this.project = res.data.data

                const {
                    status,
                    type,
                    name,
                    projectNumber,
                    customer,
                    customerContact,
                    siteName,
                    siteAddress,
                    scopeOfWork,
                    prebidDate,
                    prebidTimeZone,
                    prebidAddress,
                    prebidNote,
                    bidDueDate,
                    bidDueTimeZone,
                    proposalDueDate,
                    specialInstructions,
                    estimatedBudget,
                    scopeOfWorkFile,
                    torchRequired
                } = this.project

                this.isIncludingPrebid = (prebidDate != null)
                this.isUploadingScopeOfWork = (this.project.scopeOfWorkFile != null)
                this.scopeOfWorkFile = this.project.scopeOfWorkFile

                this.form = {
                    status: status,
                    type: type,
                    name: name,
                    projectNumber: projectNumber,
                    customer: customer,
                    customerContact: customerContact || generateEmptyCustomerContact(customer),
                    customerContactId: customerContact ? customerContact.id! : null,
                    siteName: siteName,
                    siteAddress: siteAddress || { ...emptyAddress },
                    scopeOfWork: scopeOfWork,
                    prebidDate: prebidDate
                        ? moment.tz(prebidDate, prebidTimeZone || '').format('YYYY-MM-DD')
                        : null,
                    prebidTime: prebidDate
                        ? moment.tz(prebidDate, prebidTimeZone || '').format('HH:mm:ss')
                        : times[0].value,
                    prebidTimeZone: (prebidTimeZone != null) ? prebidTimeZone : timeZones[0].value,
                    prebidAddress: prebidAddress || { ...emptyAddress },
                    prebidNote: prebidNote,
                    bidDueDate: bidDueDate
                        ? moment.tz(bidDueDate, bidDueTimeZone || '').format('YYYY-MM-DD')
                        : null,
                    bidDueTime: bidDueDate
                        ? moment.tz(bidDueDate, bidDueTimeZone || '').format('HH:mm:ss')
                        : times[0].value,
                    bidDueTimeZone: (bidDueTimeZone != null) ? bidDueTimeZone : timeZones[0].value,
                    proposalDueDate: proposalDueDate ? moment.utc(proposalDueDate).format('YYYY-MM-DD') : null,
                    specialInstructions: specialInstructions,
                    estimatedBudget,
                    torchRequired,
                    bidManagementType: 'DBS_MANAGED'
                }
            })
            // .then(() => {
            //     this.readCustomers()
            // })
            .catch(err => {
                console.log('Read Project Error', err)
                this.error = requestErrorMessage
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    onCustomerSelect (value: CustomerAllData) {
        this.form.customer = value
        this.form.customerContact = null
    }

    onCustomerClear () {
        this.form.customer = generateEmptyCustomer()
    }

    onContactSelect (value: CustomerContact) {
        this.form.customerContact = value !== undefined ? value : generateEmptyCustomerContact(this.form.customer)
    }

    onStatusChange (value: string) {
        this.showConfirmArchiveDialog = value === 'ARCHIVED'
    }

    onRejectArchive () {
        this.form.status = this.project!.status!
        this.showConfirmArchiveDialog = false
    }

    readCustomers () {
        this.customersLoading = true

        axios.get<GetCustomersResponse>('/api/customers')
            .then(res => {
                this.customers = res.data.data
            })
            .catch(err => {
                console.log('Read Customers Error', err)
                this.error = requestErrorMessage
            })
            .finally(() => {
                this.customersLoading = false
            })
    }

    async onSave () {
        if (!(this.$refs.projectForm as VForm).validate()) {
            SnackBus.showMessage(defaultError422, 'error')
            return
        }

        this.isSaving = true
        const projectId: string = this.project!.id

        // is this a new customer?
        if (this.form.customer && !this.form.customer.id) {
            try {
                const data = (await createCustomerMutation(this, {
                    variables: {
                        customerInput: { name: this.form.customer.name! } // Safe to ! here because field is required for submission
                    }
                })).data
                const newCustomer = data ? data.createCustomer : undefined
                if (!newCustomer || !newCustomer.id) {
                    throw Error('Project Center Customer returned null')
                }
                this.form.customer.id = newCustomer.id
            } catch (error) {
                SnackBus.showMessage(requestErrorMessage, 'error')
                this.isSaving = false
                return
            }
        }

        // set customer on new contact
        if (this.form.customerContact && !this.form.customerContact.id) {
            try {
                verifyAddressIsPresent(this.form.customerContact)
                const newAddressReturn = (await createProjectCenterAddressMutation(this, {
                    variables: {
                        address: mapProjectCenterAddressToCreateProjectCenterAddressInput(this.form.customerContact!.address!)
                    }
                })).data
                const newAddress = newAddressReturn ? newAddressReturn.createProjectCenterAddress : undefined
                verifyAddressReturned(newAddress)
                const newContactReturn = (await createContactMutation(this, {
                    variables: {
                        input: {
                            ...mapCustomerContactToContactInput(this.form.customerContact, newAddress!.id!)
                        }
                    }
                })).data
                const newContact = newContactReturn ? newContactReturn.createContact as Contact : null

                verifyContactReturned(newContact)
                this.form.customerContact = { ...newContact, customer: this.form.customer } as CustomerContact
            } catch (e) {
                SnackBus.showMessage(requestErrorMessage, 'error')
                this.isSaving = false
                return
            }
        }

        let formData: ProjectForm = {
            ...this.form,
            prebidDate: (this.isIncludingPrebid) ? this.prebidDateISO : null,
            prebidTime: (this.isIncludingPrebid) ? this.form.prebidTime : null,
            prebidTimeZone: (this.isIncludingPrebid) ? this.form.prebidTimeZone : null,
            prebidAddress: (this.isIncludingPrebid) ? this.form.prebidAddress : null,
            prebidNote: (this.isIncludingPrebid) ? this.form.prebidNote : null,
            bidDueDate: this.bidDueDateISO,
            proposalDueDate: this.proposalDueDateISO,
            bidManagementType: 'DBS_MANAGED'
        }

        axios.post<PostProjectResponse>('/api/projects/' + projectId, formData)
            .then(async res => {
                if (this.isUploadingScopeOfWork && this.scopeOfWorkFileUpload) {
                    return axios.post('/api/projects/' + projectId + '/scopeOfWork', {
                        fileName: this.scopeOfWorkFileUpload.filename,
                        handle: this.scopeOfWorkFileUpload.handle,
                        uri: this.scopeOfWorkFileUpload.url
                    })
                } else if ((this.project!.scopeOfWorkFile != null) && (!this.scopeOfWorkFile || !this.isUploadingScopeOfWork)) {
                    // orig scope of work was deleted by user
                    return axios.delete('/api/projects/' + projectId + '/scopeOfWork')
                        .then(_ => {
                            SnackBus.showMessage('Scope of Work deleted successfully.', 'success')
                        })
                        .catch(err => {
                            console.log('Delete Scope of Work Error: ', err)
                            SnackBus.showMessage(requestErrorMessage, 'error')
                        })
                        .finally(() => {
                        })
                }

                return res
            })
            .then(_ => {
                SnackBus.showMessage('Project saved', 'success')
                this.$router.push({ name: 'readProject', params: { id: projectId.toString() } })
            })
            // TODO - find a way to catch errors individually to throw custom error
            .catch(err => {
                console.log('Save Project Error', err)
                SnackBus.showMessage(requestErrorMessage, 'error')
            })
            .finally(() => {
                this.isSaving = false
            })
    }

    uploadScopeofWork (res: filestack.PickerResponse) {
        if (res.filesUploaded.length) {
            this.scopeOfWorkFileUpload = res.filesUploaded[0]
            this.scopeOfWorkFile = {
                createdAt: new Date(),
                filename: this.scopeOfWorkFileUpload.filename,
                id: '0',
                path: this.scopeOfWorkFileUpload.url
            }
        }
        this.openScopeOfWorkUpload = false
    }

    onDeleteScopeOfWork () {
        this.scopeOfWorkFileUpload = null
        this.scopeOfWorkFile = null
    }
}
