var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "pa-3": "", "my-3": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "", "align-center": "" } },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      indeterminate: "",
                      color: "primary",
                      size: "50",
                      "data-test": "loading-gif"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : !_vm.loading && _vm.error
    ? _c(
        "div",
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "pa-3": "", "my-3": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "", "align-center": "" } },
                [
                  _c("v-icon", { attrs: { color: "primary", size: "50" } }, [
                    _vm._v(
                      "\n                fas fa-exclamation-triangle\n            "
                    )
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "headline mt-3 font-weight-light",
                      attrs: { "data-test": "error-text" }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.error) +
                          "\n            "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : !_vm.loading && _vm.info
    ? _c(
        "div",
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "pa-3": "", "my-3": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "", "align-center": "" } },
                [
                  _c("v-icon", { attrs: { color: "primary", size: "50" } }, [
                    _vm._v("\n                fas fa-info-circle\n            ")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "headline mt-3 font-weight-light",
                      attrs: { "data-test": "error-text" }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.info) +
                          "\n            "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c("div", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }