// note: this is not an actual vue-cli plugin. I felt this was the best place to have it though
import axios from 'axios'
import UserBus, { AUTH_HEADER_NAME } from '@/utils/buses/UserBus'
import { signInWithRedirectToCurrentRoute } from '@/utils/authHelpers'

axios.defaults.baseURL = process.env.VUE_APP_API_URL || '/'

// axios.defaults.headers.common['Content-Type'] = 'application/form-data'

axios.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response.status === 401) {
        signInWithRedirectToCurrentRoute()
    }
    // try to throw response of error, else throw error
    return Promise.reject(error.response || error)
})

// request interceptor to add access token to each request
axios.interceptors.request.use(config => {
    const accessToken = UserBus.token
    const jwtToken = UserBus.jwtToken

    if (jwtToken) {
        config.headers.Authorization = 'Bearer ' + jwtToken
    }

    if (accessToken !== null) {
        config.headers[AUTH_HEADER_NAME] = accessToken
    }

    // add cache control headers for IE11
    if (config.method && config.method.toUpperCase() === 'GET') {
        config.headers['Cache-Control'] = 'no-cache'
        config.headers['Pragma'] = 'no-cache'
    }

    return config
}, err => {
    return Promise.reject(err)
})
