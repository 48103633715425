var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: {
        top: "",
        "auto-height": "",
        timeout: _vm.timeout,
        color: _vm.color
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c("span", { attrs: { "data-test": "message" } }, [
        _vm._v(_vm._s(_vm.message))
      ]),
      _c(
        "v-btn",
        {
          attrs: { icon: "" },
          on: {
            click: function($event) {
              _vm.show = false
            }
          }
        },
        [
          _c("v-icon", { attrs: { size: "16" } }, [
            _vm._v("\n            fas fa-times\n        ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }