













































































































































import axios from 'axios'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {
    VForm,
    ProjectContactOrNull,
    ProjectContact,
    generateEmptyProjectContact,
    generateEmptyContact
} from '../../types'
import AddressForm from '../AddressForm.vue'
import SnackBus from '../../utils/buses/SnackBus'
import {
    Contact,
    createContactMutation,
    createProjectCenterAddressMutation,
    useContactsQuery,
    ProjectCenterAddress,
    updateContactMutation
} from '@/graphql/graphql'
import AddProject from '@/views/Projects/AddProject.vue'
import {
    mapContactToContactInput, mapContactToProjectContact, mapContactToUpdateContactInput,
    mapProjectCenterAddressToCreateProjectCenterAddressInput
} from '@/utils/contactConversions'
import { verifyAddressReturned, verifyContactReturned } from '@/utils/helpers'

interface FullContact extends Contact{
    fullContact: string
}

@Component({
    components: {
        AddressForm
    },
    apollo: {
        contacts: useContactsQuery<AddProject>({
            variables: {},
            fetchPolicy: 'no-cache'
        })
    }
})
export default class InviteProjectContact extends Vue {
    @Prop({
        default: null
    })
    contactToInvite!: Contact | null

    @Prop({
        default: false
    })
    isEditingContact!: Boolean

    @Prop({
        default: false
    })
    resetContactForm!: Boolean

    @Watch('contactToInvite', {
        immediate: true
    })
    setContact () {
        this.contact = { ...this.contactToInvite! }

        if (this.$refs.projectContactForm) {
            (this.$refs.projectContactForm as any).resetValidation()
        }

        this.isNewContact = !this.isEditingContact
    }

    get fullProjectContactList (): FullContact[] {
        let contactsMap = this.contactList!.map(i => {
            let fullContact!: string
            let contact = {
                ...i,
                fullContact: i.name! + i.title! + i.email!
            }
            return contact
        })
        return contactsMap
    }

    private contact: Contact | null = null
    private contactList: Contact[] = []
    private isNewContact: boolean = true
    private contactsLoading: boolean = false
    private isLoading: boolean = false
    private rules = {
        required: (value: any) => !!value || 'This field is required'
    }

    async created () {
        // pull in a list of the project contacts associated with this user
        try {
            this.contactsLoading = true
            this.contactList = (await this.$apollo.queries.contacts.refetch()).data.contacts
        } catch (err) {
            console.log('There was an error getting the list of contacts. ', err)
        } finally {
            this.contactsLoading = false
        }
    }

    onContactSelect (value: Contact | null) {
        this.contact = value !== null ? value : generateEmptyContact()
        this.isNewContact = !this.isNewContact
    }

    onCancel () {
        this.$emit('close-dialog')
        // this.contact = generateEmptyProjectContact()
    }

    async onSubmit () {
        const projectId = this.$route.params.id
        if (!(this.$refs.projectContactForm as VForm).validate()) {
            (this.$refs.projectContactForm as VForm).validate()
        } else {
            this.isLoading = true
            if (this.isNewContact && !this.isEditingContact) {
            // creating a new project contact and adding
                try {
                    const newAddressReturn = (await createProjectCenterAddressMutation(this, {
                        variables: {
                            address: mapProjectCenterAddressToCreateProjectCenterAddressInput(this.contact!.address!)
                        }
                    })).data
                    const newAddress = newAddressReturn ? newAddressReturn.createProjectCenterAddress : undefined
                    verifyAddressReturned(newAddress)
                    const newContactReturn = (await createContactMutation(this, {
                        variables: {
                            input: {
                                ...mapContactToContactInput(this.contact!, newAddress!.id!)
                            }
                        }
                    })).data
                    const newContact = newContactReturn ? newContactReturn.createContact as Contact : null
                    verifyContactReturned(newContact)

                    await axios.post(`/api/projects/${projectId}/contacts`, [mapContactToProjectContact(newContact!)])
                    this.isNewContact = false
                    SnackBus.showMessage('The contact has successfully been created and added to this project.', 'success')
                    this.$emit('close-dialog')
                    this.$emit('reload-data')
                } catch {
                    SnackBus.showMessage('There was an error adding the contact.', 'error')
                    this.isNewContact = false
                    this.$emit('close-dialog')
                }
            } else if (this.isEditingContact) {
            // editing an existing project contact already associated with the project
                try {
                    const updatedContactResult = (await updateContactMutation(this, {
                        variables: {
                            contactId: this.contact!.id,
                            contactInput: {
                                ...mapContactToUpdateContactInput(this.contact!)
                            }
                        }
                    })).data
                    const updatedContact = updatedContactResult ? updatedContactResult.updateContact as Contact : null
                    verifyContactReturned(updatedContact)
                    SnackBus.showMessage('The contact has successfully been edited.', 'success')
                    this.$emit('close-dialog')
                    this.$emit('reload-data')
                } catch {
                    SnackBus.showMessage('There was an error editing the contact.', 'error')
                    this.$emit('close-dialog')
                }
            } else {
            // adding a new project contact from a list of contacts available
                try {
                    // add the contact to the project
                    await axios.post(`/api/projects/${projectId}/contacts`, [this.contact])

                    // update the contact if necessary
                    const updatedContactResult = (await updateContactMutation(this, {
                        variables: {
                            contactId: this.contact!.id,
                            contactInput: {
                                ...mapContactToUpdateContactInput(this.contact!)
                            }
                        }
                    })).data
                    const updatedContact = updatedContactResult ? updatedContactResult.updateContact as Contact : null
                    verifyContactReturned(updatedContact)

                    SnackBus.showMessage('The contact has successfully been added to this project.', 'success')
                    this.$emit('close-dialog')
                    this.$emit('reload-data')
                } catch {
                    SnackBus.showMessage('There was an error adding the contact.', 'error')
                    this.$emit('close-dialog')
                }
            }
            this.isLoading = false
        }
    }
}
