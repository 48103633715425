var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { bottom: "", left: "", "auto-height": "", timeout: 0 },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c("span", [_vm._v("This page has been updated.")]),
      _c(
        "v-btn",
        {
          attrs: { color: "secondary", flat: "" },
          on: { click: _vm.onReload }
        },
        [_vm._v("\n        Reload\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }