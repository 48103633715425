import { signInWithRedirectToCurrentRoute } from '@/utils/authHelpers'

// This function is executed when there is an error with a graphql request.
// The value of `allErrors` is bubbled to the execution point as `result.errors`
export function handleApolloError (error: any) {
    // Most of the token refreshes happen in: silent refresh and token expiring. In the event both of these have failed and the user
    // attempts to call GQL, Apollo returns an UNAUTHENTICATED error instead of a 401 / 403, so we need to have the user sign in again.
    if (error && error.networkError && error.networkError.result && error.networkError.result.errors) {
        error.networkError.result.errors.forEach((err: any) => {
            console.error(err)
        })

        const isUnauthenticated = error.networkError.result.errors.filter((err: any) => err.extensions.code === 'UNAUTHENTICATED').length > 0
        if (isUnauthenticated) {
            signInWithRedirectToCurrentRoute()
        };
    }

    const arr = [...error.graphQLErrors]
    if (error.networkError) arr.push(error.networkError)

    return { allErrors: arr }
}
