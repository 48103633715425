var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-chip",
                _vm._g({ class: { "hide-name": _vm.hideName } }, on),
                [
                  _c(
                    "v-avatar",
                    { attrs: { color: _vm._f("getAvatarColor")(_vm.session) } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("getAvatarInitials")(_vm.session)) +
                          "\n            "
                      )
                    ]
                  ),
                  !_vm.hideName
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.session && _vm.session.userName))
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [_c("span", [_vm._v(_vm._s(_vm.session.userName))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }