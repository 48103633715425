var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          class: { "toolbar-hide-navigation": _vm.hideNavigation },
          attrs: { color: "primary", dark: "" }
        },
        [
          !_vm.hideNavigation
            ? _c("v-toolbar-side-icon", {
                on: {
                  click: function($event) {
                    _vm.showNav = true
                  }
                }
              })
            : _vm._e(),
          _c("v-spacer"),
          !_vm.hideNavigation
            ? [
                _c(
                  "v-avatar",
                  {
                    staticClass: "black--text mr-2",
                    attrs: {
                      size: "36",
                      color: _vm._f("getAvatarColor")(_vm.session)
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("getAvatarInitials")(_vm.session)) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(
                  "\n            " + _vm._s(_vm.userName) + "\n            "
                ),
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "", right: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g({ attrs: { icon: "" } }, on),
                                [
                                  _c("v-icon", { attrs: { size: "20" } }, [
                                    _vm._v(
                                      "\n                            fas fa-ellipsis-v\n                        "
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4157141553
                    )
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-tile",
                          { on: { click: _vm.onLogout } },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v(
                                "\n                            fas fa-sign-out-alt\n                        "
                              )
                            ]),
                            _c("v-list-tile-title", [_vm._v("Logout")])
                          ],
                          1
                        ),
                        _vm.canEmulate
                          ? _c(
                              "v-list-tile",
                              { attrs: { to: { name: "emulate" } } },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v(
                                    "\n                            fa-user\n                        "
                                  )
                                ]),
                                _c("v-list-tile-title", [_vm._v("Emulate")])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.canSayHello
                          ? _c(
                              "v-list-tile",
                              { on: { click: _vm.greeting } },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v(
                                    "\n                            fa-bullhorn\n                        "
                                  )
                                ]),
                                _c("v-list-tile-title", [_vm._v("Say Hello")])
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { fixed: "", temporary: "" },
          model: {
            value: _vm.showNav,
            callback: function($$v) {
              _vm.showNav = $$v
            },
            expression: "showNav"
          }
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.navItems, function(item) {
              return _c(
                "v-list-tile",
                { key: item.text, attrs: { to: item.route, exact: "" } },
                [
                  _c(
                    "v-list-tile-action",
                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                    1
                  ),
                  _c("v-list-tile-title", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.text) +
                        "\n                "
                    )
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }