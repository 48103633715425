



























import { Component, Vue, Prop } from 'vue-property-decorator'
import { NumberOrNull } from '../../types'
import { defaultError422, requestErrorMessage } from '../../utils/constants/errors'
import SnackBus from '../../utils/buses/SnackBus'
import * as filestack from 'filestack-js'

@Component
export default class FileUpload extends Vue {
    @Prop({
        default: 'Upload File'
    })
    title!: string

    @Prop({
        default: 'Please upload the necessary file(s).'
    })
    description!: string

    @Prop({
        default: 10,
        required: false
    })
    maxFiles!: number

    @Prop({
        default: '',
        required: false
    })
    rootId!: string

    private fileSelected: boolean = false

    mounted () {
        this.showFilestack()
    }

    async showFilestack () {
        const filestackOptions: filestack.PickerOptions = {
            rootId: this.rootId,
            maxFiles: this.maxFiles,
            fromSources: [
                'local_file_system',
                'googledrive'
            ],
            storeTo: {
                location: 's3',
                path: process.env.VUE_APP_FILESTACK_BUCKET_PATH
            },
            onUploadDone: this.onFilestackUploadDone,
            onFileSelected: this.onFileSelected,
            displayMode: filestack.PickerDisplayMode.inline,
            container: '#filestackContainer',
            customText: {
                Upload: 'Upload',
                'Deselect All': 'Cancel'
            }
        }

        const filestackApiKey = process.env.VUE_APP_FILESTACK_API_TOKEN || ''

        const clientOptions = { sessionCache: true }
        const client = filestack.init(filestackApiKey, clientOptions)

        client.picker(filestackOptions).open()
    }

    onFilestackUploadDone (res: filestack.PickerResponse) {
        if (res.filesFailed.length === 0) {
            this.$emit('on-filestack-upload-done', res)
        } else {
            SnackBus.showMessage('File Upload Failed. Please try again.', 'error')
            this.$emit('close-dialog')
        }
    }

    onFileSelected (file: filestack.PickerFileMetadata) {
        this.fileSelected = true
    }
}
