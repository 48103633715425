import 'babel-polyfill'
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import './plugins/vuetify'
import './plugins/axios'
import './main.scss'
import App from './App.vue'
import router from './router'
import './utils/filters'
import PageContent from './components/PageContent.vue'
import KeyValueTable from './components/KeyValueTable.vue'
import { mainOidc } from '@/oidc'
import ApolloClient from 'apollo-client'
import { ApolloLink, concat } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import UserBus from '@/utils/buses/UserBus'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import Router from 'vue-router'

Vue.config.productionTip = false

Vue.component('page-content', PageContent)
Vue.component('key-value-table', KeyValueTable)

Vue.use(VueApollo)
Vue.use(Router)
Vue.use(VueAnalytics, {
    id: 'UA-40961109-17',
    router
})

const httpLink = new HttpLink({ uri: process.env.VUE_APP_SUPERGRAPH_URL })

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const jwtToken = UserBus.jwtToken
    operation.setContext({
        headers: {
            'X-GauthToken': UserBus.token,
            authorization: jwtToken ? `Bearer ${jwtToken}` : ''
        }
    })
    return forward(operation)
})
export const apolloClient = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache()
})
const apolloProvider = new VueApollo({
    defaultClient: apolloClient
})

mainOidc.startup().then(ok => {
    if (ok) {
        new Vue({
            router,
            apolloProvider,
            render: h => h(App)
        }).$mount('#app')
    }
})

mainOidc.useRouter(router)
