import { render, staticRenderFns } from "./ReloadSnackMessage.vue?vue&type=template&id=040b1d42&"
import script from "./ReloadSnackMessage.vue?vue&type=script&lang=ts&"
export * from "./ReloadSnackMessage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!/codebuild/output/src2994353656/src/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VSnackbar } from 'vuetify/lib'
installComponents(component, {VBtn,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2994353656/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('040b1d42', component.options)
    } else {
      api.reload('040b1d42', component.options)
    }
    module.hot.accept("./ReloadSnackMessage.vue?vue&type=template&id=040b1d42&", function () {
      api.rerender('040b1d42', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ReloadSnackMessage.vue"
export default component.exports