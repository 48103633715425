import {
    Contact,
    CreateContactInput,
    CreateProjectCenterAddressInput,
    ProjectCenterAddress
} from '@/graphql/graphql'
import { CustomerContact, generateEmptyUser, ProjectContact } from '@/types/contact'
import { Address } from '@/types/address'
import { ProjectContactOrNull } from '@/types'

export const mapContactToContactInput = (contact: Contact, addressId: string | null = null): CreateContactInput => {
    let address = ''
    if (contact.address && contact.address.id) {
        address = contact.address.id.toString()
    } else if (addressId) {
        address = addressId
    }
    return {
        addressId: address,
        companyName: contact.companyName,
        email: contact.email != null ? contact.email : '',
        name: contact.name != null ? contact.name : '',
        phone: contact.phone,
        title: contact.title,
        customerIds: contact.customers ? contact.customers.map((customer) => customer.id) : []
    }
}

// TODO: Ideally this wouldn't be needed if we switched CustomerContact to Contact from graph
export const mapCustomerContactToContactInput = (contact: CustomerContact, addressId: string | null = null): CreateContactInput => {
    let address = ''
    if (contact.address && contact.address.id) {
        address = contact.address.id.toString()
    } else if (addressId) {
        address = addressId
    }
    return {
        addressId: address,
        companyName: contact.companyName,
        email: contact.email != null ? contact.email : '',
        name: contact.name != null ? contact.name : '',
        phone: contact.phone,
        title: contact.title,
        customerIds: []
    }
}

export const mapContactToUpdateContactInput = (contact: Contact, addressId: string | null = null): CreateContactInput => {
    let address = ''
    if (contact.address && contact.address.id) {
        address = contact.address.id.toString()
    } else if (addressId) {
        address = addressId
    }
    return {
        addressId: address,
        companyName: contact.companyName,
        email: contact.email != null ? contact.email : '',
        name: contact.name != null ? contact.name : '',
        phone: contact.phone,
        title: contact.title
    }
}

export const mapProjectCenterAddressToAddress = (address: ProjectCenterAddress): Address | null => {
    return {
        id: address.id && address.id.valueOf() ? address.id.valueOf() : undefined,
        line1: address.line1 && address.line1.valueOf() ? address.line1.valueOf() : null,
        line2: address.line2 && address.line2.valueOf() ? address.line2.valueOf() : null,
        city: address.city && address.city.valueOf() ? address.city.valueOf() : null,
        state: address.state && address.state.valueOf() ? address.state.valueOf() : null,
        zip: address.zip && address.zip.valueOf() ? address.zip.valueOf() : null
    }
}

export const mapContactToProjectContact = (contact: Contact): ProjectContact => {
    return {
        id: contact.id,
        name: contact.name,
        title: contact.title && contact.title.valueOf() ? contact.title.valueOf() : null,
        companyName: contact.companyName && contact.companyName.valueOf() ? contact.companyName.valueOf() : null,
        phone: contact.phone && contact.phone.valueOf() ? contact.phone.valueOf() : null,
        email: contact.email && contact.email.valueOf() ? contact.email.valueOf() : null,
        address: contact.address && contact.address.valueOf()
            ? mapProjectCenterAddressToAddress(contact.address!.valueOf() as ProjectCenterAddress)
            : null
    }
}

export const mapProjectCenterAddressToCreateProjectCenterAddressInput = (address: ProjectCenterAddress | Address): CreateProjectCenterAddressInput => {
    return {
        line1: address.line1 != null ? address.line1 : '',
        line2: address.line2,
        state: address.state != null ? address.state : '',
        zip: address.zip != null ? address.zip : '',
        country: 'CA',
        city: address.city != null ? address.city : ''
    }
}

export const mapAddressToProjectCenterAddress = (address: Address): ProjectCenterAddress => {
    return {
        ...address,
        id: address.id ? address.id : ''
    }
}

export const mapProjectContactToContact = (contact: ProjectContact): Contact => {
    return {
        ...contact,
        id: contact.id ? contact.id : '',
        createdBy: generateEmptyUser(),
        customers: [],
        name: contact.name ? contact.name : '',
        sharedLinks: [],
        address: contact.address ? mapAddressToProjectCenterAddress(contact.address) : null
    }
}

export const mapProjectContactOrNullArrayToContactArray = (contacts: ProjectContactOrNull[]): Contact[] => {
    const nonNullContacts = contacts.filter(contact => contact != null)
    return nonNullContacts
        .map((contact) => mapProjectContactToContact(contact as ProjectContact))
}
