var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-icon",
        { staticClass: "mb-3", attrs: { color: "primary", size: "72" } },
        [_vm._v("\n        fas fa-exclamation-triangle\n    ")]
      ),
      _c("h1", { staticClass: "display-1" }, [
        _vm._v("\n        Page Not Found\n    ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }