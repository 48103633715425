export interface Session {
    id?: number;
    userName: string;
    role: string;
    email: string;
}

export const generateEmptySession = (): Session => ({
    userName: '',
    role: 'NOTHING',
    email: ''
})
