









































import { Component, Vue } from 'vue-property-decorator'
import UserBus, { ROLE_DBS_ADMIN, ROLE_DBS_PROJECT_MANAGEMENT, ROLE_DBS_PROPOSAL_TEAM, ROLE_PROJ_CENTER_ADMIN, ROLE_SALES_REP } from '../utils/buses/UserBus'

const roles = [
    {
        name: 'DBS Admin',
        role: ROLE_DBS_ADMIN
    },
    {
        name: 'DBS Project Management',
        role: ROLE_DBS_PROJECT_MANAGEMENT
    },
    {
        name: 'DBS Proposal Team',
        role: ROLE_DBS_PROPOSAL_TEAM
    },
    {
        name: 'Sales Rep',
        role: ROLE_SALES_REP
    },
    {
        name: 'Project Center Admin',
        role: ROLE_PROJ_CENTER_ADMIN
    }
]

@Component
export default class Emulate extends Vue {
    private roles: {
        name: string;
        role: string;
    }[] = roles

    get currentRole () {
        return UserBus.role
    }

    setRole (role: string) {
        UserBus.emulateRole(role)
        // must reload page for cookie update to take effect
        window.location.reload()
    }
}
