var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-3" },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "dbs-user-card-avatar",
                  attrs: { size: "48", color: _vm.iconBackground }
                },
                [
                  !_vm.session
                    ? _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.user.icon) +
                            "\n                "
                        )
                      ])
                    : [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("getAvatarInitials")(_vm.session)) +
                            "\n                "
                        )
                      ]
                ],
                2
              )
            ],
            1
          ),
          _c("v-flex", { staticClass: "text-truncate-alt" }, [
            _c("span", { staticClass: "grey--text text--darken-2 body-1" }, [
              _vm._v(_vm._s(_vm.user.title))
            ]),
            _c("br"),
            _c("span", { staticClass: "body-2" }, [
              _vm._v(_vm._s(_vm.session ? _vm.session.userName : "Unassigned"))
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }