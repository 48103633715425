





















































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Bid, generateEmptyBid, Contractor, generateEmptyContractor } from '../../types'

@Component
export default class BidDetails extends Vue {
    @Prop({
        default: () => generateEmptyBid()
    })
    bid!: Bid

    @Prop({
        default: () => generateEmptyContractor()
    })
    contractor!: Contractor

    @Prop({
        default: false
    })
    promotingBid!: boolean

    @Prop({
        default: false
    })
    bidAwarded!: boolean

    private promoteBidConfirmDialog: boolean = false
    private actionOnBid: string = ''

    canPromoteBid () {
        const bidAccepted = this.bid.acceptedAt !== null
        const bidInvalid = this.bid.invalidatedAt !== null
        if (this.bidAwarded || bidInvalid) {
            return false
        } else if (bidAccepted) {
            this.actionOnBid = 'Award'
            return true
        } else {
            this.actionOnBid = 'Accept'
            return true
        }
    }

    promoteBidDialog () {
        this.$emit('close-dialog')
        this.promoteBidConfirmDialog = true
    }

    cancelPromoteBidDialog () {
        this.$emit('open-dialog')
        this.promoteBidConfirmDialog = false
    }

    onPromoteBid () {
        this.$emit('on-promote-bid', this.actionOnBid)
        this.promoteBidConfirmDialog = false
    }
}
