
















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Comment } from '../../types'
import { statuses } from '../../utils/constants/statuses'
import TimelineItem from './../TimelineItem.vue'

interface PublicComment {
    id: string;
    type: string;
    title: string;
    body: string;
    createdAt: string;
}

@Component({
    components: {
        TimelineItem
    }
})
export default class AddendumProjectTimeline extends Vue {
    @Prop({
        default: () => ([])
    })
    readonly items!: PublicComment[]

    private showAllTimeline: boolean = false

    get timelineItems () {
        if (this.showAllTimeline === true) {
            return this.items.map(item => ({
                ...item,
                // determine if body text should be shown on load
                show: false
            }))
        } else {
            let fullTimeline = this.items.map(item => ({
                ...item,
                show: false
            }))
            let timelinePreview = fullTimeline.slice(0, 3)
            return timelinePreview
        }
    }
}
