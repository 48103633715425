








































































































































































































































































































































































































































import axios from 'axios'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Session, SessionOrNull, ProjectTeamList, StringOrNull, UserCardInput, UserCardInputOrNull, NumberOrNull, generateEmptySession, generateEmptyUserCardInput } from '../types'
import UserBus from '../utils/buses/UserBus'
import UserChip from '../components/UserChip.vue'

@Component({
    components: {
        UserChip
    }
})
export default class ProjectTeam extends Vue {
    @Prop({
        required: true
    })
    users!: ProjectTeamList

    private isLoading: Boolean = false
    private editLeadSalesRepDialog: Boolean = false
    private salesRepList: Session[] = []
    private selectedLeadSalesRep: SessionOrNull = null
    private editLeadSalesRepConfirmationDialog: Boolean = false
    private isEditingLeadSalesRep: Boolean = false

    private addSupportingSalesRepsDialog: Boolean = false
    private addSupportingSalesRepsConfirmationDialog: Boolean = false
    private selectedSupportingSalesReps: Session[] = []

    private unassignSupportingSalesRepDialog: Boolean = false
    private salesRepToUnassign: Session = generateEmptySession()
    private salesRepToUnassignIndex: NumberOrNull = null
    private isAddingSupportingSalesRep: Boolean = false

    private assignmentConfirmationDialog: Boolean = false
    private userToAssign: UserCardInput = generateEmptyUserCardInput()
    private isChangingProposalTeam: Boolean = false

    private unassignmentConfirmationDialog: Boolean = false
    private userToUnassign: UserCardInput = generateEmptyUserCardInput()
    private isChangingProjectManager: Boolean = false

    get salesIconBackground () {
        if (this.users.sales.session) {
            return UserBus.avatarColor(this.users.sales.session)
        } else {
            return this.users.sales.unassigned ? 'grey lighten-1' : 'primary'
        }
    }

    get proposalTeamIconBackground () {
        if (this.users.proposalTeam.session) {
            return UserBus.avatarColor(this.users.proposalTeam.session)
        } else {
            return this.users.proposalTeam.unassigned ? 'grey lighten-1' : 'primary'
        }
    }

    get projectManagerIconBackground () {
        if (this.users.projectManager.session) {
            return UserBus.avatarColor(this.users.projectManager.session)
        } else {
            return this.users.projectManager.unassigned ? 'grey lighten-1' : 'primary'
        }
    }

    get filteredSalesRepList () {
        let existingReps = this.users!.supportingSales!.session!.map(i => {
            return i!.userName!
        })
        return this.salesRepList!.filter(i => {
            return existingReps!.indexOf(i!.userName!) === -1
        })
    }

    readSalesRepList () {
        axios.get<{ data: Session[] }>('/api/users/salesReps')
            .then(res => {
                this.salesRepList = res.data.data
            })
            .catch(err => {
                this.isLoading = false
                console.log('Error retreiving a list of Sales Reps: ', err)
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    editSalesRep () {
        this.isLoading = true
        this.editLeadSalesRepDialog = true
        this.readSalesRepList()
    }

    cancelEditLeadSalesRep () {
        this.editLeadSalesRepDialog = false
        this.selectedLeadSalesRep = null
    }

    confirmEditLeadSalesRep () {
        this.editLeadSalesRepDialog = false
        this.isEditingLeadSalesRep = true
        this.$emit('on-edit-lead-sales-rep', this.selectedLeadSalesRep)
    }

    getAvatarColor (name: Session) {
        return UserBus.avatarColor(name)
    }

    assignSupportingSalesReps () {
        this.isLoading = true
        this.addSupportingSalesRepsDialog = true
        this.readSalesRepList()
    }

    cancelAssignSupportingSalesReps () {
        this.addSupportingSalesRepsDialog = false
        this.selectedSupportingSalesReps = []
    }

    confirmAssignSupportingSalesReps () {
        this.addSupportingSalesRepsDialog = false
        this.$emit('on-assign-supporting-sales-reps', this.selectedSupportingSalesReps)
        this.selectedSupportingSalesReps = []
    }

    unassignSupportingSalesRep (rep: Session, index: number) {
        this.unassignSupportingSalesRepDialog = true
        this.salesRepToUnassign = rep
        this.salesRepToUnassignIndex = index
    }

    onConfirmUnassignSupportingSalesRep () {
        this.unassignSupportingSalesRepDialog = false
        this.$emit('on-unassign-supporting-sales-rep', this.salesRepToUnassign, this.salesRepToUnassignIndex)
    }

    confirmAssign (user: UserCardInput) {
        this.assignmentConfirmationDialog = true
        this.userToAssign = user
    }

    cancelAssign () {
        this.assignmentConfirmationDialog = false
    }

    onAcceptAssignment () {
        this.$emit('on-accept-assignment', this.userToAssign)
        this.assignmentConfirmationDialog = false
    }

    confirmUnassign (user: UserCardInput) {
        this.unassignmentConfirmationDialog = true
        this.userToUnassign = user
    }

    cancelUnassign () {
        this.unassignmentConfirmationDialog = false
    }

    onAcceptUnassignment () {
        this.$emit('on-accept-unassignment', this.userToUnassign)
        this.unassignmentConfirmationDialog = false
    }
}
