import Vue from 'vue'

export default new Vue({
    methods: {
        /**
         * Emits a message to components that are listening
         *
         * @param {String} message
         * @param {String} color
         * @param {String} button
         */
        showMessage (message: string, color: string) {
            this.$emit('message-sent', message, color)
        }
    }
})
