import { NavigationGuard } from 'vue-router'

const guard: NavigationGuard = (to, from, next) => {
    if (process.env.NODE_ENV === 'development') {
        next()
    } else {
        next({ name: 'forbidden' })
    }
}

export default guard
