





















































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { NumberOrNull } from '../../types'
import { defaultError422, requestErrorMessage } from '../../utils/constants/errors'
import SnackBus from '../../utils/buses/SnackBus'
import ConfirmationDialog from '../../components/ConfirmationDialog.vue'
import * as filestack from 'filestack-js'
import FileUpload from './FileUpload.vue'

interface ProjectFile {
    createdAt: Date,
    filename: string,
    id: string,
    path: string,
    isDeleting: boolean
}

@Component({
    components: {
        FileUpload,
        ConfirmationDialog
    }
})
export default class Files extends Vue {
    @Prop({
        default: []
    })
    files!: ProjectFile[]

    private fileToDelete: ProjectFile | null = null
    private fileIndexToDelete: NumberOrNull = null
    private deleteFileConfirmDialog: boolean = false

    deleteFile (file: ProjectFile, index: number) {
        this.fileToDelete = file
        this.fileIndexToDelete = index
        this.deleteFileConfirmDialog = true
    }

    confirmDelete () {
        this.deleteFileConfirmDialog = false
        this.files[this.fileIndexToDelete!].isDeleting = true
        this.$emit('delete-file', this.fileToDelete!)
    }

    onAddFile () {
        this.$emit('add-file')
    }

    onDownloadAll () {
        const filestackApiKey = process.env.VUE_APP_FILESTACK_API_TOKEN || ''

        let fsHandles:string[] = []

        this.files.forEach(fileItem => {
            // get fs handle from file attach 'path'
            let pathArray = fileItem.path.split('/')
            fsHandles.push(pathArray[3])
        })
        if (fsHandles.length > 0) {
            // trigger popup to filestack zip operation
            let dlUrl = 'https://cdn.filestackcontent.com/' + filestackApiKey + '/zip/['
            let handleCount = 1
            fsHandles.forEach(function (handle) {
                dlUrl = dlUrl + handle
                if (handleCount < fsHandles.length) {
                    dlUrl = dlUrl + ','
                } else {
                    dlUrl = dlUrl + ']'
                }
                handleCount++
            })
            window.open(dlUrl, '_dlWin')
        }
    }
}
