





































































































































































































































































































































































































































































































































































































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import { StringOrNull, BidFile, ProjectFilesOrNull, GetInvitationResponse, Bid, BidInvitationOrNull, Addendum, AddendumOrNull, UploadOrNull } from '@/types'
import { requestErrorMessage } from '@/utils/constants/errors'
import SnackBus from '@/utils/buses/SnackBus'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import ReloadSnackMessage from '@/components/ReloadSnackMessage.vue'
import FileUpload from '@/components/Files/FileUpload.vue'
import AddendumProjectTimeline from '@/components/Addenda/AddendumProjectTimeline.vue'
import AddendaCard from '@/components/Addenda/AddendaCard.vue'
import DetailedListItem from '@/components/DetailedListItem.vue'
import * as filestack from 'filestack-js'
import Toolbar from '@/components/Toolbar.vue'
import { BidStatus, bidStatuses } from '@/utils/constants/bidStatuses'
import SocketBus, { JsonClient } from '@/utils/buses/SocketBus'

interface File {
    createdAt: Date,
    filename: string,
    id: string,
    path: string
}

interface ProjectFile extends File {
    isDeleting: boolean
}

@Component({
    components: {
        Toolbar,
        FileUpload,
        AddendumProjectTimeline,
        AddendaCard,
        DetailedListItem,
        ReloadSnackMessage,
        ConfirmationDialog
    },
    filters: {
        colorByBidStatus: (status: string) => {
            switch (status) {
            case 'SUBMITTED':
                return 'grey'
            case 'ACCEPTED':
                return 'success'
            case 'INVALID':
                return 'warning'
            }

            return ''
        }
    }
})
export default class ContractorReadProject extends Vue {
    private project: ProjectFilesOrNull = null
    private invitation: BidInvitationOrNull = null
    private bids: Bid[] = []
    private isLoading: boolean = false
    private isSubmitting: boolean = false
    private error: StringOrNull = null
    private info: StringOrNull = null
    private files: ProjectFile[] = []
    private validUser: boolean = false
    private bidExists: boolean = false
    private bidStatuses: {
        [key: string]: BidStatus
    } = bidStatuses
    private projectUpdateClient: JsonClient = new JsonClient()
    private reloadPage: Boolean = false

    // Opt Out of Bid Variables
    private optOutConfirmDialog: boolean = false
    private isOptingOut: boolean = false

    // Bid Submission Variables
    private openBidUploadDialog: boolean = false
    private openSubmitBidForm: boolean = false
    private bidSubmitting: boolean = false
    private bidFiles: BidFile[] = []
    private bidNotes: string = ''
    private invitationBidForm: UploadOrNull = null

    // Addendum Variables
    private isNewAddendum: boolean = false
    private openAddendumDetailsDialog: boolean = false
    private selectedAddendum: AddendumOrNull = null
    private addendumList: Addendum[] = []
    private showAllAddendums: boolean = false
    private bidValidating: boolean = false
    private validateBidConfirmDialog: boolean = false
    private validateFromBidCard: boolean = false

    private torchRequiredDialog: boolean = false

    get shownAddendumList () {
        if (this.showAllAddendums) {
            return this.addendumList
        } else {
            let truncatedAddendumList = this.addendumList!.slice(0, 3)
            return truncatedAddendumList
        }
    }

    get canValidate () {
        return this.bidExists! && this.bids[0]!.status === 'INVALID' && this.selectedAddendum!.version! === this.addendumList[0]!.version!
    }

    created () {
        this.isLoading = true

        this.loadPage()
    }

    loadPage () {
        this.readProject()
    }

    beforeDestroy () {
        this.projectUpdateClient.deactivate()
    }

    // There will be a BE way to do this but the FE stuff is wired up
    compareInstance (newInvitation: BidInvitationOrNull) {
        // Check the database every minute for changes
        window.setInterval(async () => {
            await axios.get<GetInvitationResponse>('/api/invitations/' + this.$route.params.hash)
                .then(res => {
                    const newData = JSON.stringify(res.data.data)
                    const currentData = JSON.stringify(this.invitation)

                    this.reloadPage = newData !== currentData
                })
        }, 6000)
    }

    onReloadPage () {
        console.debug('user reloaded page')

        this.reloadPage = false
        this.loadPage()
    }

    readProject () {
        axios.get<GetInvitationResponse>('/api/invitations/' + this.$route.params.hash)
            .then(res => {
                this.invitation = res.data.data
                this.project = res.data.data.project
                this.addendumList = res.data.data.project.addendums
                this.bids = res.data.data.bids
                this.validUser = true

                this.selectedAddendum = res.data.data.project.addendums.length > 0 ? res.data.data.project.addendums[0] : null
                this.bidExists = res.data.data.bids.length > 0
                if (res.data.data.project.addendums.length > 0) {
                    const initialUnread = res.data.meta.unread
                    this.isNewAddendum = initialUnread
                    // Open the Addendum Details Dialog if there is a new addendum that the user hasn't seen
                    this.openAddendumDetailsDialog = res.data.meta.unread === true
                }

                this.files = this.project.files.map(i => {
                    let file = {
                        filename: i.filename,
                        id: i.id,
                        path: i.path,
                        createdAt: i.createdAt,
                        isDeleting: false
                    }
                    return file
                })
                this.torchRequiredDialog = res.data.data.project.torchRequired && !res.data.data.hasAcknowledgedTorchRequired
            })
            .then(res => {
                axios.get('/api/invitations/' + this.$route.params.hash + '/bidForm')
                    .then(res => {
                        this.invitationBidForm = res.data.data
                    }).catch(err => {
                        console.error('failed getting bid form', err)
                    })
            })
            .catch(err => {
                console.log('Read Project Error', err)
                this.error = err.data.errors.hash === 'invitation expired' ? 'This invitation is invalid or is now beyond the original bid due date.' : requestErrorMessage
            })
            .finally(() => {
                this.isLoading = false

                if (this.project) {
                    this.projectUpdateClient = SocketBus.subscribeClient('/topic/projects/' + this.project.id, msg => {
                        let data = msg.json.data

                        console.debug('Project feed: %o', data)

                        // TODO don't respect notifications triggered by current page
                        if (data.updated) {
                            this.reloadPage = true
                        }
                    })
                }
            })
    }

    uploadBidForm (files: filestack.PickerResponse) {
        this.bidFiles = files.filesUploaded.map(i => {
            return {
                filename: i.filename,
                path: i.url
            }
        })
        this.openSubmitBidForm = true
    }

    onOptOut () {
        this.isOptingOut = true
        const hash = this.$route.params.hash

        axios.post('api/invitations/' + hash + '/decline')
            .then(res => {
                this.info = 'You have declined your invitation to bid on this project'
            })
            .catch(err => {
                console.log('Decline Invitation Error: ', err)
                SnackBus.showMessage('Unsuccessful in opting out of this bid invitation.', 'error')
            })
            .finally(() => {
                this.optOutConfirmDialog = false
                this.isOptingOut = false
            })
    }

    submitBid () {
        this.bidSubmitting = true
        const hash = this.$route.params.hash
        axios.post<any>('api/invitations/' + hash + '/bids',
            {
                files: this.bidFiles,
                notes: this.bidNotes
            }
        )
            .then(res => {
                SnackBus.showMessage('Thank you. Your bid has been submitted.', 'success')
                this.readProject()
            })
            .catch(err => {
                SnackBus.showMessage('Error submitting bid', 'failure')
                console.error('Failed to submit bid', err)
            })
            .finally(() => {
                this.bidFiles = []
                this.bidNotes = ''
                this.bidSubmitting = false
                this.openBidUploadDialog = false
                this.openSubmitBidForm = false
            })
    }

    cancelBidSubmit () {
        this.openBidUploadDialog = false
        this.openSubmitBidForm = false
        this.bidFiles = []
        this.bidNotes = ''
    }

    // Addendum Functionality
    populateAddendumListItem (addendum: Addendum) {
        return {
            icon: 'fas fa-bell',
            tooltip: 'Addendum ' + addendum.version,
            lineOne: 'Addendum ' + addendum.version,
            lineTwo: this.$options!.filters!.formatCalendarDate(addendum.createdAt) || ''
        }
    }

    viewAddendumDetails (addendum: Addendum) {
        this.selectedAddendum = {
            ...addendum,
            comments: addendum.comments.reverse()
        }
        this.openAddendumDetailsDialog = true
    }

    openValidateConfirmDialogFromAddendum () {
        this.openAddendumDetailsDialog = false
        this.validateFromBidCard = false
        this.validateBidConfirmDialog = true
    }

    openValidateConfirmDialogFromBid () {
        this.validateFromBidCard = true
        this.validateBidConfirmDialog = true
    }

    closeValidateConfirmDialog () {
        if (this.validateFromBidCard) {
            this.validateBidConfirmDialog = false
        } else {
            this.validateBidConfirmDialog = false
            this.openAddendumDetailsDialog = true
        }
    }

    onDownloadAll () {
        const filestackApiKey = process.env.VUE_APP_FILESTACK_API_TOKEN || ''

        let fsHandles:string[] = []

        this.files.forEach(fileItem => {
            // get fs handle from file attach 'path'
            let pathArray = fileItem.path.split('/')
            fsHandles.push(pathArray[3])
        })
        if (fsHandles.length > 0) {
            // trigger popup to filestack zip operation
            let dlUrl = 'https://cdn.filestackcontent.com/' + filestackApiKey + '/zip/['
            let handleCount = 1
            fsHandles.forEach(function (handle) {
                dlUrl = dlUrl + handle
                if (handleCount < fsHandles.length) {
                    dlUrl = dlUrl + ','
                } else {
                    dlUrl = dlUrl + ']'
                }
                handleCount++
            })
            window.open(dlUrl, '_dlWin')
        }
    }

    onValidateBid () {
        this.bidValidating = true
        const hash = this.$route.params.hash
        const bid = this.bids[0].id
        axios.post('/api/invitations/' + hash + '/bids/' + bid + '/validate')
            .then(res => {
                SnackBus.showMessage('Your current bid has been successfully confirmed.', 'success')
                this.readProject()
            })
            .catch(err => {
                console.log('Error validating bid: ', err)
                SnackBus.showMessage('Error confirming your current bid.', 'error')
            })
            .finally(() => {
                this.validateBidConfirmDialog = false
                this.bidValidating = false
            })
    }

    torchRequiredAcknowledged () {
        const hash = this.$route.params.hash
        axios.post('/api/invitations/' + hash + '/torchAcknowledged')
            .then(res => {
                this.torchRequiredDialog = false
            })
            .catch(err => {
                console.error('torch acknowledgement failed:', err)
            })
    }
}
