var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { "data-cy": "project-supporting-files-card" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "headline pb-0",
          staticStyle: { "flex-wrap": "nowrap" },
          attrs: { "primary-title": "" }
        },
        [_vm._v("\n        Supporting Files\n    ")]
      ),
      _c("v-card-text", { staticClass: "grey--text subtitle-1 pt-0" }, [
        _vm._v("\n        Total " + _vm._s(_vm.files.length) + "\n    ")
      ]),
      _c(
        "v-list",
        { staticClass: "projectFileColumn", attrs: { "two-line": "" } },
        [
          _c(
            "v-list-tile",
            [
              _c(
                "v-list-tile-avatar",
                { attrs: { color: "grey lighten-1" } },
                [
                  _c("v-icon", { attrs: { dark: "", size: "16" } }, [
                    _vm._v(
                      "\n                    fas fa-file\n                "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-list-tile-content",
                [
                  _c("v-list-tile-title", [
                    _vm._v("\n                    File(s)\n                ")
                  ])
                ],
                1
              ),
              _c(
                "v-list-tile-action",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", flat: "" },
                      on: { click: _vm.onAddFile }
                    },
                    [_vm._v("\n                    Add\n                ")]
                  )
                ],
                1
              ),
              _vm.files.length > 1
                ? _c(
                    "v-list-tile-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary", flat: "" },
                          on: { click: _vm.onDownloadAll }
                        },
                        [
                          _vm._v(
                            "\n                    Download All\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-list",
        { staticClass: "projectFileColumn", attrs: { "two-line": "" } },
        [
          _c("v-subheader", [_vm._v("\n            Files Added\n        ")]),
          _vm._l(_vm.files, function(file, index) {
            return _c(
              "v-list-tile",
              { key: file.id, attrs: { href: file.path, target: "_blank" } },
              [
                _c(
                  "v-list-tile-avatar",
                  [
                    _c("v-icon", { attrs: { size: "16" } }, [
                      _vm._v(
                        "\n                    fas fa-download\n                "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-list-tile-content",
                  [
                    _c("v-list-tile-title", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(file.filename) +
                          "\n                "
                      )
                    ]),
                    _c("v-list-tile-sub-title", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("formatCalendarDate")(file.createdAt)) +
                          "\n                "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-list-tile-action",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          flat: "",
                          icon: "",
                          color: "rgba(0,0,0,0.54)",
                          disabled: file.isDeleting,
                          loading: file.isDeleting
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.deleteFile(file, index)
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { size: "16" } }, [
                          _vm._v(
                            "\n                        fas fa-trash\n                    "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm.files.length === 0
            ? _c("v-list-tile", { staticClass: "pl-3" }, [
                _vm._v(
                  "\n            No files associated to this project\n        "
                )
              ])
            : _vm._e()
        ],
        2
      ),
      _vm.deleteFileConfirmDialog
        ? _c("confirmation-dialog", {
            attrs: {
              "show-dialog": _vm.deleteFileConfirmDialog,
              header: "Delete File?",
              message:
                "This will permanently remove the file from this project.",
              action: "Delete"
            },
            on: {
              "close-dialog": function($event) {
                _vm.deleteFileConfirmDialog = false
              },
              "confirm-action": _vm.confirmDelete
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }