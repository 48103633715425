import Router from 'vue-router'
import Login from './views/Login.vue'
import Dashboard from './views/Dashboard.vue'
import Projects from './views/Projects/Projects.vue'
import AddProject from './views/Projects/AddProject.vue'
import ReadProject from './views/Projects/ReadProject.vue'
import EditProject from './views/Projects/EditProject.vue'
import ContractorReadProject from './views/Projects/ContractorReadProject.vue'
import Emulate from './views/Emulate.vue'
import Forbidden from './views/Forbidden.vue'
import NotFound from './views/NotFound.vue'
import devOnlyGuard from './utils/guards/devOnlyGuard'
import { mainOidc } from './oidc'
import authGuard from '@/utils/guards/authGuard'
import { signInWithRedirectToCurrentRoute } from './utils/authHelpers'

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login,
            beforeEnter: (to, from, next) => {
                if (!mainOidc.isAuthenticated) {
                    signInWithRedirectToCurrentRoute()
                }
                if (process.env.NODE_ENV === 'production') {
                    next('/dashboard')
                } else {
                    next()
                }
            }
        },
        {
            path: '/invite/:hash',
            name: 'contractorReadProject',
            component: ContractorReadProject
        },
        {
            path: '/shared/:hash',
            name: 'sharedAccessProject',
            beforeEnter: (to) => {
                // Redirect to Project Center
                window.location.href = `${process.env.VUE_APP_PROJECT_CENTER_URL}/shared/${to.params.hash}`
            }
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            beforeEnter: authGuard,
            meta: {
                authName: mainOidc.authName
            },
            redirect: { name: 'projects' },
            children: [
                {
                    path: 'projects',
                    name: 'projects',
                    component: Projects
                },
                {
                    path: 'projects/new',
                    name: 'addProject',
                    component: AddProject
                },
                {
                    path: 'projects/:id',
                    name: 'readProject',
                    component: ReadProject
                },
                {
                    path: 'projects/:id/edit',
                    name: 'editProject',
                    component: EditProject
                },
                {
                    path: 'emulate',
                    name: 'emulate',
                    beforeEnter: devOnlyGuard,
                    component: Emulate
                },
                {
                    path: '403-forbidden',
                    name: 'forbidden',
                    component: Forbidden
                }
            ]
        },
        {
            path: '*',
            component: NotFound
        }
    ]
})

export default router
