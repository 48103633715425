






















































































































































import axios from 'axios'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { VForm, Contractor, generateEmptyContractor, ContractorOrNull } from '../../types'
import SnackBus from '../../utils/buses/SnackBus'

interface BidContractor extends Contractor {
    fullName: string
}

@Component
export default class InviteContractor extends Vue {
    @Prop({
        default: null
    })
    contractorList!: BidContractor[]

    @Prop({
        default: false
    })
    resetContractorForm!: Boolean

    @Prop({
        default: false
    })
    isEditingContractor!: Boolean

    @Prop({
        default: null
    })
    contractorToEdit!: Contractor

    @Prop({
        default: false
    })
    isDeleting!: Boolean

    @Prop({
        default: false
    })
    canSendCalendarInvites!: Boolean

    @Watch('contractorToEdit', {
        immediate: true
    })
    setContractor () {
        this.contractor = { ...this.contractorToEdit! }

        if (this.$refs.contractorForm) {
            (this.$refs.contractorForm as any).resetValidation()
        }
    }

    @Watch('resetContractorForm')
    resetForm () {
        this.contractor = generateEmptyContractor()
        if (this.$refs.contractorForm) {
            (this.$refs.contractorForm as any).resetValidation()
        }
        this.isNewContractor = true
    }

    get bidContractorsFull (): BidContractor[] {
        let contractorMap = this.contractorList!.map(i => {
            let fullName!: string
            let contractor = {
                ...i,
                fullName: i.name + i.companyName
            }
            return contractor
        })
        return contractorMap
    }

    private contractor: Contractor = generateEmptyContractor()
    private showInviteButton: boolean = false
    private isNewContractor: boolean = true
    private rules = {
        required: (value: any) => !!value || 'This field is required'
    }

    onContractorSelect (value: Contractor) {
        this.contractor = value !== undefined ? value : generateEmptyContractor()
        this.showInviteButton = value !== undefined
        this.isNewContractor = value === undefined
    }

    onContractorInput () {
        if (!(this.$refs.contractorForm as VForm).validate()) {
            this.showInviteButton = false
            return
        }

        this.showInviteButton = true
        this.isNewContractor = !this.isEditingContractor
    }

    async onSubmit () {
        if (this.isEditingContractor) {
            try {
                await axios.post(`/api/contractors/${this.contractor!.id!}`, this.contractor)
                SnackBus.showMessage('The contractor has successfully been edited.', 'success')
                this.$emit('close-dialog')
                this.$emit('reload-data')
            } catch {
                SnackBus.showMessage('There was an error updating this contractor.', 'error')
            }
        } else {
            this.$emit('send-invite', this.contractor)
        }
    }
}
