
























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Comment } from '../types'

@Component
export default class TimelineItem extends Vue {
    // determines if body is shown initially
    @Prop({
        default: false
    })
    readonly show!: boolean
    @Prop({
        required: true
    })
    readonly item!: Comment

    // set initial expanded state
    private expanded: boolean = this.show
}
