var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-tile",
    [
      _c(
        "v-list-tile-avatar",
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "", "max-width": "125px" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-badge",
                        {
                          attrs: { overlap: "" },
                          scopedSlots: _vm._u(
                            [
                              _vm.item.badge
                                ? {
                                    key: "badge",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.item.badge) +
                                            "\n                    "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                : null
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("v-icon", _vm._g({ attrs: { size: "36" } }, on), [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.item.icon) +
                                "\n                    "
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.item.tooltip) +
                    "\n            "
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-list-tile-content",
        [
          _c("v-list-tile-title", [
            _vm._v("\n            " + _vm._s(_vm.item.lineOne) + "\n        ")
          ]),
          _c("v-list-tile-sub-title", [
            _vm._v("\n            " + _vm._s(_vm.item.lineTwo) + "\n        ")
          ]),
          _vm.item.lineThree
            ? _c("v-list-tile-sub-title", [
                _vm._v(
                  "\n            " + _vm._s(_vm.item.lineThree) + "\n        "
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }