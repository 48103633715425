import { Component, Vue } from 'vue-property-decorator'
import { Client, StompSubscription, messageCallbackType, IMessage, IPublishParams, StompHeaders } from '@stomp/stompjs'
import SockJS from 'sockjs-client'

interface IMessageJson {
    message: IMessage,
    json: any
}

interface IPublishParamsJson extends IPublishParams {
    objectBody?: any
}

export class JsonClient extends Client {
    subscribeJson (destination: string, handler: (message: IMessageJson) => void, headers?: StompHeaders | undefined) {
        this.subscribe(destination, message => {
            try {
                handler({
                    message: message,
                    json: JSON.parse(message.body)
                })
            } catch (e) {
                handler({
                    message,
                    json: null
                })
            }
        })
    }

    publishJson (params: IPublishParamsJson) {
        this.publish({
            ...params,
            body: JSON.stringify(params.objectBody)
        })
    }
}

// TODO revisit single-socket, multi-subscription service
@Component
class SocketBus extends Vue {
    private clients: Client[] = []

    // add a subscription by opening a new client
    subscribeClient (destination: string, handler: (message: IMessageJson) => void) {
        let client = new JsonClient({
            webSocketFactory: () => {
                return new SockJS(process.env.VUE_APP_API_URL + '/ws')
            },
            // reconnectDelay: 5000,
            // heartbeatIncoming: 4000,
            // heartbeatOutgoing: 4000,
            onConnect: frame => {
                console.debug('Connected: ' + frame)

                client.subscribeJson(destination, handler)
            },
            onWebSocketClose: (conf) => {
                console.debug('Disconnected: %o', conf)
            }
        })

        client.activate()

        this.clients.push(client)
        return client
    }

    publishJson (client: Client, destination: string, body: any) {
        client.publish({
            destination,
            body: JSON.stringify(body)
        })
    }
}

export default new SocketBus()
