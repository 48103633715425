






















import { Component, Vue, Prop } from 'vue-property-decorator'
import { CustomerOrNull, generateEmptyCustomer } from '../types'

@Component
export default class CustomerForm extends Vue {
    @Prop({
        default: () => (generateEmptyCustomer())
    })
    readonly customer!: CustomerOrNull

    private rules = {
        required: (value: any) => !!value || 'This field is required',
        newCustomer: (value: any) => !!this.customer!.id
    }
}
