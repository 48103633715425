var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c(
        "v-form",
        { ref: "contactForm" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm.contact
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v(
                          "\n                    Contact Information\n                "
                        )
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          label: "Contact Name*",
                          autocomplete: "name",
                          rules: [_vm.rules.newContact, _vm.rules.required]
                        },
                        on: {
                          input: function($event) {
                            return _vm.$emit("input", _vm.contact)
                          }
                        },
                        model: {
                          value: _vm.contact.name,
                          callback: function($$v) {
                            _vm.$set(_vm.contact, "name", $$v)
                          },
                          expression: "contact.name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm6: "" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "Title", rules: [_vm.rules.newContact] },
                    on: {
                      input: function($event) {
                        return _vm.$emit("input", _vm.contact)
                      }
                    },
                    model: {
                      value: _vm.contact.title,
                      callback: function($$v) {
                        _vm.$set(_vm.contact, "title", $$v)
                      },
                      expression: "contact.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm6: "" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "Company", rules: [_vm.rules.newContact] },
                    on: {
                      input: function($event) {
                        return _vm.$emit("input", _vm.contact)
                      }
                    },
                    model: {
                      value: _vm.contact.companyName,
                      callback: function($$v) {
                        _vm.$set(_vm.contact, "companyName", $$v)
                      },
                      expression: "contact.companyName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm6: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Phone",
                      rules: [_vm.rules.newContact],
                      mask: "phone",
                      hint: "(###) ###-####"
                    },
                    on: {
                      input: function($event) {
                        return _vm.$emit("input", _vm.contact)
                      }
                    },
                    model: {
                      value: _vm.contact.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.contact, "phone", $$v)
                      },
                      expression: "contact.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm6: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Email",
                      rules: [_vm.rules.newContact, _vm.rules.required]
                    },
                    on: {
                      input: function($event) {
                        return _vm.$emit("input", _vm.contact)
                      }
                    },
                    model: {
                      value: _vm.contact.email,
                      callback: function($$v) {
                        _vm.$set(_vm.contact, "email", $$v)
                      },
                      expression: "contact.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("address-form", {
                    attrs: { address: _vm.contact.address },
                    on: {
                      "update:address": function($event) {
                        return _vm.$set(_vm.contact, "address", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }