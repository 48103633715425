<template>
    <v-container class="text-xs-center">
        <v-icon
            color="primary"
            size="72"
            class="mb-3"
        >
            fas fa-exclamation-triangle
        </v-icon>
        <h1 class="display-1">
            403 Forbidden
        </h1>
    </v-container>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class NotFound extends Vue {}
</script>

<style>

</style>
