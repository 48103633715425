import { createOidcAuth, SignInType } from 'vue-oidc-client/vue2'

// note the ending '/'
const loco = window.location
const appUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`

// SignInType could be Window or Popup
export const mainOidc = createOidcAuth(
    'main',
    SignInType.Window,
    appUrl,
    {
        authority: process.env.VUE_APP_OIDC_AUTHORITY_URL,
        client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
        response_type: process.env.VUE_APP_OIDC_RESPONSE_TYPE,
        scope: process.env.VUE_APP_OIDC_SCOPES
    }
)
