var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { "data-cy": "project-shared-links-card" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "headline pb-0",
          staticStyle: { "flex-wrap": "nowrap" },
          attrs: { "primary-title": "" }
        },
        [_vm._v("\n        Shared Access\n    ")]
      ),
      _c("v-card-text", { staticClass: "grey--text subtitle-1 pt-0" }, [
        _vm._v("\n        Total " + _vm._s(_vm.contacts.length) + "\n    ")
      ]),
      _c(
        "v-list",
        { staticClass: "projectContactsColumn", attrs: { "two-line": "" } },
        [
          _c(
            "v-list-tile",
            [
              _c(
                "v-list-tile-avatar",
                { attrs: { color: "grey lighten-1" } },
                [
                  _c("v-icon", { attrs: { dark: "", size: "16" } }, [
                    _vm._v(
                      "\n                    fas fa-user-plus\n                "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-list-tile-content",
                [
                  _c("v-list-tile-title", [
                    _vm._v(
                      "\n                    Add a New Contact\n                "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-list-tile-action",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", flat: "" },
                      on: { click: _vm.onAddContact }
                    },
                    [_vm._v("\n                    Add\n                ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-subheader", [
            _vm._v("\n            Contacts with Access\n        ")
          ]),
          _vm._l(_vm.revokableContacts, function(contact, index) {
            return _c(
              "v-list-tile",
              {
                key: contact.id,
                attrs: { href: contact.path, target: "_blank" }
              },
              [
                _c(
                  "v-list-tile-avatar",
                  [
                    _c("v-icon", { attrs: { size: "16" } }, [
                      _vm._v(
                        "\n                    fas fa-user\n                "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-list-tile-content",
                  [
                    _c("v-list-tile-title", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(contact.name) +
                          "\n                "
                      )
                    ]),
                    _c("v-list-tile-sub-title", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(contact.companyName) +
                          "\n                "
                      )
                    ]),
                    _c("v-list-tile-sub-title", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(contact.email) +
                          "\n                "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-list-tile-action",
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c(
                          "v-flex",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.revokeAccess(contact.id, index)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      size: "16",
                                      color: "rgba(0,0,0,0.54)"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                fas fa-trash\n                            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.onEditContact(contact)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      size: "16",
                                      color: "rgba(0,0,0,0.54)"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                fas fa-pen\n                            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm.revokableContacts.length === 0
            ? _c("v-list-tile", { staticClass: "pl-3" }, [
                _vm._v(
                  "\n            No contacts associated to this project\n        "
                )
              ])
            : _vm._e()
        ],
        2
      ),
      _vm.contactToInvite
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "600" },
              model: {
                value: _vm.showContactDialog,
                callback: function($$v) {
                  _vm.showContactDialog = $$v
                },
                expression: "showContactDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("invite-project-contact", {
                    attrs: {
                      "contact-to-invite": _vm.contactToInvite,
                      "is-editing-contact": _vm.isEditingContact
                    },
                    on: {
                      "close-dialog": function($event) {
                        _vm.showContactDialog = false
                      },
                      "reload-data": function($event) {
                        return _vm.$emit("reload-data")
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showRevokeAccessConfirmDialog
        ? _c("confirmation-dialog", {
            attrs: {
              "show-dialog": _vm.showRevokeAccessConfirmDialog,
              header: "Revoke Access?",
              message:
                "This will remove this contact's access to the shared details about this project.",
              action: "Revoke Access"
            },
            on: {
              "close-dialog": function($event) {
                _vm.showRevokeAccessConfirmDialog = false
              },
              "confirm-action": _vm.onRevokeAccess
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }