






















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AddressOrNull, emptyAddress, Address } from '../types'
import states from 'iso3166-2-db/regions/US/geonames.json'
import orderBy from 'lodash/orderBy'

@Component
export default class AddressForm extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    readonly isRequired!: boolean

    @Prop({
        default: () => ({ ...emptyAddress })
    })
    readonly address!: Address

    private states = orderBy(states, ['name'], ['asc'])

    private rules = {
        required: (value: any) => {
            if (!this.isRequired) {
                return false
            }

            return !!value || 'This field is required'
        }
    }
}
