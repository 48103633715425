var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "grid-list-lg": "" } },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v("\n        Emulate\n    ")]),
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", md4: "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-3" },
                [
                  _c(
                    "v-list",
                    [
                      _c("v-list-tile", [
                        _vm._v(
                          "\n                        Current Role: " +
                            _vm._s(_vm.currentRole) +
                            "\n                    "
                        )
                      ]),
                      _c("v-divider"),
                      _c("p", { staticClass: "px-3 pt-3" }, [
                        _vm._v(
                          "\n                        Choose a role to emulate. The browser will refresh after a choice is made. This is to apply the newly set cookie token.\n                    "
                        )
                      ]),
                      _c("v-subheader", [
                        _vm._v(
                          "\n                        Emulate as\n                    "
                        )
                      ]),
                      _vm._l(_vm.roles, function(item) {
                        return _c(
                          "v-list-tile",
                          { key: item.name },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { block: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.setRole(item.role)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.name) +
                                    "\n                        "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }