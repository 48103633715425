import { StringOrNull, NumberOrNull } from './customTypes'
import { Address, emptyAddress } from './address'
import { CustomerContact } from './contact'

export interface Customer {
    id?: string;
    userId: NumberOrNull;
    name: StringOrNull;
}

export interface CustomerAllData extends Customer {
    contacts: CustomerContact[];
}

export const generateEmptyCustomer = (): CustomerAllData => ({
    name: null,
    userId: null,
    contacts: []
})
