var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.item.title
        ? _c("span", { staticClass: "font-weight-bold d-block" }, [
            _vm._v("\n        " + _vm._s(_vm.item.title) + "\n    ")
          ])
        : _vm._e(),
      _vm.expanded
        ? _c("p", { staticClass: "text-body mb-0" }, [
            _vm._v(_vm._s(_vm.item.body))
          ])
        : _vm._e(),
      _vm.item.body
        ? _c(
            "v-btn",
            {
              staticClass: "mx-0 caption text-capitalize show-more-button",
              attrs: { flat: "", small: "" },
              on: {
                click: function($event) {
                  _vm.expanded = !_vm.expanded
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.expanded ? "Show Less" : "Show More") +
                  "\n    "
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }