











































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { StringOrNull } from '../types'

@Component
export default class PageContent extends Vue {
    @Prop({
        default: false
    })
    readonly loading!: boolean
    @Prop({
        default: null
    })
    readonly error!: StringOrNull
    @Prop({
        default: null
    })
    readonly info!: StringOrNull
}
