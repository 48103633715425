var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.persistent, width: "290" },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline", attrs: { "primary-title": "" } },
            [_vm._v("\n            " + _vm._s(_vm.header) + "\n        ")]
          ),
          _c("v-card-text", [
            _vm._v(
              "\n            " +
                _vm._s(_vm.message) +
                " Are you sure you wish to continue?\n        "
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close-dialog")
                    }
                  }
                },
                [_vm._v("\n                Cancel\n            ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: _vm.buttonType },
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirm-action")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(_vm.action) + "\n            "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }