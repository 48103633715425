












































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import {
    ProjectInvitation,
    Invitation,
    Contractor,
    generateEmptyContractor,
    BidInvitation,
    Bid,
    generateEmptyBid
} from '../../types'
import UserBus, {
    ROLE_DBS_ADMIN,
    ROLE_DBS_PROJECT_MANAGEMENT,
    ROLE_DBS_PROPOSAL_TEAM,
    ROLE_PROJ_CENTER_ADMIN
} from '../../utils/buses/UserBus'
import BidDetails from './BidDetails.vue'
import DetailedListItem from '../DetailedListItem.vue'
import InviteContractor from './InviteContractor.vue'
import ConfirmationDialog from '../ConfirmationDialog.vue'

@Component({
    components: {
        BidDetails,
        DetailedListItem,
        InviteContractor,
        ConfirmationDialog
    },
    filters: {
        iconByStatus: (status: string) => {
            switch (status) {
            case 'Invitation Declined':
                return 'fas fa-user-times'
            case 'Invitation Not Sent':
                return 'fas fa-user-clock'
            case 'Invitation Sent':
                return 'fas fa-envelope'
            case 'Invitation Viewed':
                return 'far fa-envelope-open'
            case 'Invitation Expired':
                return 'fas fa-user-slash'
            case 'Bid Submitted':
                return 'fas fa-envelope-open-text'
            case 'Bid Accepted':
                return 'fas fa-check'
            case 'Bid Awarded':
                return 'fas fa-trophy'
            }
        }
    }
})
export default class ContractorInvitesCard extends Vue {
    @Prop({
        default: []
    })
    bidInvitations!: ProjectInvitation[]

    @Prop({
        default: null
    })
    bidDueDate!: Date

    @Prop({
        default: null
    })
    bidDueTimeZone!: string

    @Prop({
        default: false
    })
    isLoading!: boolean

    @Prop({
        default: false
    })
    promotingBid!: boolean

    @Prop({
        default: false
    })
    showDownloadSpinner!: boolean

    private inviteToDelete!: Invitation
    private inviteIndexToDelete!: number
    private deleteInvitationConfirmDialog: boolean = false

    private inviteToResend!: Invitation
    private inviteIndexToResend!: number
    private resendInvitationConfirmDialog: boolean = false

    private pastBidDueDate: boolean = false

    private openBidDetailsDialog: boolean = false
    private bidDetails: Bid = generateEmptyBid()
    private contractorDetails: Contractor = generateEmptyContractor()

    private showContractorDialog: boolean = false
    private isEditingContractor: boolean = true
    private contractorToEdit: Contractor = generateEmptyContractor()

    private bidExpansionPanel: boolean[] = [true]

    private contractorsWithSubmittedBidCount: number = 0
    private invitedContractorCount: number = 0

    get acceptedBidList (): ProjectInvitation[] {
        // if there's an awarded bid do not show accepted bid area
        const awardedBidIndex = this.bidInvitations!.findIndex(i => i.status === 'Bid Awarded')
        let bidInvitationsMap: ProjectInvitation[] = []
        if (awardedBidIndex === -1) {
            bidInvitationsMap = this.bidInvitations!.filter(i => {
                if (i.bids.length && i.status !== 'Bid Awarded') {
                    let accepted = i.bids[0].acceptedAt !== null && i.bids[0].invalidatedAt === null && i.declinedAt === null
                    return accepted
                }
            })
        }
        this.bidExpansionPanel = bidInvitationsMap.length ? [false] : [true]
        return bidInvitationsMap
    }

    get awardedBid (): ProjectInvitation[] {
        let bidInvitationsMap = this.bidInvitations!.filter(i => {
            if (i.bids.length > 0) {
                return i.status === 'Bid Awarded'
            }
        })
        this.bidExpansionPanel = bidInvitationsMap.length ? [false] : [true]
        return bidInvitationsMap
    }

    get allowedUsers (): Boolean {
        const role = UserBus!.session!.role!
        return [
            ROLE_DBS_ADMIN,
            ROLE_DBS_PROJECT_MANAGEMENT,
            ROLE_DBS_PROPOSAL_TEAM,
            ROLE_PROJ_CENTER_ADMIN
        ].includes(role)
    }

    get allowedBidList (): ProjectInvitation[] {
        // If the user is restricted (like a Sales Rep) change the bid statuses to be submitted
        if (!this.allowedUsers) {
            let bidInvitationsMap = this.bidInvitations!.map(i => {
                if (i.status === 'Bid Awarded' || i.status === 'Bid Accepted') {
                    return {
                        ...i,
                        status: 'Bid Submitted'
                    }
                } else {
                    return i
                }
            })
            return bidInvitationsMap
        } else {
        // If a user has full access, show them the list of unaccepted bids
            const awardedBidIndex = this.bidInvitations!.findIndex(i => i.status === 'Bid Awarded')
            let bidInvitationsMap = []
            if (awardedBidIndex !== -1) {
                bidInvitationsMap = this.bidInvitations!.filter(i => {
                    return i.status !== 'Bid Awarded'
                })
            } else {
                bidInvitationsMap = this.bidInvitations!.filter(i => {
                    if (i.bids.length > 0) {
                        return i.declinedAt !== null || i.bids[0].invalidatedAt !== null || i.bids[0].acceptedAt === null
                    } else {
                        return true
                    }
                })
            }
            return bidInvitationsMap
        }
    }

    mounted () {
        let today: Date = new Date()
        const bidDueDate = new Date(this.bidDueDate!)
        this.pastBidDueDate = bidDueDate < today
        this.bidInvitations.forEach(inv => {
            if (inv.bids.length > 0) this.contractorsWithSubmittedBidCount++
        })
    }

    bidCreated (bids: Bid[]) {
        if (!bids.length) {
            return false
        } else {
            let date = bids[0].acceptedAt !== null ? bids[0].acceptedAt : bids[0].createdAt
            return date
        }
    }

    inviteTime (invite: Invitation) {
        let time
        switch (invite.status) {
        case 'Invitation Declined':
            time = invite.declinedAt
            break
        case 'Invitation Not Sent':
            time = null
            break
        case 'Invitation Sent':
            time = invite.sentAt
            break
        case 'Invitation Viewed':
            time = invite.viewedAt
            break
        case 'Invitation Expired':
            time = invite.expiredAt
            break
        case 'Bid Submitted':
            time = invite.bids[0].createdAt
            break
        case 'Bid Accepted':
            time = invite.bids[0].acceptedAt
            break
        case 'Bid Awarded':
            time = invite.bids[0].awardedAt
        }
        return time
    }

    populateInviteListItem (invite: Invitation) {
        let listDetails = {
            icon: this.$options!.filters!.iconByStatus(invite.status),
            badge: invite.bids.length > 1 ? 'v' + invite.bids.length : null,
            tooltip: this.getTooltipForInvitation(invite),
            lineOne: invite.contractor.companyName,
            lineTwo: invite.contractor.name,
            lineThree: invite.contractor.email
        }
        return listDetails
    }

    getTooltipForInvitation (invite: Invitation): string {
        return (invite.status === 'Bid Submitted' ? 'Bid ' + invite.bids.length + ' Submitted' : invite.status)
            + ' ' + this.$options!.filters!.formatCalendarDate(this.inviteTime(invite))
    }

    canDeleteInvitation (invite: BidInvitation) {
        if (invite.bids.length > 0 || invite.expiredAt !== null || invite.status === 'Invitation Declined') {
            return false
        } else {
            return true
        }
    }

    canResendInvitation (invite: BidInvitation) {
        if (invite.status !== 'Invitation Not Sent' && invite.status !== 'Invitation Declined' && invite.expiredAt === null) {
            return true
        } else {
            return false
        }
    }

    canViewBid (invite: BidInvitation) {
        if (!this.allowedUsers) {
            return false
        } else if (invite.status === 'Bid Submitted' || invite.status === 'Bid Accepted' || invite.status === 'Bid Awarded') {
            return true
        }
    }

    canEditContractor (invite: Invitation) {
        if (invite.contractor.deletedAt === null && invite.status !== 'Invitation Expired') {
            return true
        } else {
            return false
        }
    }

    deleteInvite (invite: Invitation, index: number) {
        this.deleteInvitationConfirmDialog = true
        this.inviteToDelete = invite
        this.inviteIndexToDelete = index
    }

    onDeleteInvite (invite: Invitation, index: number) {
        this.bidInvitations[index].isDeleting = true
        this.deleteInvitationConfirmDialog = false
        this.$emit('delete-invite', invite)
    }

    resendInvite (invite: Invitation, index: number) {
        this.resendInvitationConfirmDialog = true
        this.inviteToResend = invite
        this.inviteIndexToResend = index
    }

    onResendInvite (invite: Invitation, index: number) {
        this.resendInvitationConfirmDialog = false
        this.$emit('resend-invite', invite)
    }

    openBidDetails (bid: Bid, contractor: Contractor) {
        this.openBidDetailsDialog = true
        this.bidDetails = bid
        this.contractorDetails = contractor
    }

    onPromoteBid (actionOnBid: string) {
        this.$emit('on-promote-bid', actionOnBid, this.bidDetails)
    }

    onAddInvitation () {
        this.$emit('add-invite')
    }

    onEditContractor (contractor: Contractor) {
        this.showContractorDialog = true
        this.isEditingContractor = true
        this.contractorToEdit = contractor
    }

    onDownloadBids () {
        this.$emit('download-bids')
    }
}
