




































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Comment } from '../types'
import { statuses } from '../utils/constants/statuses'
import TimelineItem from './TimelineItem.vue'
import { DateTime } from 'luxon'
import _ from 'lodash'

@Component({
    components: {
        TimelineItem
    },
    filters: {
        iconByType: (value: string) => {
            if (statuses[value]) {
                // if type matches a status, use corresponding icon
                return statuses[value].icon
            } else {
                // else use custom icon
                switch (value) {
                case 'ASSIGNED_PROJECT_MANAGER':
                case 'ASSIGNED_PROPOSAL_TEAM':
                    return 'fas fa-user-tag'
                default:
                    return null
                }
            }
        }
    }
})
export default class ProjectTimeline extends Vue {
    @Prop({
        default: () => ([])
    })
    readonly items!: Comment[]

    get timelineItems () {
        let sortedItems = _.sortBy(this.items, ['createdAt'])
        const firstNonDraftChange: Comment | undefined = sortedItems
            .find(item => item.title.startsWith('Status changed') && item.type !== 'DRAFT')
        const timeNoLongerDraft: DateTime = firstNonDraftChange
            ? DateTime.fromISO(firstNonDraftChange.createdAt)
            : DateTime.fromMillis(0)
        return this.items.map(item => ({
            ...item,
            // determine if body text should be shown on load
            show: false
        })).filter(item => DateTime.fromISO(item.createdAt) >= timeNoLongerDraft)
    }
}
