import router from '@/router'
import { mainOidc } from '@/oidc'

// Provides a way to sign in on the same page the user is currently on when they have an expired token.
export async function signInWithRedirectToCurrentRoute () {
    let route = router.currentRoute.fullPath
    if (route.startsWith('/auth/')) {
        route = '/'
    }
    return mainOidc.signIn({ state: { to: route } })
}

mainOidc.events.addSilentRenewError(async () => {
    await signInWithRedirectToCurrentRoute()
})

mainOidc.events.addAccessTokenExpired(async () => {
    // Preserve the previous page's request for authentication and ignore that the auth route also has
    // an expired token.
    if (router.currentRoute.fullPath.startsWith('/auth/')) {
        return
    }

    await signInWithRedirectToCurrentRoute()
})
