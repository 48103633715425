
















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { CustomerContactOrNull, generateEmptyCustomerContact } from '../../types'
import AddressForm from './../AddressForm.vue'

@Component({
    components: {
        AddressForm
    }
})
export default class ContactForm extends Vue {
    @Prop({
        default: () => (generateEmptyCustomerContact(null))
    })
    readonly contact!: CustomerContactOrNull

    @Prop({
        default: false,
        required: false
    })
    resetContactValidation!: Boolean

    @Watch('resetContactValidation')
    resetValidation () {
        if (this.$refs.contactForm) {
            (this.$refs.contactForm as any).resetValidation()
        }
    }

    private rules = {
        required: (value: any) => !!value || 'This field is required',
        newContact: (value: any) => !!this.contact!.id
    }
}
