import { render, staticRenderFns } from "./DetailedListItem.vue?vue&type=template&id=6a457951&"
import script from "./DetailedListItem.vue?vue&type=script&lang=ts&"
export * from "./DetailedListItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!/codebuild/output/src2994353656/src/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAvatar } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileSubTitle } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VTooltip } from 'vuetify/lib'
installComponents(component, {VBadge,VIcon,VListTile,VListTileAvatar,VListTileContent,VListTileSubTitle,VListTileTitle,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2994353656/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('6a457951', component.options)
    } else {
      api.reload('6a457951', component.options)
    }
    module.hot.accept("./DetailedListItem.vue?vue&type=template&id=6a457951&", function () {
      api.rerender('6a457951', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DetailedListItem.vue"
export default component.exports