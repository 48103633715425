var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("h3", { staticClass: "title" }, [
                _vm._v("\n                Customer Information\n            ")
              ]),
              _c("v-text-field", {
                attrs: {
                  label: "Customer Name*",
                  autocomplete: "organization",
                  rules: [_vm.rules.newCustomer, _vm.rules.required]
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("input", _vm.customer)
                  }
                },
                model: {
                  value: _vm.customer.name,
                  callback: function($$v) {
                    _vm.$set(_vm.customer, "name", $$v)
                  },
                  expression: "customer.name"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }