var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-content",
    { attrs: { loading: _vm.isLoading, error: _vm.error } },
    [
      _c(
        "v-container",
        { attrs: { "data-cy": "projects-data" } },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "transparent" } },
            [
              _c("v-toolbar-title", [_vm._v("Projects Dashboard")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "secondary",
                  attrs: { to: { name: "addProject" } }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(
                      "\n                    fas fa-plus\n                "
                    )
                  ]),
                  _vm._v(" New Project\n            ")
                ],
                1
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "primary",
                                attrs: {
                                  icon: "",
                                  href:
                                    "https://drive.google.com/file/d/1WH1agzLy1hWKgiSBRhxk2l6CdAVvYFKz/view?usp=sharing",
                                  target: "_cpTrainingVideo"
                                }
                              },
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(
                                  "\n                            fas fa-tv\n                        "
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Training Video")])]
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "", "align-center": "" } },
            [
              _c(
                "v-container",
                { attrs: { "px-0": "", "grid-list-lg": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { key: "text" },
                        [
                          _c("v-text-field", {
                            staticClass: "dbs-append-outer-button",
                            attrs: {
                              "single-line": "",
                              solo: "",
                              clearable: "",
                              "clear-icon": "fas fa-times",
                              "hide-details": ""
                            },
                            on: {
                              "click:clear": function($event) {
                                _vm.$nextTick(function() {
                                  return _vm.onChangeTerm()
                                })
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onChangeTerm($event)
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-inner",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    fas fa-search\n                                "
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "append-outer",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          disabled: !_vm.filterTerm
                                        },
                                        on: { click: _vm.onChangeTerm }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Search\n                                "
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.filterTerm,
                              callback: function($$v) {
                                _vm.filterTerm = $$v
                              },
                              expression: "filterTerm"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color:
                                  _vm.filterStatuses.length > 0 ? "primary" : ""
                              }
                            },
                            [
                              _vm._v(
                                "\n                            fas fa-clipboard-check\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { key: "status", attrs: { shrink: "" } },
                        [
                          _c("v-autocomplete", {
                            ref: "statusFilter",
                            staticClass: "filter-input",
                            attrs: {
                              items: _vm.statuses,
                              label: "Status",
                              multiple: "",
                              "single-line": "",
                              dense: "",
                              solo: "",
                              "hide-details": "",
                              "return-object": ""
                            },
                            on: { change: _vm.onChangeStatus },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-item",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-list-tile",
                                      {
                                        attrs: { ripple: "" },
                                        on: { click: _vm.toggleStatuses }
                                      },
                                      [
                                        _c(
                                          "v-list-tile-action",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color:
                                                    _vm.filterStatuses.length >
                                                    0
                                                      ? "primary"
                                                      : ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.toggleStatusesIcon
                                                    ) +
                                                    "\n                                        "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v("Select All")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-divider", { staticClass: "mt-2" })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    _vm.filterStatuses.length === 1
                                      ? _c("v-chip", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.text) +
                                              "\n                                "
                                          )
                                        ])
                                      : index === 1
                                      ? _c("v-chip", [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.filterStatuses.length
                                              ) +
                                              " selected\n                                "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.filterStatuses,
                              callback: function($$v) {
                                _vm.filterStatuses = $$v
                              },
                              expression: "filterStatuses"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.isDbsUser
                        ? _c(
                            "v-flex",
                            { key: "assignTo", attrs: { shrink: "" } },
                            [
                              _c("v-autocomplete", {
                                staticClass: "filter-input",
                                attrs: {
                                  loading: _vm.isAssignedToFilterLoading,
                                  items: _vm.filterAssignedToOptions,
                                  "item-text": "userName",
                                  "item-value": "id",
                                  label: "Assigned To",
                                  clearable: "",
                                  "single-line": "",
                                  dense: "",
                                  solo: "",
                                  "hide-details": ""
                                },
                                on: { change: _vm.onChangeAssignedTo },
                                model: {
                                  value: _vm.filterAssignedTo,
                                  callback: function($$v) {
                                    _vm.filterAssignedTo = $$v
                                  },
                                  expression: "filterAssignedTo"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isDbsUser && _vm.isSalesMgr
                        ? _c(
                            "v-flex",
                            {
                              key: "assignedToMe",
                              staticClass: "flex-checkbox",
                              attrs: { shrink: "" }
                            },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  color: "primary",
                                  label: "My Projects",
                                  "hide-details": ""
                                },
                                on: { change: _vm.onChangeAssignedCheckbox },
                                model: {
                                  value: _vm.filterAssignedToMe,
                                  callback: function($$v) {
                                    _vm.filterAssignedToMe = $$v
                                  },
                                  expression: "filterAssignedToMe"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            [
              _c("v-data-table", {
                staticClass: "projects-table",
                attrs: {
                  "must-sort": "",
                  headers: _vm.headers,
                  items: _vm.projects,
                  pagination: _vm.pagination,
                  "total-items": _vm.totalItems,
                  "rows-per-page-items": [25, 50, 100],
                  "no-data-text": "No Projects Available",
                  loading: _vm.tableLoading
                },
                on: {
                  "update:pagination": [
                    function($event) {
                      _vm.pagination = $event
                    },
                    _vm.onPaginationUpdate
                  ]
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "cursor-pointer",
                            attrs: {
                              tag: "tr",
                              to: {
                                name: "readProject",
                                params: { id: props.item.id }
                              },
                              "data-cy": "project-details-navigation"
                            }
                          },
                          [
                            _c("td", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(props.item.projectNumber) +
                                  "\n                        "
                              )
                            ]),
                            _c(
                              "td",
                              [
                                props.item.salesRep
                                  ? _c(
                                      "v-badge",
                                      {
                                        staticClass: "dbs-user-chip-badge",
                                        attrs: {
                                          bottom: "",
                                          overlap: "",
                                          color: "transparent"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            props.item.supportingSalesReps
                                              .length > 0
                                              ? {
                                                  key: "badge",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            dark: "",
                                                            small: "",
                                                            color: "primary"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        fas fa-star\n                                    "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              : null
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("user-chip", {
                                          attrs: {
                                            "hide-name":
                                              props.item.supportingSalesReps
                                                .length > 0,
                                            session: props.item.salesRep
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._l(props.item.supportingSalesReps, function(
                                  supportingSalesRep
                                ) {
                                  return _c("user-chip", {
                                    key: supportingSalesRep.email,
                                    attrs: {
                                      session: supportingSalesRep,
                                      "hide-name": ""
                                    }
                                  })
                                })
                              ],
                              2
                            ),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  props.item.customer &&
                                    props.item.customer.name
                                )
                              )
                            ]),
                            _c("td", [_vm._v(_vm._s(props.item.siteName))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCalendarDate")(
                                    props.item.createdAt
                                  )
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCalendarDate")(
                                    props.item.updatedAt
                                  )
                                )
                              )
                            ]),
                            _c("td", { attrs: { align: "right" } }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm._f("getStatusText")(props.item.status)
                                  ) +
                                  "\n                        "
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }