var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { attrs: { "primary-title": "" } }, [
        _c("h3", { staticClass: "headline" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                !_vm.isEditingContact
                  ? "Send Shared Link to Contact"
                  : "Edit Contact"
              ) +
              "\n        "
          )
        ])
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            { attrs: { "grid-list-lg": "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  !_vm.isEditingContact
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              value: _vm.contact,
                              items: _vm.fullProjectContactList,
                              loading: _vm.contactsLoading,
                              label: "Contact Names",
                              placeholder: "Search for a contact",
                              type: "search",
                              "item-value": "id",
                              "item-text": "fullContact",
                              "return-object": "",
                              clearable: "",
                              "clear-icon": "fas fa-times",
                              hint:
                                "If you cannot find a contact add a new one.",
                              "persistent-hint": ""
                            },
                            on: { change: _vm.onContactSelect },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(data) {
                                    return [
                                      _c("v-list-tile-content", [
                                        _c(
                                          "div",
                                          { staticClass: "text-truncate" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(data.item.name) +
                                                "\n                                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "caption grey--text" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(data.item.email) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "selection",
                                  fn: function(data) {
                                    return [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(data.item.name) +
                                          ",\n                            " +
                                          _vm._s(data.item.email) +
                                          "\n                        "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2193360049
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _vm.contact
                        ? _c(
                            "v-form",
                            { ref: "projectContactForm" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("h3", { staticClass: "title" }, [
                                        _vm._v(
                                          "\n                                    Contact Information\n                                "
                                        )
                                      ]),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Contact Name",
                                          autocomplete: "name",
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.contact.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.contact, "name", $$v)
                                          },
                                          expression: "contact.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Title" },
                                        model: {
                                          value: _vm.contact.title,
                                          callback: function($$v) {
                                            _vm.$set(_vm.contact, "title", $$v)
                                          },
                                          expression: "contact.title"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Company" },
                                        model: {
                                          value: _vm.contact.companyName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "companyName",
                                              $$v
                                            )
                                          },
                                          expression: "contact.companyName"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Phone",
                                          mask: "phone",
                                          hint: "(###) ###-####"
                                        },
                                        model: {
                                          value: _vm.contact.phone,
                                          callback: function($$v) {
                                            _vm.$set(_vm.contact, "phone", $$v)
                                          },
                                          expression: "contact.phone"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Email",
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.contact.email,
                                          callback: function($$v) {
                                            _vm.$set(_vm.contact, "email", $$v)
                                          },
                                          expression: "contact.email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("address-form", {
                                        attrs: { address: _vm.contact.address },
                                        on: {
                                          "update:address": function($event) {
                                            return _vm.$set(
                                              _vm.contact,
                                              "address",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c("v-btn", { attrs: { flat: "" }, on: { click: _vm.onCancel } }, [
            _vm._v("\n            Cancel\n        ")
          ]),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", loading: _vm.isLoading },
              on: {
                click: function($event) {
                  return _vm.onSubmit()
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.isEditingContact ? "Save" : "Add & Send Link") +
                  "\n        "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }