var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { "data-cy": "project-contractor-invites-card" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "headline pb-0",
          staticStyle: { "flex-wrap": "nowrap" },
          attrs: { "primary-title": "" }
        },
        [
          _vm._v("\n        Contractor Invites\n        "),
          _c("v-spacer"),
          _vm.bidInvitations.length &&
          _vm.bidInvitations[0].status === "Invitation Not Sent"
            ? _c(
                "v-chip",
                { staticClass: "warning white--text" },
                [
                  _c(
                    "v-avatar",
                    [
                      _c("v-icon", [
                        _vm._v(
                          "\n                    fas fa-exclamation-circle\n                "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v("\n            Unsent Invitation"),
                  _vm.bidInvitations.length > 1
                    ? _c("span", [_vm._v("s")])
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("v-card-text", { staticClass: "grey--text pt-0" }, [
        _c("span", { staticClass: "subtitle-1" }, [
          _vm._v(
            "\n            Total " +
              _vm._s(_vm.bidInvitations.length) +
              "\n        "
          )
        ]),
        this.pastBidDueDate
          ? _c("p", { staticClass: "subheading mb-0" }, [
              _vm.bidDueDate
                ? _c("span", [
                    _vm._v(
                      "Invitations Expired: " +
                        _vm._s(
                          _vm._f("formatLocalDate")(
                            _vm.bidDueDate,
                            "L LT z",
                            _vm.bidDueTimeZone
                          )
                        )
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      "You must set the bid due date to invite contractors to bid."
                    )
                  ])
            ])
          : _vm._e()
      ]),
      _vm.awardedBid.length == 0 && !this.pastBidDueDate
        ? [
            _c(
              "v-list",
              { attrs: { "two-line": "" } },
              [
                _c(
                  "v-list-tile",
                  [
                    _c(
                      "v-list-tile-avatar",
                      { attrs: { color: "grey lighten-1" } },
                      [
                        _c("v-icon", { attrs: { dark: "", size: "16" } }, [
                          _vm._v(
                            "\n                        fas fa-user-plus\n                    "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-list-tile-content",
                      [
                        _c("v-list-tile-title", [
                          _vm._v(
                            "\n                        Add Contractor\n                    "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-list-tile-action",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "secondary", flat: "" },
                            on: { click: _vm.onAddInvitation }
                          },
                          [
                            _vm._v(
                              "\n                        Add\n                    "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.awardedBid.length && _vm.allowedUsers
        ? [
            _c("p", { staticClass: "subheading mb-0 pl-3" }, [
              _vm._v("\n            Awarded Bid\n        ")
            ]),
            _c(
              "v-list",
              { attrs: { "two-line": "" } },
              _vm._l(_vm.awardedBid, function(invite) {
                return _c(
                  "detailed-list-item",
                  {
                    key: invite.id,
                    attrs: { item: _vm.populateInviteListItem(invite) }
                  },
                  [
                    _vm.allowedUsers
                      ? _c(
                          "v-list-tile-action",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "secondary", flat: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.openBidDetails(
                                      invite.bids[0],
                                      invite.contractor
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        View\n                    "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              1
            ),
            _c("v-divider")
          ]
        : _vm._e(),
      _vm.acceptedBidList.length && _vm.allowedUsers
        ? [
            _c("p", { staticClass: "subheading mb-0 pl-3" }, [
              _vm._v("\n            Accepted Bid"),
              _vm.acceptedBidList.length > 1
                ? _c("span", [_vm._v("s")])
                : _vm._e()
            ]),
            _c(
              "v-list",
              { attrs: { "two-line": "" } },
              _vm._l(_vm.acceptedBidList, function(invite) {
                return _c(
                  "detailed-list-item",
                  {
                    key: invite.id,
                    attrs: { item: _vm.populateInviteListItem(invite) }
                  },
                  [
                    _vm.allowedUsers
                      ? _c(
                          "v-list-tile-action",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "secondary", flat: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.openBidDetails(
                                      invite.bids[0],
                                      invite.contractor
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        View\n                    "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              1
            ),
            _c("v-divider")
          ]
        : _vm._e(),
      [
        _c(
          "v-expansion-panel",
          {
            staticClass: "elevation-0",
            attrs: { expand: "" },
            model: {
              value: _vm.bidExpansionPanel,
              callback: function($$v) {
                _vm.bidExpansionPanel = $$v
              },
              expression: "bidExpansionPanel"
            }
          },
          [
            _c(
              "v-expansion-panel-content",
              {
                scopedSlots: _vm._u(
                  [
                    _vm.allowedBidList.length
                      ? {
                          key: "header",
                          fn: function() {
                            return [_c("div", [_vm._v("Show All Invitations")])]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                )
              },
              [
                _c(
                  "v-list",
                  {
                    staticClass: "projectBidColumn",
                    attrs: { "two-line": "" }
                  },
                  [
                    _vm.bidInvitations.length === 0
                      ? _c("v-list-tile", { staticClass: "pl-3" }, [
                          _vm._v(
                            "\n                        No bid invitations associated to this project\n                    "
                          )
                        ])
                      : _vm._e(),
                    _vm._l(_vm.allowedBidList, function(invite, index) {
                      return _c(
                        "detailed-list-item",
                        {
                          key: invite.id,
                          attrs: { item: _vm.populateInviteListItem(invite) }
                        },
                        [
                          _c(
                            "v-list-tile-action",
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", "justify-end": "" } },
                                [
                                  _vm.canDeleteInvitation(invite)
                                    ? _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                loading: invite.isDeleting,
                                                icon: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteInvite(
                                                    invite,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "rgba(0,0,0,0.54)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            fas fa-trash\n                                        "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.canResendInvitation(invite)
                                    ? _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                loading: invite.isResending,
                                                icon: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.resendInvite(
                                                    invite,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "rgba(0,0,0,0.54)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            fas fa-paper-plane\n                                        "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.canEditContractor(invite)
                                    ? _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onEditContractor(
                                                    invite.contractor
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "rgba(0,0,0,0.54)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            fas fa-pen\n                                        "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          invite.bids &&
                          invite.bids.length &&
                          _vm.canViewBid(invite)
                            ? _c(
                                "v-list-tile-action",
                                [
                                  invite.bids.length
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            flat: "",
                                            color: "secondary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openBidDetails(
                                                invite.bids[0],
                                                invite.contractor
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                View\n                            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        ),
        _vm.allowedUsers &&
        _vm.bidInvitations.length > 0 &&
        this.contractorsWithSubmittedBidCount > 0
          ? _c(
              "v-list",
              [
                _c(
                  "v-list-tile",
                  [
                    _c(
                      "v-layout",
                      { attrs: { "align-end": "", "justify-end": "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "secondary",
                              flat: "",
                              loading: _vm.showDownloadSpinner
                            },
                            on: { click: _vm.onDownloadBids }
                          },
                          [
                            _vm._v(
                              "\n                        Download all existing bids\n                    "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.openBidDetailsDialog,
            callback: function($$v) {
              _vm.openBidDetailsDialog = $$v
            },
            expression: "openBidDetailsDialog"
          }
        },
        [
          _c("bid-details", {
            attrs: {
              bid: _vm.bidDetails,
              contractor: _vm.contractorDetails,
              "promoting-bid": _vm.promotingBid,
              "bid-awarded": _vm.awardedBid.length > 0
            },
            on: {
              "close-dialog": function($event) {
                _vm.openBidDetailsDialog = false
              },
              "open-dialog": function($event) {
                _vm.openBidDetailsDialog = true
              },
              "on-promote-bid": _vm.onPromoteBid
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.deleteInvitationConfirmDialog,
            callback: function($$v) {
              _vm.deleteInvitationConfirmDialog = $$v
            },
            expression: "deleteInvitationConfirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline", attrs: { "primary-title": "" } },
                [
                  _vm._v(
                    "\n                Delete Invitation to Bid?\n            "
                  )
                ]
              ),
              _c("v-card-text", [
                _vm._v(
                  "\n                Are you sure you want to delete this contractor's invitation to bid?\n            "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.deleteInvitationConfirmDialog = false
                        }
                      }
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.onDeleteInvite(
                            _vm.inviteToDelete,
                            _vm.inviteIndexToDelete
                          )
                        }
                      }
                    },
                    [_vm._v("\n                    Delete\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.resendInvitationConfirmDialog,
            callback: function($$v) {
              _vm.resendInvitationConfirmDialog = $$v
            },
            expression: "resendInvitationConfirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline", attrs: { "primary-title": "" } },
                [
                  _vm._v(
                    "\n                Re-Send Invitation to Bid?\n            "
                  )
                ]
              ),
              _c("v-card-text", [
                _vm._v(
                  "\n                Are you sure you want to re-send this contractor's invitation to bid?\n            "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.resendInvitationConfirmDialog = false
                        }
                      }
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.onResendInvite(
                            _vm.inviteToResend,
                            _vm.inviteIndexToResend
                          )
                        }
                      }
                    },
                    [_vm._v("\n                    Re-Send\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.showContractorDialog,
            callback: function($$v) {
              _vm.showContractorDialog = $$v
            },
            expression: "showContractorDialog"
          }
        },
        [
          _c("invite-contractor", {
            attrs: {
              "is-editing-contractor": true,
              "contractor-to-edit": _vm.contractorToEdit
            },
            on: {
              "reload-data": function($event) {
                return _vm.$emit("reload-data")
              },
              "close-dialog": function($event) {
                _vm.showContractorDialog = false
              }
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }