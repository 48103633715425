var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-content",
    { attrs: { loading: _vm.isLoading, error: _vm.error } },
    [
      _vm.project
        ? _c(
            "v-container",
            { attrs: { "grid-list-lg": "", "data-cy": "project-edit-data" } },
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "transparent" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        to: {
                          name: "readProject",
                          params: { id: _vm.$route.params.id }
                        },
                        "active-class": ""
                      }
                    },
                    [_c("v-icon", [_vm._v("fas fa-arrow-left")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Edit Project")])
                ],
                1
              ),
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm8: "", md6: "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-3" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "projectForm",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.onSave($event)
                                }
                              }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _vm.form.status !== "DRAFT"
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Project Status",
                                              items: _vm.allowedStatuses,
                                              rules: [_vm.rules.required]
                                            },
                                            on: { change: _vm.onStatusChange },
                                            model: {
                                              value: _vm.form.status,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression: "form.status"
                                            }
                                          }),
                                          _c("confirmation-dialog", {
                                            attrs: {
                                              "show-dialog":
                                                _vm.showConfirmArchiveDialog,
                                              header: "Archive Project",
                                              message:
                                                "You are about to archive this project. You must save these changes.",
                                              action: "Archive",
                                              persistent: ""
                                            },
                                            on: {
                                              "close-dialog":
                                                _vm.onRejectArchive,
                                              "confirm-action": function(
                                                $event
                                              ) {
                                                _vm.showConfirmArchiveDialog = false
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Project Type",
                                          items: _vm.projectTypes,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.form.type,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "type", $$v)
                                          },
                                          expression: "form.type"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Project Name*",
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.form.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "name", $$v)
                                          },
                                          expression: "form.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Project Number",
                                          disabled: !_vm.allowedUsers,
                                          hint: _vm.allowedUsers
                                            ? "e.g.19-ES-000012"
                                            : "Project number will be edited by a member of the DBS team.",
                                          "persistent-hint": ""
                                        },
                                        model: {
                                          value: _vm.form.projectNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "projectNumber",
                                              $$v
                                            )
                                          },
                                          expression: "form.projectNumber"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          value: _vm.form.customer,
                                          items: _vm.customers,
                                          label: "Customer Name*",
                                          placeholder: "Search for a customer",
                                          hint:
                                            "If you cannot find a customer, enter the details below.",
                                          "item-text": "name",
                                          "item-value": "id",
                                          type: "search",
                                          "return-object": "",
                                          clearable: "",
                                          "clear-icon": "fas fa-times",
                                          "persistent-hint": ""
                                        },
                                        on: {
                                          change: _vm.onCustomerSelect,
                                          "click:clear": _vm.onCustomerClear
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-expand-transition",
                                    [
                                      _vm.showCustomerForm
                                        ? _c("customer-form", {
                                            model: {
                                              value: _vm.form.customer,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "customer",
                                                  $$v
                                                )
                                              },
                                              expression: "form.customer"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          value: _vm.form.customerContact,
                                          items: _vm.contactList,
                                          label: "Contact Name*",
                                          placeholder: "Search for a contact",
                                          hint:
                                            "If you cannot find a contact, enter the details below.",
                                          "item-text": "name",
                                          "item-value": "id",
                                          type: "search",
                                          "return-object": "",
                                          clearable: "",
                                          "clear-icon": "fas fa-times",
                                          "persistent-hint": ""
                                        },
                                        on: { change: _vm.onContactSelect },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(data) {
                                                return [
                                                  _c("v-list-tile-content", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-truncate"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              data.item.name
                                                            ) +
                                                            "\n                                            "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "caption grey--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              data.item.title
                                                            ) +
                                                            "\n                                            "
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          4200886176
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-expand-transition",
                                    [
                                      _c("contact-form", {
                                        attrs: {
                                          contact: _vm.form.customerContact
                                        },
                                        on: {
                                          "update:contact": function($event) {
                                            return _vm.$set(
                                              _vm.form,
                                              "customerContact",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("h3", { staticClass: "title" }, [
                                        _vm._v(
                                          "\n                                    Site Information\n                                "
                                        )
                                      ]),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Site Name",
                                          rules:
                                            _vm.form.status !== "DRAFT"
                                              ? [_vm.rules.required]
                                              : []
                                        },
                                        model: {
                                          value: _vm.form.siteName,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "siteName", $$v)
                                          },
                                          expression: "form.siteName"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("address-form", {
                                        attrs: {
                                          address: _vm.form.siteAddress,
                                          "is-required":
                                            _vm.form.status !== "DRAFT"
                                        },
                                        on: {
                                          "update:address": function($event) {
                                            return _vm.$set(
                                              _vm.form,
                                              "siteAddress",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("h3", { staticClass: "title pt-2" }, [
                                        _vm._v(
                                          "\n                                    Pre-Bid Information\n                                "
                                        )
                                      ]),
                                      _c("v-checkbox", {
                                        attrs: {
                                          label:
                                            "I am Including Pre-Bid Information",
                                          color: "primary"
                                        },
                                        model: {
                                          value: _vm.isIncludingPrebid,
                                          callback: function($$v) {
                                            _vm.isIncludingPrebid = $$v
                                          },
                                          expression: "isIncludingPrebid"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.isIncludingPrebid
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "", md4: "" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "prebidDateMenu",
                                              attrs: {
                                                "close-on-content-click": false,
                                                "nudge-right": 40,
                                                lazy: "",
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "full-width": "",
                                                "min-width": "290px"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Meeting Date",
                                                                readonly: "",
                                                                clearable: "",
                                                                "clear-icon":
                                                                  "fas fa-times",
                                                                rules: [
                                                                  _vm.rules
                                                                    .required
                                                                ]
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .prebidDate,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "prebidDate",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "form.prebidDate"
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2959254583
                                              ),
                                              model: {
                                                value: _vm.menus.prebidDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.menus,
                                                    "prebidDate",
                                                    $$v
                                                  )
                                                },
                                                expression: "menus.prebidDate"
                                              }
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  "no-title": "",
                                                  scrollable: ""
                                                },
                                                on: {
                                                  input: function($event) {
                                                    _vm.menus.prebidDate = false
                                                  }
                                                },
                                                model: {
                                                  value: _vm.form.prebidDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "prebidDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.prebidDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isIncludingPrebid
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs6: "", md4: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Time",
                                              items: _vm.times,
                                              rules: [_vm.rules.required]
                                            },
                                            model: {
                                              value: _vm.form.prebidTime,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "prebidTime",
                                                  $$v
                                                )
                                              },
                                              expression: "form.prebidTime"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isIncludingPrebid
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs6: "", md4: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              label: "Time Zone",
                                              items: _vm.timeZones,
                                              rules: [_vm.rules.required]
                                            },
                                            model: {
                                              value: _vm.form.prebidTimeZone,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "prebidTimeZone",
                                                  $$v
                                                )
                                              },
                                              expression: "form.prebidTimeZone"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isIncludingPrebid
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("address-form", {
                                            attrs: {
                                              address: _vm.form.prebidAddress
                                            },
                                            on: {
                                              "update:address": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.form,
                                                  "prebidAddress",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isIncludingPrebid
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              label: "Meeting Notes",
                                              "auto-grow": ""
                                            },
                                            model: {
                                              value: _vm.form.prebidNote,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "prebidNote",
                                                  $$v
                                                )
                                              },
                                              expression: "form.prebidNote"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("h3", { staticClass: "title pt-2" }, [
                                        _vm._v(
                                          "\n                                    Scope of Work & Details\n                                "
                                        )
                                      ]),
                                      _c("v-checkbox", {
                                        attrs: {
                                          label:
                                            "I am uploading the Scope of Work",
                                          color: "primary",
                                          hint: !_vm.scopeOfWorkFile
                                            ? "Please clearly label the document before uploading."
                                            : "",
                                          "persistent-hint":
                                            _vm.isUploadingScopeOfWork
                                        },
                                        model: {
                                          value: _vm.isUploadingScopeOfWork,
                                          callback: function($$v) {
                                            _vm.isUploadingScopeOfWork = $$v
                                          },
                                          expression: "isUploadingScopeOfWork"
                                        }
                                      }),
                                      _vm.isUploadingScopeOfWork
                                        ? _c(
                                            "v-input",
                                            {
                                              model: {
                                                value: _vm.scopeOfWorkFile,
                                                callback: function($$v) {
                                                  _vm.scopeOfWorkFile = $$v
                                                },
                                                expression: "scopeOfWorkFile"
                                              }
                                            },
                                            [
                                              !_vm.scopeOfWorkFile
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.openScopeOfWorkUpload = true
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        Upload File\n                                    "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-dialog",
                                                {
                                                  attrs: { "max-width": "700" },
                                                  model: {
                                                    value:
                                                      _vm.openScopeOfWorkUpload,
                                                    callback: function($$v) {
                                                      _vm.openScopeOfWorkUpload = $$v
                                                    },
                                                    expression:
                                                      "openScopeOfWorkUpload"
                                                  }
                                                },
                                                [
                                                  _vm.isUploadingScopeOfWork
                                                    ? _c("file-upload", {
                                                        attrs: {
                                                          "root-id":
                                                            "scopeOfWorkFile",
                                                          title:
                                                            "Upload a Scope of Work Document",
                                                          description:
                                                            "If you have a document detailing the scope of work, please attach that here.",
                                                          "max-files": 1
                                                        },
                                                        on: {
                                                          "close-dialog": function(
                                                            $event
                                                          ) {
                                                            _vm.openScopeOfWorkUpload = false
                                                          },
                                                          "on-filestack-upload-done":
                                                            _vm.uploadScopeofWork
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm.scopeOfWorkFile
                                                ? _c(
                                                    "v-list",
                                                    {
                                                      staticStyle: {
                                                        width: "386px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-tile",
                                                        {
                                                          staticClass: "pl-0",
                                                          attrs: {
                                                            href:
                                                              _vm
                                                                .scopeOfWorkFile
                                                                .path,
                                                            target: "_blank"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-tile-avatar",
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    size: "16",
                                                                    left: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                    fas fa-download\n                                                "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-tile-content",
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-truncate"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                    " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .scopeOfWorkFile
                                                                          .filename
                                                                      ) +
                                                                      "\n                                                "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-list-tile-action",
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    flat: "",
                                                                    icon: "",
                                                                    color:
                                                                      "rgba(0,0,0,0.54)"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.onDeleteScopeOfWork(
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        size:
                                                                          "20"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        fas fa-trash\n                                                    "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm.deleteScopeOfWorkConfirmDialog
                                                                ? _c(
                                                                    "confirmation-dialog",
                                                                    {
                                                                      attrs: {
                                                                        "show-dialog":
                                                                          _vm.deleteScopeOfWorkConfirmDialog,
                                                                        header:
                                                                          "Delete File?",
                                                                        message:
                                                                          "This will permanently remove the file from this project.",
                                                                        action:
                                                                          "Delete"
                                                                      },
                                                                      on: {
                                                                        "close-dialog": function(
                                                                          $event
                                                                        ) {
                                                                          _vm.deleteScopeOfWorkConfirmDialog = false
                                                                        },
                                                                        "confirm-action":
                                                                          _vm.onDeleteScopeOfWork
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _c("v-textarea", {
                                            attrs: {
                                              label: "Scope of Work",
                                              "auto-grow": ""
                                            },
                                            model: {
                                              value: _vm.form.scopeOfWork,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "scopeOfWork",
                                                  $$v
                                                )
                                              },
                                              expression: "form.scopeOfWork"
                                            }
                                          }),
                                      _c("v-checkbox", {
                                        attrs: {
                                          label:
                                            "This project will require a torch.",
                                          color: "primary",
                                          hint:
                                            "Contractors will be notified prior to submitting a bid.",
                                          "persistent-hint":
                                            _vm.form.torchRequired
                                        },
                                        model: {
                                          value: _vm.form.torchRequired,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "torchRequired",
                                              $$v
                                            )
                                          },
                                          expression: "form.torchRequired"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", md4: "" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "bidDueDateMenu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "nudge-right": 40,
                                            lazy: "",
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "full-width": "",
                                            "min-width": "290px"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            label:
                                                              "Bid Due Date",
                                                            readonly: "",
                                                            clearable: "",
                                                            "clear-icon":
                                                              "fas fa-times",
                                                            rules:
                                                              _vm.project
                                                                .status !==
                                                              "DRAFT"
                                                                ? [
                                                                    _vm.rules
                                                                      .required
                                                                  ]
                                                                : []
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .bidDueDate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "bidDueDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.bidDueDate"
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2766852431
                                          ),
                                          model: {
                                            value: _vm.menus.bidDueDate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.menus,
                                                "bidDueDate",
                                                $$v
                                              )
                                            },
                                            expression: "menus.bidDueDate"
                                          }
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: {
                                              "no-title": "",
                                              scrollable: "",
                                              min: _vm.minBidDueDate,
                                              max: _vm.maxBidDueDate,
                                              "allowed-dates":
                                                _vm.allowedDueDates
                                            },
                                            on: {
                                              input: function($event) {
                                                _vm.menus.bidDueDate = false
                                              }
                                            },
                                            model: {
                                              value: _vm.form.bidDueDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "bidDueDate",
                                                  $$v
                                                )
                                              },
                                              expression: "form.bidDueDate"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "", md4: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Time",
                                          items: _vm.times,
                                          rules:
                                            _vm.project.status !== "DRAFT"
                                              ? [_vm.rules.required]
                                              : []
                                        },
                                        model: {
                                          value: _vm.form.bidDueTime,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "bidDueTime",
                                              $$v
                                            )
                                          },
                                          expression: "form.bidDueTime"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "", md4: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Time Zone",
                                          items: _vm.timeZones,
                                          rules:
                                            _vm.project.status !== "DRAFT"
                                              ? [_vm.rules.required]
                                              : []
                                        },
                                        model: {
                                          value: _vm.form.bidDueTimeZone,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "bidDueTimeZone",
                                              $$v
                                            )
                                          },
                                          expression: "form.bidDueTimeZone"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "proposalDueDateMenu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "nudge-right": 40,
                                            lazy: "",
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "full-width": "",
                                            "min-width": "290px"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            label:
                                                              "Requested Proposal Date",
                                                            readonly: "",
                                                            clearable: "",
                                                            "clear-icon":
                                                              "fas fa-times"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .proposalDueDate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "proposalDueDate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.proposalDueDate"
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1576779797
                                          ),
                                          model: {
                                            value: _vm.menus.proposalDueDate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.menus,
                                                "proposalDueDate",
                                                $$v
                                              )
                                            },
                                            expression: "menus.proposalDueDate"
                                          }
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: {
                                              "picker-date":
                                                _vm.proposalPickerDate,
                                              "no-title": "",
                                              scrollable: "",
                                              min: _vm.minProposalDueDate,
                                              "allowed-dates":
                                                _vm.allowedDueDates
                                            },
                                            on: {
                                              "update:pickerDate": function(
                                                $event
                                              ) {
                                                _vm.proposalPickerDate = $event
                                              },
                                              "update:picker-date": function(
                                                $event
                                              ) {
                                                _vm.proposalPickerDate = $event
                                              },
                                              input: function($event) {
                                                _vm.menus.proposalDueDate = false
                                              }
                                            },
                                            model: {
                                              value: _vm.form.proposalDueDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "proposalDueDate",
                                                  $$v
                                                )
                                              },
                                              expression: "form.proposalDueDate"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          label: "Special Instructions",
                                          "auto-grow": ""
                                        },
                                        model: {
                                          value: _vm.form.specialInstructions,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "specialInstructions",
                                              $$v
                                            )
                                          },
                                          expression: "form.specialInstructions"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Est. Budget ($)",
                                          prefix: "$",
                                          "auto-grow": "",
                                          rules: [_vm.rules.numericOrEmpty]
                                        },
                                        model: {
                                          value: _vm.form.estimatedBudget,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "estimatedBudget",
                                              $$v
                                            )
                                          },
                                          expression: "form.estimatedBudget"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { "justify-end": "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: { shrink: "", "pa-0": "" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    type: "submit",
                                                    color: "primary",
                                                    disabled: _vm.isSaving,
                                                    loading: _vm.isSaving
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Save\n                                        "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }