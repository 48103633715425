<template>
    <v-app>
        <snack-messages />
        <v-content>
            <router-view />
        </v-content>
    </v-app>
</template>

<script>
import SnackMessages from './components/SnackMessages'

export default {
    name: 'App',
    components: {
        SnackMessages
    }
}
</script>
