






























































































































































































































































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import { Project, GetProjectsResponse, Session, StringOrNull, ManagerRep } from '@/types'
import { requestErrorMessage } from '../../utils/constants/errors'
import UserBus from '../../utils/buses/UserBus'
import { statuses, statusesArray, Status } from '../../utils/constants/statuses'
import qs from 'qs'
import UserChip from '../../components/UserChip.vue'

const headers = [
    {
        text: 'Project #',
        sortable: true,
        value: 'id'
    },
    {
        text: 'Sales Rep',
        sortable: true,
        value: 'salesRep'
    },
    {
        text: 'Customer Name',
        sortable: true,
        value: 'customer.name'
    },
    {
        text: 'Site Name',
        sortable: true,
        value: 'siteName'
    },
    {
        text: 'Created',
        sortable: true,
        value: 'createdAt'
    },
    {
        text: 'Last Updated',
        sortable: true,
        value: 'updatedAt'
    },
    {
        text: 'Status',
        sortable: true,
        value: 'status',
        class: 'right-icon',
        align: 'right'
    }
]

interface AssignedToFilterOption {
    id: String | Number,
    userName: String
}

@Component({
    components: {
        UserChip
    }
})
export default class Projects extends Vue {
    private projects: Project[] = []
    private headers = headers
    private isLoading: boolean = false
    private tableLoading: boolean = false
    private isSalesMgr: boolean = false
    private error: StringOrNull = null
    private pagination = this.setDefaultPagination()
    private totalItems: number = 1
    private filterTerm: StringOrNull = this.setDefaultTerm()
    private filterStatuses: Status[] = this.setDefaultStatus()
    private filterAssignedToMe: boolean = this.setDefaultAssignedToMe()
    private filterAssignedTo: number | string | null = this.setDefaultAssignedTo()
    private filterAssignedToOptions: AssignedToFilterOption[] = []
    private isAssignedToFilterLoading: boolean = false

    // TODO verify if this syntax is still needed
    private statuses: Status[] = (() => {
        return [
            ...statusesArray
        ]
    })()

    get isDbsUser (): boolean {
        return UserBus.userIs.dbsUser()
    }

    get filterAllStatuses () {
        return this.filterStatuses.length === this.statuses.length
    }

    get filterSomeStatuses () {
        return this.filterStatuses.length > 0 && !this.filterAllStatuses
    }

    get toggleStatusesIcon () {
        if (this.filterAllStatuses) return 'fas fa-check-square'
        if (this.filterSomeStatuses) return 'fas fa-minus-square'
        return 'far fa-square'
    }

    created () {
        this.isLoading = true
        this.managerCheck()
        this.populateFilterAssignedToOptions()
        this.getData()
            .finally(() => {
                this.isLoading = false
            })
    }

    managerCheck () {
        axios.get('/api/users/managerReps')
            .then(res => {
                const mgrReps:ManagerRep[] = res.data.data
                if (mgrReps.length > 0) {
                    this.isSalesMgr = true
                    console.log('isMgr: ' + this.isSalesMgr)
                }
            })
    }

    populateFilterAssignedToOptions () {
        if (!this.isDbsUser) {
            return
        }
        this.isAssignedToFilterLoading = true
        axios.get('/api/users/dbsUsers')
            .then(res => {
                const allFilterOptions = res.data.data

                const filterOptions: AssignedToFilterOption[] = allFilterOptions
                    .filter((option: Session) => {
                        return option.id !== UserBus.id
                    })
                    .map((option: Session) => {
                        return {
                            id: option.id,
                            userName: option.userName
                        }
                    })

                // Re-insert ourselves at the top of the list
                filterOptions.splice(0, 0, {
                    id: UserBus.id!,
                    userName: 'Me'
                })
                // Insert unassigned at the end of the list
                filterOptions.push({
                    id: 'unassigned',
                    userName: 'Unassigned'
                })

                this.filterAssignedToOptions = filterOptions
            })
            .catch(error => {
                console.log(error.status)
            })
            .finally(() => {
                this.isAssignedToFilterLoading = false
            })
    }

    getData () {
        this.tableLoading = true

        // build sort param
        let sort = []
        if (this.pagination.sortBy) {
            sort.push(this.pagination.sortBy)
        }
        sort.push(this.pagination.descending ? 'desc' : 'asc')

        return axios.get<GetProjectsResponse>('/api/projects', {
            params: {
                sort: sort.join(','),
                page: this.pagination.page - 1, // java expects pages starting at 0 index
                size: this.pagination.rowsPerPage,
                term: this.filterTerm,
                status: this.filterStatuses.map(v => v.value),
                assignedToMe: this.filterAssignedToMe,
                assignedTo: this.filterAssignedTo
            },
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: 'repeat' })
            }
        }).then(res => {
            this.projects = res.data.data.content
            this.totalItems = res.data.data.totalElements

            return this.projects
        }).catch(err => {
            console.log('Get Projects Error', err)
            this.error = requestErrorMessage
        }).finally(() => {
            this.tableLoading = false
        })
    }

    setDefaultPagination () {
        const descending = this.$route.query.descending
            ? this.$route.query.descending === 'true'
            : true
        const page = this.$route.query.page
            ? Number(this.$route.query.page)
            : 1
        const rowsPerPage = this.$route.query.rowsPerPage
            ? Number(this.$route.query.rowsPerPage)
            : 25
        const sortBy = this.$route.query.sortBy
            ? this.$route.query.sortBy as string
            : 'updatedAt'

        return {
            descending,
            page,
            rowsPerPage,
            sortBy,
            totalItems: 1
        }
    }

    setDefaultTerm () {
        return this.$route.query.term ? this.$route.query.term as string : null
    }

    setDefaultStatus () {
        let status = this.$route.query.status as string[]

        let queryStatuses: string[] = []

        if (status) {
            if (typeof status === 'string') {
                queryStatuses = [status]
            } else if (typeof status === 'object') {
                queryStatuses = status
            }
        }

        return queryStatuses.map(v => statuses[v])
    }
    setDefaultAssignedToMe () {
        return this.$route.query.assignedToMe === 'true'
    }

    setDefaultAssignedTo () {
        const assignedToId = this.$route.query.assignedTo

        const parsedId = parseInt(assignedToId as string)
        if (isNaN(parsedId)) {
            return assignedToId ? assignedToId as string : null
        }

        return parsedId
    }

    onPaginationUpdate () {
        // TODO this isn't working
        // prevents pagination event from firing on load which would cause multiple api request
        if (this.isLoading) return

        const {
            descending,
            page,
            rowsPerPage,
            sortBy
        } = this.pagination

        this.$router.replace({
            name: 'projects',
            query: {
                ...this.$route.query,
                descending: descending.toString(),
                page: page.toString(),
                rowsPerPage: rowsPerPage.toString(),
                sortBy
            }
        })

        this.getData()
    }

    toggleStatuses () {
        this.$nextTick(() => {
            if (this.filterAllStatuses || this.filterSomeStatuses) {
                this.filterStatuses = []
            } else {
                this.filterStatuses = this.statuses.slice()
            }

            (this.$refs.statusFilter as Vue).$emit('change')
        })
    }

    onChangeTerm () {
        const term = this.filterTerm

        if (term) {
            this.$router.replace({
                name: 'projects',
                query: {
                    ...this.$route.query,
                    term
                }
            })
        } else {
            let query = { ...this.$route.query }
            delete query.term

            this.$router.replace({
                name: 'projects',
                query: {
                    ...query
                }
            })
        }

        this.getData()
    }

    onChangeStatus () {
        const status = this.filterStatuses.map(v => v.value)

        this.$router.replace({
            name: 'projects',
            query: {
                ...this.$route.query,
                status
            }
        })

        this.getData()
    }

    onChangeAssignedTo () {
        const assignedTo: string = this.filterAssignedTo as string

        this.$router.replace({
            name: 'projects',
            query: {
                ...this.$route.query,
                assignedTo
            }
        })

        this.getData()
    }

    onChangeAssignedCheckbox () {
        const checkbox = this.filterAssignedToMe

        this.$router.replace({
            name: 'projects',
            query: {
                ...this.$route.query,
                assignedToMe: checkbox.toString()
            }
        })

        this.getData()
    }
}
