



















import { Component, Vue, Prop } from 'vue-property-decorator'
import { Session } from '../types'

@Component
export default class UserChip extends Vue {
    @Prop({
        required: true
    })
    readonly session!: Session

    @Prop({
        type: Boolean,
        default: false
    })
    readonly hideName!: Boolean
}
