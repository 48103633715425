/// Bid statuses

export const SUBMITTED = 'SUBMITTED'
export const ACCEPTED = 'ACCEPTED'
export const INVALID = 'INVALID'

export interface BidStatus {
    text: string;
    value: string;
    chipClasses: string;
    icon: string;
}

export const bidStatuses: {
    [key: string]: BidStatus
} = {
    [SUBMITTED]: {
        text: 'Submitted',
        value: SUBMITTED,
        chipClasses: '',
        icon: ''
    },
    [ACCEPTED]: {
        text: 'Accepted',
        value: ACCEPTED,
        chipClasses: 'success white--text',
        icon: 'fas fa-check-circle'
    },
    [INVALID]: {
        text: 'Invalid',
        value: INVALID,
        chipClasses: 'warning white--text',
        icon: 'fas fa-exclamation-circle'
    }
}
