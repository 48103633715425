import { StringOrNull } from './customTypes'

export interface Address {
    id?: string;
    line1: StringOrNull;
    line2: StringOrNull;
    city: StringOrNull;
    state: StringOrNull;
    zip: StringOrNull;
}

export const emptyAddress: Address = {
    line1: null,
    line2: null,
    city: null,
    state: null,
    zip: null
}
