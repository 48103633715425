var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-timeline",
        {
          staticClass: "project-timeline",
          attrs: { dense: "", "align-top": "" }
        },
        _vm._l(_vm.timelineItems, function(item) {
          return _c(
            "v-timeline-item",
            { key: item.id, attrs: { right: "", "fill-dot": "" } },
            [
              _c("template", { slot: "opposite" }, [
                _c("div", { staticClass: "mt-3" }, [
                  _c("span", { staticClass: "grey--text text--darken-2" }, [
                    _vm._v(
                      _vm._s(_vm._f("formatDate")(item.createdAt, "MMM DD"))
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("timeline-item", {
                    attrs: { item: item, show: item.show }
                  })
                ],
                1
              )
            ],
            2
          )
        }),
        1
      ),
      _vm.items.length > 3
        ? _c(
            "v-layout",
            { attrs: { "align-center": "" } },
            [
              _c("v-spacer"),
              _c(
                "v-flex",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-xs-center",
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function($event) {
                          _vm.showAllTimeline = !_vm.showAllTimeline
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.showAllTimeline ? "Show Less" : "Show All"
                          ) +
                          "\n                "
                      ),
                      _c("v-icon", { staticClass: "pl-2" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.showAllTimeline
                                ? "fas fa-angle-up"
                                : "fas fa-angle-down"
                            ) +
                            "\n                "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }