










































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Addendum, AddendumOrNull } from '../../types'
import DetailedListItem from '../../components/DetailedListItem.vue'
import AddendumDetails from '../../components/Addenda/AddendumDetails.vue'
import SnackBus from '../../utils/buses/SnackBus'

@Component({
    components: {
        DetailedListItem,
        AddendumDetails
    }
})

export default class AddendaCard extends Vue {
    @Prop({
        default: []
    })
    addendums!: Addendum[]

    @Prop({
        default: false
    })
    showAll!: boolean

    @Prop({
        default: false
    })
    expandable!: boolean

    @Prop({
        default: false,
        required: false
    })
    isContractor!: boolean

    private showAddendumDetailsDialog: boolean = false
    private selectedAddendum: AddendumOrNull = null

    populateAddendumListItem (addendum: Addendum) {
        return {
            icon: 'fas fa-bell',
            tooltip: 'Addendum ' + addendum.version,
            lineOne: 'Addendum ' + addendum.version,
            lineTwo: this.$options!.filters!.formatCalendarDate(addendum.createdAt) || ''
        }
    }

    viewAddendumDetails (addendum: Addendum) {
        if (this.isContractor) {
            this.$emit('view-addendum-details', addendum)
        } else {
            this.selectedAddendum = addendum
            this.showAddendumDetailsDialog = true
        }
    }
}
