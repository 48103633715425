



























































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Location } from 'vue-router'
import UserBus from '../utils/buses/UserBus'
import SocketBus, { JsonClient } from '../utils/buses/SocketBus'
import SnackBus from '../utils/buses/SnackBus'

interface NavItem {
    text: string;
    icon: string;
    route: Location
}

const navItems: NavItem[] = [
    {
        text: 'Projects',
        icon: 'fas fa-list',
        route: {
            name: 'projects'
        }
    }
]

@Component
export default class Toolbar extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    readonly hideNavigation!: boolean

    private greetingsClient: JsonClient = new JsonClient()
    private showNav: boolean = false;
    private navItems: NavItem[] = navItems

    get canEmulate () {
        return process.env.NODE_ENV === 'development'
    }

    get canSayHello () {
        return process.env.NODE_ENV === 'development' && UserBus.userIs.admin() && this.greetingsClient.active
    }

    get session () {
        return UserBus.session
    }

    get userName () {
        return UserBus.userName
    }

    onLogout () {
        localStorage.clear()
        sessionStorage.clear()
        location.href = 'https://www.garlandhq.com/'
    }

    mounted () {
        if (process.env.NODE_ENV === 'development' && !this.hideNavigation) {
            this.greetingsClient = SocketBus.subscribeClient('/topic/greetings', msg => {
                SnackBus.showMessage(msg.json.content, 'info')
            })
        }
    }

    beforeDestroy () {
        this.greetingsClient.deactivate()
    }

    greeting () {
        this.greetingsClient.publishJson({
            destination: '/app/hello',
            objectBody: {
                name: UserBus.userName
            }
        })
    }
}
