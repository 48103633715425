var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { attrs: { "primary-title": "" } }, [
        _c("h3", { staticClass: "headline" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.isEditingContractor ? "Edit" : "Invite") +
              " Contractor\n        "
          )
        ])
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            { attrs: { "grid-list-lg": "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  !_vm.isEditingContractor
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              value: _vm.contractor,
                              items: _vm.bidContractorsFull,
                              label: "Contractor Name",
                              placeholder: "Search for a contractor",
                              "item-value": "id",
                              "item-text": "fullName",
                              type: "search",
                              "return-object": "",
                              clearable: "",
                              "clear-icon": "fas fa-times",
                              hint:
                                "If you cannot find a contractor enter the details below.",
                              "persistent-hint": ""
                            },
                            on: { change: _vm.onContractorSelect },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(data) {
                                    return [
                                      _c("v-list-tile-content", [
                                        _c(
                                          "div",
                                          { staticClass: "text-truncate" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(data.item.companyName) +
                                                "\n                                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "caption grey--text" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(data.item.name) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "selection",
                                  fn: function(data) {
                                    return [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(data.item.companyName) +
                                          ",\n                            " +
                                          _vm._s(data.item.name) +
                                          "\n                        "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1520441041
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-form",
                        { ref: "contractorForm" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("h3", { staticClass: "title" }, [
                                  _vm._v(
                                    "\n                                    Contractor Information\n                                "
                                  )
                                ])
                              ]),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Contact Name",
                                      autocomplete: "name",
                                      rules: [_vm.rules.required]
                                    },
                                    on: { input: _vm.onContractorInput },
                                    model: {
                                      value: _vm.contractor.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.contractor, "name", $$v)
                                      },
                                      expression: "contractor.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Email",
                                      rules: [_vm.rules.required]
                                    },
                                    on: { input: _vm.onContractorInput },
                                    model: {
                                      value: _vm.contractor.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.contractor, "email", $$v)
                                      },
                                      expression: "contractor.email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Phone",
                                      rules: [_vm.rules.required],
                                      mask: "phone",
                                      hint: "(###) ###-####"
                                    },
                                    on: { input: _vm.onContractorInput },
                                    model: {
                                      value: _vm.contractor.phone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.contractor, "phone", $$v)
                                      },
                                      expression: "contractor.phone"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Company Name",
                                      rules: [_vm.rules.required]
                                    },
                                    on: { input: _vm.onContractorInput },
                                    model: {
                                      value: _vm.contractor.companyName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "companyName",
                                          $$v
                                        )
                                      },
                                      expression: "contractor.companyName"
                                    }
                                  })
                                ],
                                1
                              ),
                              !_vm.isEditingContractor &&
                              _vm.canSendCalendarInvites
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label:
                                            "Send Pre-Bid Calendar Invite.",
                                          color: "secondary",
                                          reverse: ""
                                        },
                                        model: {
                                          value:
                                            _vm.contractor
                                              .shouldSendCalendarInvites,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contractor,
                                              "shouldSendCalendarInvites",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contractor.shouldSendCalendarInvites"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          !_vm.isNewContractor && !_vm.isEditingContractor
            ? _c(
                "v-btn",
                {
                  attrs: { loading: _vm.isDeleting, color: "error", flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirm-delete", _vm.contractor.id)
                    }
                  }
                },
                [_vm._v("\n            Delete Contractor\n        ")]
              )
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { flat: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close-dialog")
                }
              }
            },
            [_vm._v("\n            Cancel\n        ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                disabled: !_vm.showInviteButton || _vm.isDeleting
              },
              on: {
                click: function($event) {
                  return _vm.onSubmit()
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.isEditingContractor ? "Save" : "Send Invite") +
                  "\n        "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }