import { render, staticRenderFns } from "./UserChip.vue?vue&type=template&id=5d260367&scoped=true&"
import script from "./UserChip.vue?vue&type=script&lang=ts&"
export * from "./UserChip.vue?vue&type=script&lang=ts&"
import style0 from "./UserChip.vue?vue&type=style&index=0&id=5d260367&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d260367",
  null
  
)

/* vuetify-loader */
import installComponents from "!/codebuild/output/src2994353656/src/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib'
import { VChip } from 'vuetify/lib'
import { VTooltip } from 'vuetify/lib'
installComponents(component, {VAvatar,VChip,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2994353656/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('5d260367', component.options)
    } else {
      api.reload('5d260367', component.options)
    }
    module.hot.accept("./UserChip.vue?vue&type=template&id=5d260367&scoped=true&", function () {
      api.rerender('5d260367', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/UserChip.vue"
export default component.exports