export interface Contractor {
    id?: string;
    name: string;
    companyName: string;
    phone: string;
    email: string;
    deletedAt?: string;
    shouldSendCalendarInvites?: Boolean;
}

export interface ContractorAllData extends Contractor {
  contractors: Contractor[];
}

export const generateEmptyContractor = (): Contractor => ({
    name: '',
    companyName: '',
    phone: '',
    email: '',
    shouldSendCalendarInvites: true
})
