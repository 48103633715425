var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-content",
    { attrs: { loading: _vm.isLoading, error: _vm.error, info: _vm.info } },
    [
      _c("div", [_c("toolbar", { attrs: { "hide-navigation": "" } })], 1),
      _vm.validUser && _vm.invitationBidForm
        ? _c(
            "v-container",
            {
              attrs: {
                "grid-list-lg": "",
                "data-cy": "invitation-project-details"
              }
            },
            [
              _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.project.name) + " "),
                _vm.selectedAddendum != null
                  ? _c("span", [
                      _vm._v(
                        "(Addendum " +
                          _vm._s(_vm.selectedAddendum.version) +
                          ")"
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "v-toolbar",
                {
                  staticClass: "text-xs-right",
                  attrs: { flat: "", color: "transparent" }
                },
                [
                  _c("v-spacer"),
                  _vm.invitationBidForm
                    ? _c(
                        "a",
                        {
                          staticClass: "v-btn v-btn theme--light secondary",
                          attrs: {
                            href: _vm.invitationBidForm.path,
                            target: "_blank"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "pr-2",
                              attrs: { size: "20", dark: "" }
                            },
                            [
                              _vm._v(
                                "\n                    fas fa-download\n                "
                              )
                            ]
                          ),
                          _vm._v(
                            "\n                Download Bid Form\n            "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.bidSubmitting, color: "secondary" },
                      on: {
                        click: function($event) {
                          _vm.openBidUploadDialog = true
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pr-2",
                          attrs: { size: "20", dark: "" }
                        },
                        [
                          _vm._v(
                            "\n                    fas fa-upload\n                "
                          )
                        ]
                      ),
                      _vm._v("\n                Upload Bid\n            ")
                    ],
                    1
                  ),
                  _vm.bidExists && _vm.bids[0].invalidatedAt !== null
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.bidValidating,
                            color: "secondary"
                          },
                          on: { click: _vm.openValidateConfirmDialogFromBid }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "pr-2",
                              attrs: { size: "20", dark: "" }
                            },
                            [
                              _vm._v(
                                "\n                    fas fa-check-circle\n                "
                              )
                            ]
                          ),
                          _vm._v(
                            "\n                Confirm Current Bid\n            "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.isOptingOut,
                        color: "primary",
                        flat: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.optOutConfirmDialog = true
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                I'm Not Interested\n            "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { "justify-center": "", row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", md8: "" } },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { persistent: "", "max-width": "40rem" },
                          model: {
                            value: _vm.torchRequiredDialog,
                            callback: function($$v) {
                              _vm.torchRequiredDialog = $$v
                            },
                            expression: "torchRequiredDialog"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", { staticClass: "headline" }, [
                                _vm._v(
                                  "\n                            Torch Required\n                        "
                                )
                              ]),
                              _c("v-card-text", [
                                _c("p", [
                                  _vm._v(
                                    "Please note – the Scope of Work for this project includes torch applications."
                                  )
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "DBS requires our subcontractors using an open flame torch to carry a $10,000,000 umbrella insurance policy."
                                  )
                                ])
                              ]),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: _vm.torchRequiredAcknowledged
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                I Understand\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            { attrs: { "primary-title": "" } },
                            [
                              _c("div", { staticClass: "headline" }, [
                                _vm._v(
                                  "\n                            Project Details\n                        "
                                )
                              ])
                            ]
                          ),
                          _c("key-value-table", [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [_vm._v("Customer Name")]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.project.customer &&
                                        _vm.project.customer.name
                                    )
                                  )
                                ])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Customer Contact Address")]),
                                _c("td", { staticClass: "text-pre-wrap" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("getAddressString")(
                                        _vm.project.customerContact.address
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Site Name")]),
                                _c("td", [_vm._v(_vm._s(_vm.project.siteName))])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Site Address")]),
                                _c("td", { staticClass: "text-pre-wrap" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("getAddressString")(
                                        _vm.project.siteAddress
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c(
                                "tr",
                                { staticClass: "dbs-scope-of-work-row" },
                                [
                                  _c("td", [_vm._v("Scope of Work")]),
                                  _vm.project.scopeOfWorkFile
                                    ? [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-list",
                                              {
                                                attrs: { color: "transparent" }
                                              },
                                              [
                                                _c(
                                                  "v-list-tile",
                                                  {
                                                    staticClass:
                                                      "elevation-1 white",
                                                    attrs: {
                                                      href:
                                                        _vm.project
                                                          .scopeOfWorkFile.path,
                                                      target: "_blank"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          size: "16",
                                                          left: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    fas fa-download\n                                                "
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-list-tile-content", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-truncate"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                _vm.project
                                                                  .scopeOfWorkFile
                                                                  .filename
                                                              ) +
                                                              "\n                                                    "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : [
                                        _c(
                                          "td",
                                          { staticClass: "text-pre-wrap" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.project.scopeOfWork)
                                            )
                                          ]
                                        )
                                      ]
                                ],
                                2
                              ),
                              _c("tr", [
                                _c("td", [
                                  _vm._v("Will this project require a torch?")
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      this.project.torchRequired ? "Yes" : "No"
                                    )
                                  )
                                ])
                              ]),
                              _c(
                                "tr",
                                { staticClass: "dbs-scope-of-work-row" },
                                [
                                  _c("td", [_vm._v("Bid Form")]),
                                  _vm.invitationBidForm
                                    ? [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-list",
                                              [
                                                _c(
                                                  "v-list-tile",
                                                  {
                                                    staticClass:
                                                      "elevation-1 white",
                                                    attrs: {
                                                      href:
                                                        _vm.invitationBidForm
                                                          .path,
                                                      target: "_blank"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          size: "16",
                                                          left: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    fas fa-download\n                                                "
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-list-tile-content", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-truncate"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                _vm
                                                                  .invitationBidForm
                                                                  .filename
                                                              ) +
                                                              "\n                                                    "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c("tr", [
                                _c("td", [_vm._v("Bid Due Date")]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatLocalDate")(
                                        _vm.project.bidDueDate,
                                        "L LT z",
                                        _vm.project.bidDueTimeZone
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Special Instructions")]),
                                _c("td", { staticClass: "text-pre-wrap" }, [
                                  _vm._v(
                                    _vm._s(_vm.project.specialInstructions)
                                  )
                                ])
                              ])
                            ])
                          ]),
                          _c("v-divider"),
                          _c("v-card-text", [
                            _c("h3", { staticClass: "title mt-2" }, [
                              _vm._v(
                                "\n                            Supporting Files\n                        "
                              )
                            ])
                          ]),
                          _c(
                            "v-list",
                            { attrs: { "two-line": "" } },
                            [
                              _vm.files.length > 1
                                ? _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "secondary",
                                            flat: ""
                                          },
                                          on: { click: _vm.onDownloadAll }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Download All Files\n                            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._l(_vm.files, function(file) {
                                return _c(
                                  "v-list-tile",
                                  {
                                    key: file.id,
                                    attrs: { href: file.path, target: "_blank" }
                                  },
                                  [
                                    _c(
                                      "v-list-tile-avatar",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "16" } },
                                          [
                                            _vm._v(
                                              "\n                                    fas fa-download\n                                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c("v-list-tile-title", [
                                          _vm._v(_vm._s(file.filename))
                                        ]),
                                        _c("v-list-tile-sub-title", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatCalendarDate")(
                                                file.createdAt
                                              )
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _vm.project.files === 0
                                ? _c("v-list-tile", [
                                    _vm._v(
                                      "\n                            No files associated to this project\n                        "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", md4: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-center": "", row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { attrs: { "primary-title": "" } },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "headline pb-0" },
                                          [
                                            _vm._v(
                                              "\n                                        Welcome, " +
                                                _vm._s(
                                                  _vm.invitation.contractor.name
                                                ) +
                                                "\n                                    "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "title font-weight-regular grey--text"
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.invitation.contractor
                                                    .companyName
                                                ) +
                                                "\n                                    "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-list",
                                    { attrs: { "two-line": "" } },
                                    [
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-sub-title", [
                                                _vm._v("Bid Due")
                                              ]),
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatLocalDate")(
                                                      _vm.project.bidDueDate,
                                                      "L LT z",
                                                      _vm.project.bidDueTimeZone
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-sub-title", [
                                                _vm._v(
                                                  "Invitation To Bid Received"
                                                )
                                              ]),
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatLocalDate")(
                                                      _vm.invitation.sentAt,
                                                      "L LT z",
                                                      _vm.project.bidDueTimeZone
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "dbs-current-bid-card" },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass: "headline",
                                      attrs: { "primary-title": "" }
                                    },
                                    [
                                      _c("span", [_vm._v("Current Bid")]),
                                      _c("v-spacer"),
                                      _vm.bidExists
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  class:
                                                    _vm.bidStatuses[
                                                      _vm.bids[0].status
                                                    ].chipClasses
                                                },
                                                [
                                                  _vm.bidStatuses[
                                                    _vm.bids[0].status
                                                  ].icon
                                                    ? _c(
                                                        "v-avatar",
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm
                                                                    .bidStatuses[
                                                                    _vm.bids[0]
                                                                      .status
                                                                  ].icon
                                                                ) +
                                                                "\n                                            "
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.bidStatuses[
                                                          _vm.bids[0].status
                                                        ].text
                                                      ) +
                                                      "\n                                    "
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm.bidExists
                                    ? [
                                        _c(
                                          "v-list",
                                          { attrs: { "two-line": "" } },
                                          [
                                            _vm._l(_vm.bids[0].files, function(
                                              file
                                            ) {
                                              return _c(
                                                "v-list-tile",
                                                {
                                                  key: file.path,
                                                  attrs: {
                                                    href: file.path,
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-tile-avatar",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { size: "16" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                fas fa-download\n                                            "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-tile-content",
                                                    [
                                                      _c("v-list-tile-title", [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              file.filename
                                                            ) +
                                                            "\n                                            "
                                                        )
                                                      ]),
                                                      _c(
                                                        "v-list-tile-sub-title",
                                                        [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatCalendarDate"
                                                                )(
                                                                  file.createdAt
                                                                )
                                                              ) +
                                                              "\n                                            "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            _vm.bids[0].notes !== ""
                                              ? _c(
                                                  "v-list-tile",
                                                  [
                                                    _c(
                                                      "v-list-tile-content",
                                                      [
                                                        _c(
                                                          "v-list-tile-sub-title",
                                                          [_vm._v("Notes")]
                                                        ),
                                                        _c(
                                                          "v-list-tile-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.bids[0]
                                                                  .notes
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ]
                                    : [
                                        _c("v-card-text", [
                                          _vm._v(
                                            "\n                                    No bids have been submitted yet.\n                                "
                                          )
                                        ])
                                      ]
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.addendumList.length > 0
                                ? _c("addenda-card", {
                                    attrs: {
                                      addendums: _vm.shownAddendumList,
                                      "show-all": _vm.showAllAddendums,
                                      expandable: _vm.addendumList.length > 3,
                                      "is-contractor": true
                                    },
                                    on: {
                                      "show-all-addendums": function($event) {
                                        _vm.showAllAddendums = !_vm.showAllAddendums
                                      },
                                      "view-addendum-details":
                                        _vm.viewAddendumDetails
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "700" },
          model: {
            value: _vm.openBidUploadDialog,
            callback: function($$v) {
              _vm.openBidUploadDialog = $$v
            },
            expression: "openBidUploadDialog"
          }
        },
        [
          !_vm.openSubmitBidForm
            ? _c("file-upload", {
                attrs: {
                  "root-id": "bidForm",
                  title: "Submit Bid Form",
                  description:
                    "Please attach the completed version of your provided bid form and supporting documents. A blank copy can be downloaded by clicking the 'DOWNLOAD BID FORM' button."
                },
                on: {
                  "close-dialog": function($event) {
                    _vm.openBidUploadDialog = false
                  },
                  "on-filestack-upload-done": _vm.uploadBidForm
                }
              })
            : _vm._e(),
          _vm.openSubmitBidForm
            ? _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline", attrs: { "primary-title": "" } },
                    [_vm._v("\n                Submit Your Bid\n            ")]
                  ),
                  _c(
                    "v-list",
                    { staticClass: "pb-3" },
                    [
                      _c("v-subheader", [
                        _vm._v("\n                    Bid File"),
                        _vm.bidFiles.length > 1
                          ? _c("span", [_vm._v("s")])
                          : _vm._e()
                      ]),
                      _vm._l(_vm.bidFiles, function(file) {
                        return _c(
                          "v-list-tile",
                          {
                            key: file.path,
                            attrs: { href: file.path, target: "_blank" }
                          },
                          [
                            _c(
                              "v-list-tile-avatar",
                              [
                                _c("v-icon", { attrs: { size: "16" } }, [
                                  _vm._v(
                                    "\n                            fas fa-download\n                        "
                                  )
                                ])
                              ],
                              1
                            ),
                            _c("v-list-tile-title", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(file.filename) +
                                  "\n                    "
                              )
                            ])
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-textarea", {
                        attrs: {
                          name: "bidNotes",
                          label: "Bid Notes",
                          placeholder:
                            "Add any additional information you'd like to submit.",
                          "auto-grow": ""
                        },
                        model: {
                          value: _vm.bidNotes,
                          callback: function($$v) {
                            _vm.bidNotes = $$v
                          },
                          expression: "bidNotes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "" },
                          on: { click: _vm.cancelBidSubmit }
                        },
                        [
                          _vm._v(
                            "\n                    Cancel\n                "
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.bidSubmitting,
                            color: "primary"
                          },
                          on: { click: _vm.submitBid }
                        },
                        [
                          _vm._v(
                            "\n                    Submit\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.addendumList.length > 0
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: _vm.isNewAddendum,
                width: "600",
                scrollable: ""
              },
              model: {
                value: _vm.openAddendumDetailsDialog,
                callback: function($$v) {
                  _vm.openAddendumDetailsDialog = $$v
                },
                expression: "openAddendumDetailsDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { attrs: { "primary-title": "" } }, [
                    _c("h3", { staticClass: "headline" }, [
                      _vm._v(
                        "\n                    Addendum " +
                          _vm._s(_vm.selectedAddendum.version) +
                          "\n                "
                      )
                    ])
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _vm.canValidate
                        ? _c("p", [
                            _vm._v(
                              "\n                    Please review the project changes below. If the details of your current bid are still valid, please confirm your current bid below. If this addendum appears to invalidate your bid, you must close this dialog and revise / replace your bid before the bid due date, or it will not be accepted.\n                "
                            )
                          ])
                        : _vm._e(),
                      _vm.selectedAddendum.notes
                        ? _c("div", [
                            _c("h3", { staticClass: "title" }, [
                              _vm._v(
                                "\n                        Addendum Notes\n                    "
                              )
                            ]),
                            _c("p", { staticClass: "body-1 py-3" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.selectedAddendum.notes) +
                                  "\n                    "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.selectedAddendum.comments.length > 0
                        ? _c("div", [
                            _c("h3", { staticClass: "title" }, [
                              _vm._v(
                                "\n                        Project Changes\n                    "
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "my-3" },
                              [
                                _c("addendum-project-timeline", {
                                  attrs: {
                                    items: _vm.selectedAddendum.comments
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.canValidate
                        ? _c("div", [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("Current Bid Submitted")
                            ]),
                            _c("br"),
                            _c("span", { staticClass: "grey--text" }, [
                              _vm._v("You will have the ability to "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.bidExists ? "replace" : "upload")
                                )
                              ]),
                              _vm._v(" your bid until "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatLocalDate")(
                                      _vm.project.bidDueDate,
                                      "L LT z",
                                      _vm.project.bidDueTimeZone
                                    )
                                  )
                                )
                              ]),
                              _vm._v(".")
                            ])
                          ])
                        : _vm._e(),
                      _vm.canValidate
                        ? _c(
                            "v-list",
                            { attrs: { "two-line": "" } },
                            [
                              _vm._l(_vm.bids[0].files, function(file) {
                                return _c(
                                  "v-list-tile",
                                  {
                                    key: file.path,
                                    attrs: { href: file.path, target: "_blank" }
                                  },
                                  [
                                    _c(
                                      "v-list-tile-avatar",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "16" } },
                                          [
                                            _vm._v(
                                              "\n                                fas fa-download\n                            "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-list-tile-content", [
                                      _c(
                                        "div",
                                        { staticClass: "text-truncate" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(file.filename) +
                                              "\n                            "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "caption grey--text" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm._f("formatCalendarDate")(
                                                  file.createdAt
                                                )
                                              ) +
                                              "\n                            "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              }),
                              _vm.bids[0].notes !== ""
                                ? _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-sub-title", [
                                            _vm._v("Bid Notes")
                                          ]),
                                          _c("v-list-tile-title", [
                                            _vm._v(_vm._s(_vm.bids[0].notes))
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "" },
                          on: {
                            click: function($event) {
                              _vm.openAddendumDetailsDialog = false
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.canValidate ? "Revise Bid" : "Close") +
                              "\n                "
                          )
                        ]
                      ),
                      _vm.canValidate
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: _vm.openValidateConfirmDialogFromAddendum
                              }
                            },
                            [
                              _vm._v(
                                "\n                    Confirm Current Bid\n                "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.validateBidConfirmDialog
        ? _c("confirmation-dialog", {
            attrs: {
              "show-dialog": _vm.validateBidConfirmDialog,
              header: "Confirm your current bid?",
              message:
                "Changes have been made to this project since you submitted your last bid.",
              action: "Confirm",
              "button-type": "primary"
            },
            on: {
              "close-dialog": _vm.closeValidateConfirmDialog,
              "confirm-action": _vm.onValidateBid
            }
          })
        : _vm._e(),
      _vm.validateBidConfirmDialog
        ? _c("confirmation-dialog", {
            attrs: {
              "show-dialog": _vm.validateBidConfirmDialog,
              header: "Confirm your current bid?",
              message:
                "Changes have been made to this project since you submitted your last bid.",
              action: "Confirm",
              "button-type": "primary"
            },
            on: {
              "close-dialog": _vm.closeValidateConfirmDialog,
              "confirm-action": _vm.onValidateBid
            }
          })
        : _vm._e(),
      _vm.optOutConfirmDialog
        ? _c("confirmation-dialog", {
            attrs: {
              "show-dialog": _vm.optOutConfirmDialog,
              header: "Opt out of bidding?",
              message:
                "You will no longer be eligible to bid on this project and will not receive emails or updates related to this bid.",
              action: "Opt Out"
            },
            on: {
              "close-dialog": function($event) {
                _vm.optOutConfirmDialog = false
              },
              "confirm-action": _vm.onOptOut
            }
          })
        : _vm._e(),
      _c("reload-snack-message", {
        attrs: { show: _vm.reloadPage },
        on: { "reload-page": _vm.onReloadPage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }