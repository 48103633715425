import { render, staticRenderFns } from "./ProjectTimeline.vue?vue&type=template&id=1b34d77a&scoped=true&"
import script from "./ProjectTimeline.vue?vue&type=script&lang=ts&"
export * from "./ProjectTimeline.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectTimeline.vue?vue&type=style&index=0&id=1b34d77a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b34d77a",
  null
  
)

/* vuetify-loader */
import installComponents from "!/codebuild/output/src2994353656/src/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib'
import { VTimeline } from 'vuetify/lib'
import { VTimelineItem } from 'vuetify/lib'
installComponents(component, {VAvatar,VTimeline,VTimelineItem})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2994353656/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('1b34d77a', component.options)
    } else {
      api.reload('1b34d77a', component.options)
    }
    module.hot.accept("./ProjectTimeline.vue?vue&type=template&id=1b34d77a&scoped=true&", function () {
      api.rerender('1b34d77a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ProjectTimeline.vue"
export default component.exports