var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      [
        _c(
          "v-card-title",
          { staticClass: "headline" },
          [
            _c("span", [
              _vm._v(
                "\n                " + _vm._s(_vm.title) + "\n            "
              )
            ]),
            _c("v-spacer"),
            _c(
              "v-btn",
              {
                attrs: { icon: "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close-dialog")
                  }
                }
              },
              [
                _c("v-icon", { attrs: { color: "rgba(0,0,0,0.54)" } }, [
                  _vm._v("\n                    fas fa-times\n                ")
                ])
              ],
              1
            )
          ],
          1
        ),
        _c("v-card-text", { staticClass: "subtitle-1" }, [
          _vm._v("\n            " + _vm._s(_vm.description) + "\n        ")
        ]),
        _c("div", { attrs: { id: "filestackContainer" } })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }