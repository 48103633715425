<template>
    <div>
        <toolbar />
        <router-view />
    </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import Toolbar from '../components/Toolbar'

@Component({
    components: {
        Toolbar
    }
})
export default class Dashboard extends Vue {}
</script>

<style>

</style>
