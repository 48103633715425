



































import { Component, Vue, Prop } from 'vue-property-decorator'
import SnackBus from '../utils/buses/SnackBus'

@Component
export default class ConfirmationDialog extends Vue {
    @Prop({
        default: false
    })
    showDialog!: Boolean

    @Prop({
        default: false,
        required: false
    })
    persistent!: Boolean

    @Prop({
        default: 'Delete?'
    })
    header!: String

    @Prop({
        default: 'This will permanently remove this from the project?'
    })
    message!: String

    @Prop({
        default: 'Delete'
    })
    action!: String

    @Prop({
        default: 'error',
        required: false
    })
    buttonType!: String
}
