import { StringOrNull, DateOrNull } from './customTypes'
import { Contractor, ProjectFiles } from '.'

export interface Invitation {
    id?: string;
    bids: Bid[];
    contractor: Contractor;
    declinedAt: Date;
    createdAt: Date;
    expiredAt: Date;
    projectId: string;
    sentAt: Date;
    viewedAt: Date;
    status: string;
}

export interface BidInvitation {
    id: string;
    hash: string;
    projectId: string;
    project: ProjectFiles; // TODO: This should really be `Project`, not `ProjectFiles` to match BE response
    bids: Bid[];
    declinedAt: Date;
    createdAt: Date;
    sentAt: DateOrNull;
    viewedAt: DateOrNull;
    expiredAt: DateOrNull;
    status: string;
    hasAcknowledgedTorchRequired: boolean;
}

export interface BidFile {
    filename: string,
    path: string
}

export interface Bid {
    awardedAt?: Date,
    acceptedAt?: Date,
    createdAt?: Date,
    files: BidFile[],
    id?: string,
    invitationId?: string,
    invalidatedAt?: Date,
    notes: string,
    status?: string
}

export interface ProjectInvitation {
    id?: string;
    bids: Bid[];
    contractor: Contractor;
    projectId: string;
    declinedAt: Date;
    createdAt: Date;
    sentAt: DateOrNull;
    viewedAt: DateOrNull;
    expiredAt: DateOrNull;
    status: string;
    isDeleting: boolean;
}

export const generateEmptyBid = (): Bid => ({
    files: [{
        filename: '',
        path: ''
    }],
    notes: ''
})
