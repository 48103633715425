import moment from 'moment'
import { map } from 'lodash'
import { mainOidc } from '@/oidc'
import { Contact, ProjectCenterAddress } from '@/graphql/graphql'
import { CustomerContact } from '@/types'

export interface SelectItem<T> {
    text: string,
    value: T
}

/**
 * Set cookie on browser
 * @param {String} cname name of cookie
 * @param {*} cvalue value of cookie
 * @param {Number} exdays days until cookie expires
 */
export const setCookie = (cname: string, cvalue: any, exdays: number) => {
    const d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    const expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

/**
 * Get cookie from browser
 * @param {String} cname name of cookie
 */
export const getCookie = (cname: string): string => {
    if (mainOidc && mainOidc.userProfile) {
        return mainOidc.userProfile['custom:garland-token']
    }
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (let i = 0, length = ca.length; i < length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

/**
 * Remove cookie from browser
 * @param {String} cname name of cookie
 */
export const removeCookie = (cname: string) => {
    document.cookie = cname + '=; Path=/; Max-Age=-99999999;'
}

/**
 * Generate list of time selections
 */
export const getTimes = (): SelectItem<string>[] => {
    let hours: SelectItem<string>[] = []

    for (let hour = 0; hour < 24; hour++) {
        const fullHour = moment({ hour })
        const halfHour = moment({ hour }).add(30, 'minutes')

        const times = [
            fullHour, halfHour
        ]

        times.forEach(item => {
            hours.push({
                text: item.format('h:mm A'),
                value: item.format('HH:mm:ss')
            })
        })
    }

    return hours
}

/**
 * Generate list of time zone selections
 */
export const getTimeZones = (): SelectItem<string>[] => {
    let zones = {
        'Eastern': 'America/New_York',
        'Central': 'America/Chicago',
        'Mountain': 'America/Denver',

        // TODO determine if we need this zone
        // 'Mountain (no DST)': 'America/Phoenix',

        'Pacific': 'America/Los_Angeles'
    }

    // map to SelectItem collection
    return map(zones, (value, text): SelectItem<string> => {
        return { text, value }
    })
}

export function verifyAddressReturned (address: ProjectCenterAddress | undefined) {
    if (!(address ? address.id : null)) {
        throw Error('Project Center Address returned null')
    }
}

export function verifyContactReturned (contact: Contact | undefined | null) {
    if (!contact) {
        throw Error('Contact returned null')
    }
}
export function verifyAddressIsPresent (contact: Contact | CustomerContact) {
    if (!contact.address) {
        throw Error('Address is null')
    }
}
