var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "d-none",
      attrs: { "max-width": "400" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c("v-hover", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var hover = ref.hover
                        return _c(
                          "v-card",
                          _vm._g(
                            {
                              staticClass: "pa-3 cursor-pointer",
                              class: { "lighten-1 primary": hover }
                            },
                            on
                          ),
                          [
                            _c(
                              "v-layout",
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        staticClass: "dbs-user-card-avatar",
                                        attrs: {
                                          size: "48",
                                          color: hover
                                            ? "white"
                                            : _vm.iconBackground
                                        }
                                      },
                                      [
                                        !_vm.session
                                          ? _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: hover
                                                    ? "primary"
                                                    : "white"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      hover
                                                        ? "fas fa-plus"
                                                        : _vm.user.icon
                                                    ) +
                                                    "\n                            "
                                                )
                                              ]
                                            )
                                          : [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm._f("getAvatarInitials")(
                                                      _vm.session
                                                    )
                                                  ) +
                                                  "\n                            "
                                              )
                                            ]
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "text-truncate-alt",
                                    class: { "white--text": hover }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "body-1",
                                        class: {
                                          "grey--text text--darken-2": !hover
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.user.title) +
                                            "\n                        "
                                        )
                                      ]
                                    ),
                                    _c("br"),
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        _vm._s(
                                          hover
                                            ? _vm.user.name
                                            : _vm.session
                                            ? _vm.session.userName
                                            : "Unassigned"
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline", attrs: { "primary-title": "" } },
            [
              _vm._v(
                "\n            Assign yourself as " +
                  _vm._s(_vm.user.title) +
                  "?\n        "
              )
            ]
          ),
          _c("v-card-text", [
            _vm._v(
              "\n            Are you sure you want to assign yourself as " +
                _vm._s(_vm.user.title) +
                " for this project?\n        "
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { flat: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("\n                Cancel\n            ")]
              ),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.onAccept } },
                [_vm._v("\n                Assign\n            ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }