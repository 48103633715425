var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600", scrollable: "" },
      model: {
        value: _vm.showDialogModel,
        callback: function($$v) {
          _vm.showDialogModel = $$v
        },
        expression: "showDialogModel"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline", attrs: { "primary-title": "" } },
            [
              _vm._v(
                "\n            Addendum " +
                  _vm._s(_vm.selectedAddendum.version) +
                  "\n        "
              )
            ]
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _vm.selectedAddendum.notes
                ? [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v(
                        "\n                    Addendum Notes\n                "
                      )
                    ]),
                    _c("p", { staticClass: "body-1 py-3" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.selectedAddendum.notes) +
                          "\n                "
                      )
                    ])
                  ]
                : _vm._e(),
              _vm.selectedAddendum.comments.length > 0
                ? [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v(
                        "\n                    Project Changes\n                "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _c("addendum-project-timeline", {
                          attrs: { items: _vm.selectedAddendum.comments }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close-dialog")
                    }
                  }
                },
                [_vm._v("\n                Close\n            ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }