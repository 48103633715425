import { SessionOrNull, Session } from '../types'
// TODO - organize this differently?
export interface UserCardInput {
    icon: string;
    title: string;
    name: string;
}

export const generateEmptyUserCardInput = (): UserCardInput => ({
    icon: '',
    title: '',
    name: ''
})

export interface ListItemDetails {
    badge?: string;
    icon: string;
    tooltip: string;
    lineOne: string;
    lineTwo: string | Date;
    lineThree?: string | Date;
}

interface ProjectTeamAssignment {
    session: SessionOrNull;
    user: UserCardInput;
    unassigned: Boolean;
    assignable: Boolean;
}

export interface ProjectTeamList {
    sales: ProjectTeamAssignment;
    supportingSales: {
        session: Session[] | null;
        user: UserCardInput[];
        unassigned: Boolean;
        assignable: Boolean;
    }
    proposalTeam: ProjectTeamAssignment;
    projectManager: ProjectTeamAssignment;
}
