var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "headline" }, [
          _vm._v("\n            Addendum Details\n        ")
        ])
      ]),
      _c(
        "v-list",
        { attrs: { "two-line": "" } },
        _vm._l(_vm.addendums, function(addendum) {
          return _c(
            "detailed-list-item",
            {
              key: addendum.id,
              attrs: { item: _vm.populateAddendumListItem(addendum) }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.viewAddendumDetails(addendum)
                    }
                  }
                },
                [_vm._v("\n                View\n            ")]
              )
            ],
            1
          )
        }),
        1
      ),
      _vm.expandable
        ? _c(
            "v-btn",
            {
              staticClass: "text-xs-center",
              attrs: { color: "primary", flat: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("show-all-addendums")
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.showAll ? "Show Less" : "Show More") +
                  "\n    "
              )
            ]
          )
        : _vm._e(),
      _vm.selectedAddendum && !_vm.isContractor
        ? _c("addendum-details", {
            attrs: {
              "show-dialog": _vm.showAddendumDetailsDialog,
              "selected-addendum": _vm.selectedAddendum
            },
            on: {
              "close-dialog": function($event) {
                _vm.showAddendumDetailsDialog = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }