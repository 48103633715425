import { StringOrNull, AddressOrNull, DateOrNull, CustomerOrNull, SessionOrNull, CustomerContactOrNull, InvitationsOrNull, UploadOrNull, ProjectContactOrNull, NumberOrNull } from './customTypes'
import { Session } from './session'

export interface Project {
    id: string;
    name: string;
    projectNumber: StringOrNull;
    status: string;
    type: string;
    siteName: string;
    customer: CustomerOrNull;
    customerContact: CustomerContactOrNull;
    contacts: ProjectContactOrNull[];
    salesRep: SessionOrNull;
    supportingSalesReps: Session[];
    scopeOfWork: StringOrNull;
    scopeOfWorkFile: UploadOrNull;
    specialInstructions: StringOrNull;
    estimatedBudget: NumberOrNull;
    invitations: InvitationsOrNull;
    bidDueDate: StringOrNull;
    bidDueTimeZone: StringOrNull;
    prebidDate: StringOrNull;
    prebidAddress: AddressOrNull;
    prebidTimeZone: StringOrNull;
    prebidNote: StringOrNull;
    proposalDueDate: StringOrNull;
    siteAddress: AddressOrNull;
    updatedAt: string;
    createdAt: string;
    addendums: Addendum[];
    torchRequired: boolean;
    bidManagementType: string;
}

export interface ProjectAllData extends Project {
    files: Upload[];
    comments: Comment[];
    projectManager: SessionOrNull;
    proposalTeam: SessionOrNull;
}

export interface ProjectFiles extends Project {
    files: Upload[];
}

export interface Comment {
    id: string;
    user: Session;
    type: string;
    title: string;
    body: string;
    createdAt: string;
}

export interface AddendumComment {
    id: string;
    type: string;
    title: string;
    body: string;
    createdAt: string;
}

export interface Addendum {
    id: string;
    projectId: string;
    user: Session;
    version: number;
    comments: Comment[];
    notes: StringOrNull;
    createdAt: Date;
}

export interface Upload {
    id: string;
    filename: string;
    path: string;
    createdAt: Date;
}

export interface ProjectBidForm {
    id?: string,
    filename: string,
    path: string,
    createdAt?: DateOrNull
}

export interface AttachCustomBidForm {
    fileName: string,
    uri: string
}

export const generateEmptyBidForm = (): ProjectBidForm => ({
    filename: '',
    path: ''
})
