import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import { getCookie, removeCookie, setCookie } from '../helpers'
import {
    CheckPermissionsResponse,
    LabeledPermissions,
    NumberOrNull,
    Session,
    SessionOrNull,
    StringOrNull,
    ValidateTokenResponse
} from '@/types'
import { mapValues } from 'lodash'
import { mainOidc } from '@/oidc'

export const AUTH_COOKIE_NAME = 'DBS-GAuthToken'
export const AUTH_HEADER_NAME = 'X-GAuthToken'
export const ROLE_DBS_ADMIN = 'DBS_ADMIN'
export const ROLE_DBS_PROJECT_MANAGEMENT = 'DBS_PROJECT_MANAGEMENT'
export const ROLE_DBS_PROPOSAL_TEAM = 'DBS_PROPOSAL_TEAM'
export const ROLE_SALES_REP = 'SALES_REP'
export const ROLE_PROJ_CENTER_ADMIN = 'PROJ_CENTER_ADMIN'

@Component
class UserBus extends Vue {
    get token (): StringOrNull {
        return getCookie(AUTH_COOKIE_NAME) || null
    }
    public id: NumberOrNull = null
    public role: StringOrNull = null
    public userName: StringOrNull = null
    public session: SessionOrNull = null

    get userIs () {
        return {
            admin: () => this.role === ROLE_DBS_ADMIN || this.role === ROLE_PROJ_CENTER_ADMIN,
            projectManagement: () => this.role === ROLE_DBS_PROJECT_MANAGEMENT,
            proposalTeam: () => this.role === ROLE_DBS_PROPOSAL_TEAM,
            salesRep: () => this.role === ROLE_SALES_REP,
            dbsUser: () => {
                return this.role === ROLE_DBS_PROPOSAL_TEAM
                    || this.role === ROLE_DBS_PROJECT_MANAGEMENT
                    || this.role === ROLE_DBS_ADMIN
            }
        }
    }

    async userCan (permissions: LabeledPermissions) {
        try {
            const res = await axios.post<CheckPermissionsResponse>('/api/permissions', permissions)

            return res.data.data
        } catch (err) {
            console.log('Check Permissions Error', err)

            return mapValues(permissions, v => {
                v.authorized = false
                return v
            })
        }
    }

    beforeCreate () {
        if (process.env.NODE_ENV === 'development') {
            (window as any).getCookie = getCookie;
            (window as any).setCookie = setCookie;
            (window as any).removeCookie = removeCookie;
            (window as any).getRole = () => this.role
        }
    }

    validateUser () {
        return axios.post<ValidateTokenResponse>('/api/users/checkToken')
            .then(res => {
                const data = res.data.data

                // determine valid role
                const role = data.role
                const user = data.response.user
                if (
                    role === ROLE_DBS_ADMIN
                    || role === ROLE_DBS_PROJECT_MANAGEMENT
                    || role === ROLE_DBS_PROPOSAL_TEAM
                    || role === ROLE_SALES_REP
                    || role === ROLE_PROJ_CENTER_ADMIN
                ) {
                    this.role = role
                    this.userName = user ? user.userName : null
                    this.id = user ? user.userId : null
                    this.session = {
                        id: user!.userId,
                        userName: user ? user.userName : 'Unknown User',
                        role: role,
                        email: user ? user.email : 'unknown@email.com'
                    }
                } else {
                    const error = (new Error('Invalid Role') as Error & {status: number})
                    error.status = 401
                    throw error
                }

                return res
            })
    }

    emulateRole (role: string) {
        switch (role) {
        case ROLE_DBS_ADMIN:
            this.role = ROLE_DBS_ADMIN
            break
        case ROLE_DBS_PROJECT_MANAGEMENT:
            this.role = ROLE_DBS_PROJECT_MANAGEMENT
            break
        case ROLE_DBS_PROPOSAL_TEAM:
            this.role = ROLE_DBS_PROPOSAL_TEAM
            break
        case ROLE_SALES_REP:
            this.role = ROLE_SALES_REP
            break
        case ROLE_PROJ_CENTER_ADMIN:
            this.role = ROLE_PROJ_CENTER_ADMIN
        }
    }

    // see https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
    avatarColor (session: Session) {
        let root = session.userName
        let hash = 0

        for (let i = 0; i < root.length; i++) {
            hash = root.charCodeAt(i) + ((hash << 5) - hash)
        }

        let h = hash % 360
        return 'hsl(' + h + ', 50%, 80%)'
    }

    avatarInitials (session: Session) {
        let initialsList = session.userName.replace(/[^a-zA-Z0-9\s]/g, '').split(' ').map(v => v[0] || '')

        let initials = initialsList.length === 1
            ? initialsList[0]
            : initialsList[0] + initialsList[initialsList.length - 1]

        return initials.toUpperCase()
    }

    /* Start JWT Token / OIDC */
    get jwtToken (): string | null {
        // @ts-ignore
        if (!mainOidc || !mainOidc.user) return null
        // @ts-ignore
        return mainOidc.user.id_token
    }

    // get userName (): string | null {
    //     return mainOidc?.userProfile?.name ?? null;
    // }

    get userEmail (): string | null {
        if (!mainOidc || !mainOidc.userProfile || !mainOidc.userProfile.email) return null
        return mainOidc.userProfile.email
    }

    get userPicture (): string | null {
        if (!mainOidc || !mainOidc.userProfile || !mainOidc.userProfile.picture) return null
        return mainOidc.userProfile.picture
    }

    get userRoles (): string[] | null {
        if (!mainOidc || !mainOidc.userProfile) return null
        return mainOidc.userProfile.userProfile['custom:role']
    }
}

export default new UserBus()
