




















import { Component, Vue } from 'vue-property-decorator'
import SnackBus from '../utils/buses/SnackBus'
import { StringOrNull } from '../types'

@Component
export default class SnackMessages extends Vue {
    private show: boolean = false
    private message: StringOrNull = null
    private color: StringOrNull = null
    private timeout: number = 5000

    created () {
        SnackBus.$on('message-sent', this.showMessage)
    }
    beforeDestroy () {
        // remove event listener to avoid memory leaks
        SnackBus.$off('message-sent', this.showMessage)
    }
    showMessage (message: string, color: string) {
        this.message = message
        this.show = true
        this.color = color
    }
}
