










































































































































import axios from 'axios'
import { Component, Vue, Prop } from 'vue-property-decorator'
import {
    ProjectContactOrNull,
    generateEmptyProjectContact,
    ProjectContact,
    NumberOrNull
} from '../../types'
import { defaultError422, requestErrorMessage } from '../../utils/constants/errors'
import SnackBus from '../../utils/buses/SnackBus'
import InviteProjectContact from './InviteProjectContact.vue'
import ConfirmationDialog from '../ConfirmationDialog.vue'

interface RevokableProjectContact extends ProjectContact{
    isRevoking: Boolean
}

@Component({
    components: {
        InviteProjectContact,
        ConfirmationDialog
    }
})
export default class SharedLinksCard extends Vue {
    @Prop({
        default: []
    })
    contacts!: ProjectContactOrNull[]

    get revokableContacts (): RevokableProjectContact[] {
        if (this.contacts.length) {
            return this.contacts.map(i => {
                return {
                    ...i!,
                    isRevoking: false
                }
            })
        } else {
            return []
        }
    }

    private showContactDialog: Boolean = false
    private isEditingContact: Boolean = false
    private contactToInvite: ProjectContactOrNull = null
    private showRevokeAccessConfirmDialog: Boolean = false
    private indexToRevoke: NumberOrNull = null
    private contactToRevoke: NumberOrNull = null

    /**
     * Add and Edit Project Contacts Functionality
     */

    onEditContact (contact: ProjectContact) {
        this.contactToInvite = contact
        this.isEditingContact = true
        this.showContactDialog = true
    }

    onAddContact () {
        this.contactToInvite = generateEmptyProjectContact()
        this.isEditingContact = false
        this.showContactDialog = true
    }

    revokeAccess (contactId: number, index: number) {
        this.indexToRevoke = index
        this.contactToRevoke = contactId
        this.showRevokeAccessConfirmDialog = true
    }

    async onRevokeAccess () {
        this.showRevokeAccessConfirmDialog = false
        try {
            this.revokableContacts[this.indexToRevoke!].isRevoking = true
            await axios.delete<number[]>(`/api/projects/${this.$route.params.id}/contacts`, {
                data: [this.contactToRevoke]
            })
            SnackBus.showMessage('This contacts access to the project has been successfully revoked.', 'success')

            // remove contact with api
            this.$emit('reload-data')
        } catch (err) {
            SnackBus.showMessage('There was an error revoking shared access for this contact.', 'error')
        } finally {
            this.revokableContacts[this.indexToRevoke!].isRevoking = false
        }
    }
}
