











































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ListItemDetails } from '../types'

@Component

export default class DetailedListItem extends Vue {
    @Prop({
        default: {}
    })
    item!: ListItemDetails
}
