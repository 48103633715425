import * as filestack from 'filestack-js'

export const validationRules = {
    required: (value: any) => !!value || 'This field is required',
    filesNotEmpty: (value: filestack.PickerFileMetadata[]) => value.length > 0 || 'Please select at least one file to upload.',
    numericOrEmpty: (value: any) => {
        const pattern = /^(\d*\.?\d+|\d+\.?)$/ // tolerate odd use of decimal point
        return !value || pattern.test(value) || 'Must be numeric or empty'
    }
}
